import React, { useContext, useState, useEffect } from "react";
import {
  CAvatar,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
import { cilLockLocked, cilUser } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import threedots from "../../assets/images/threedots.png";
import UsersStats from "../Taxi/DashboardStats/UsersStats";
import userContext from "../../utils/context";
import {
  deleteTrips,
  removeDriver,
  switchCompany,
  switchPartnerAccount,
  switchDriver,
  getDriverProfile,
} from "../../utils/api";
import {
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CButton,
  CModal,
  CModalBody,
  CCardBody,
  CCol,
  CRow,
  CCard,
} from "@coreui/react";
import deletepopup from "../../assets/images/deletepopup.png";
import { toast } from "react-toastify";

const AppHeaderDropdown = () => {
  const { user, setUser } = useContext(userContext);
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const [profile, setProfile] = useState([])

  // console.log("user id", user);


  useEffect(() => {

    getPartner();

  }, []);


  const getPartner = () => {
    getDriverProfile().then((res) => {
      // console.log("driver profile", res);
      setProfile(res?.result?.partnerCompanyAccess);

    }).catch((err) => {
      console.log(err);
    });
  }


  // console.log("Profile", profile);

  const handleDeletItem = () => {
    const id = user._id;
    removeDriver(id)
      .then((res) => {
        console.log("delete success", res);
        if (res?.code == 200) {
          toast.success(res.message, {
            position: "top-right",
            autoClose: 1000,
          });
          setUser(null);
          localStorage.clear();
          navigate("/login");
          setVisible(false);
        } else {
          toast.error(res.message, {
            position: "top-right",
            autoClose: 1000,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const logout = () => {
    console.log("logout run please");
    setUser(null);
    localStorage.clear();
    navigate("/");
  };

  return (
    <>
      {user && user.role == "DRIVER" && (
        <div>
          <button
            onClick={() => {
              setVisible(true);
            }}
            className="submit-btn"
            style={{ width: "140px", fontSize: "14px" , color:"black" }}
          >
            {" "}
            Delete account
          </button>
        </div>
      )}

      <CDropdown variant="nav-item" className="header-drop-down">
        <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
          <img className="three-dots" src={threedots} size="md" />
        </CDropdownToggle>
        <CDropdownMenu className="pt-0" placement="bottom-end">
          {/* <CDropdownHeader className="bg-light fw-semibold py-2">Account</CDropdownHeader>
        <CDropdownDivider /> */}
          {user?.role === "COMPANY" && (
            <CDropdownItem className="edit_profile">
              {/* <CIcon icon={cilUser} className="me-2" /> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-sliders2"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M10.5 1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4H1.5a.5.5 0 0 1 0-1H10V1.5a.5.5 0 0 1 .5-.5M12 3.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m-6.5 2A.5.5 0 0 1 6 6v1.5h8.5a.5.5 0 0 1 0 1H6V10a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5M1 8a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2A.5.5 0 0 1 1 8m9.5 2a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V13H1.5a.5.5 0 0 1 0-1H10v-1.5a.5.5 0 0 1 .5-.5m1.5 2.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5"
                />
              </svg>
              <Link to="/taxi/booking/edit" style={{ marginLeft: "8px" }}>
                <span>Edit Booking Page</span>
              </Link>
            </CDropdownItem>
          )}
          {user?.role != "DRIVER" && (
            <CDropdownItem className="edit_profile">
              <CIcon icon={cilUser} className="me-2" />
              <Link to="/edit-profile">
                <span>Edit Profile</span>
              </Link>
            </CDropdownItem>
          )}
          {user?.role === "COMPANY" && !user?.isDriver && (
            <CDropdownItem className="edit_profile">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-pencil-square me-2"
                viewBox="0 0 16 16"
              >
                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                <path
                  fillRule="evenodd"
                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                />
              </svg>
              <Link to="/taxi/driver-register">
                <span>Register as a driver</span>
              </Link>
            </CDropdownItem>
          )}
          {user?.role === "COMPANY" && user?.isDriver && (
            <CDropdownItem
              style={{
                cursor: "pointer",
              }}
              disabled = {localStorage.getItem('partnerAccount') == 'true'}
              onClick={async () => {
                try {
                  const result = await switchDriver();
                  console.log(
                    "🚀 ~ <CDropdownItemonClick={async ~ result:",
                    result
                  );
                  setUser(result.data.result);
                  navigate("/");
                } catch (err) {
                  console.log("🚀 ~ err:", err);
                }
              }}
              className="edit_profile"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-arrow-bar-left"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5"
                />
              </svg>

              <span
                style={{
                  color: "#000",
                  marginLeft: "8px",
                }}
              >
                Switch to driver
              </span>
            </CDropdownItem>
          )}

          {
            localStorage.getItem('partnerAccount') == 'true' && user?.role === "COMPANY"  ? 

            <CDropdownItem
            style={{
              cursor: "pointer",
            }}
            onClick={async () => {
              try {
                const result = await switchDriver();
                console.log(
                  "🚀 ~ <CDropdownItemonClick={async ~ result:",
                  result
                );
                localStorage.removeItem('partnerAccount')

                setUser(result.data.result);
                navigate("/");
              } catch (err) {
                console.log("🚀 ~ err:", err);
              }
            }}
            className="edit_profile"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-arrow-bar-left"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5"
              />
            </svg>

            <span
              style={{
                fontFamily: "Poppins, sans-serif",
                color: "#000",
                marginLeft: "8px",
              }}
            >
              Switch to Your driver
            </span>
          </CDropdownItem> 
          : ''

          }
          {user?.role === "DRIVER" && user?.isCompany && (
            <CDropdownItem
              style={{
                cursor: "pointer",
              }}
              className="edit_profile"
              onClick={async () => {
                try {
                  const result = await switchCompany();
                  console.log(
                    "🚀 ~ <CDropdownItemonClick={async ~ result:",
                    result
                  );
                  setUser(result.data.result);
                  navigate("/");
                } catch (error) {
                  console.log("🚀 ~ error:", error);
                }
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-arrow-bar-left"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5"
                />
              </svg>

              <span
                style={{
                  fontFamily: "Poppins, sans-serif",
                  color: "#000",
                  marginLeft: "8px",
                }}
              >
                Switch to company
              </span>
            </CDropdownItem>
          )}
          {
            profile?.length > 0
              ? profile.map((item, i) => {
                return (
                  <CDropdownItem
                    key={i}
                    style={{
                      cursor: "pointer",
                    }}
                    className="edit_profile"
                    onClick={async () => {
                      try {
                        const result = await switchPartnerAccount(item?.user_id);
                        console.log(
                          "🚀 ~ <CDropdownItemonClick={async ~ result:",
                          result
                        );
                        setUser(result.data.result);
                        localStorage.setItem('partnerAccount', "true")
                        navigate("/");
                      } catch (error) {
                        console.log("🚀 ~ error:", error);
                      }
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-arrow-bar-left"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5"
                      />
                    </svg>

                    <span
                      style={{
                        fontFamily: "Poppins, sans-serif",
                        color: "#000",
                        marginLeft: "8px",
                      }}
                    >
                      Switch to {item.company_name}
                    </span>
                  </CDropdownItem>
                );
              })
              : ""
          }

          <CDropdownItem
            onClick={() => {
              logout();
            }}
            className="logout-dropdown"
          >
            <CIcon icon={cilLockLocked} className="me-2" />
            <button className="text-black btn-logout ">Logout</button>
          </CDropdownItem>
        </CDropdownMenu>
      </CDropdown>

      <CModal
        alignment="center"
        visible={visible}
        onClose={() => setVisible(false)}
      >
        {/* <CModalHeader>
                      <CModalTitle>Edit Fare</CModalTitle>
                    </CModalHeader> */}
        <CModalBody>
          <CRow>
            <CCol xs={12}>
              <CCard className="mb-4 delete_vehicle_popup">
                <CCardBody>
                  <img src={deletepopup} alt="danger" />
                  <h2>Are you Sure</h2>
                  <p>You want to delete this Driver account ?</p>
                </CCardBody>
                <div className="delete_vehicle_popup_outer">
                  <CButton
                    className="delete_popup"
                    onClick={() => handleDeletItem()}
                  >
                    Delete
                  </CButton>
                  <CButton
                    className="cancel_popup"
                    onClick={() => setVisible(false)}
                  >
                    Cancel
                  </CButton>
                </div>
              </CCard>
            </CCol>
          </CRow>
        </CModalBody>
      </CModal>
    </>
  );
};

export default AppHeaderDropdown;

import React, { useEffect, useState } from "react";
import AppHeader from "../../TopBar/AppHeader";
import {
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
    CButton,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CCard,
    CFormSelect,
    CCardBody,
    CCol,
    CForm,
    CFormInput,
    CFormLabel,
    CRow,
} from "@coreui/react";
import {
    getVehicleType,
    getPriceList,
    editPriceList
} from "../../../utils/api";

import editiconimg from "../../../assets/images/editicon.png";
import deleteiconimg from "../../../assets/images/deleteicon.png";
import SuperSideBar from "../SiderNavBar/Sidebar";
import deletepopup from '../../../assets/images/deletepopup.png'
import EmptyData from "../../EmptyData";
import AppLoader from "../../AppLoader";
import ReactPaginate from 'react-paginate';
import { MDBInputGroup, MDBInput, MDBIcon, MDBBtn } from "mdb-react-ui-kit";
import Switch from "react-switch";

import { toast } from "react-toastify";
const PriceList = () => {


    const [search, setSearch] = useState("");

    const [allPagination, setAllPagination] = useState();
    const [pageNumbers, setPageNumbers] = useState(1);
    const [debouncedSearch, setDebouncedSearch] = useState("");


    const [visible, setVisible] = useState(false);
    const [deleteVisible, setDeleteVisible] = useState(false);
    const [loader, setLoader] = useState(false);
    const [pricelist, setPriceList] = useState([])


    const [editingRowId, setEditingRowId] = useState(null);
    const [editedAmount, setEditedAmount] = useState("");

    const [selectedFare, setSelectedFare] = useState(null);


    const data = pricelist



    const handlePageClick = (selectedPage) => {
        console.log("se", selectedPage)
        setPageNumbers(selectedPage.selected + 1)
    };


    const handleSearchChange = (e) => {
        setSearch(e.target.value);
        setPageNumbers(1);
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            setDebouncedSearch(search);
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
    }, [search]);


    useEffect(() => {

        setLoader(true)
        let data = {
            search: search,
            page: pageNumbers,
            limit: 10,
        }
        getPriceList(data).then((res) => {
            console.log(res);

            if (res?.code === 200) {
                setPriceList(res?.allPriceList)
                setAllPagination(res?.totalItems)
                setLoader(false);
            }
        }).catch((err) => {
            console.log(err);
        });
        ;
    }, [debouncedSearch, pageNumbers]);


    function handleSpecialPlan(item) {

        editPriceList(item._id, {
            status: !item.status,
        })
            .then((res) => {
                console.log("status changed", res);
                if (res.code === 200) {
                    const newCompanyData = data.map((i) => {
                        if (i._id == item._id) {
                            i.status = !item.status;
                            return i;
                        }
                        return i;
                    });
                    setPriceList(newCompanyData);

                }
                toast.success(`${res.message}`, {
                    position: "top-right",
                    autoClose: 1000,
                });
            })
            .catch((error) => {
                console.log(error);
                toast.warning(`${error.message}`, {
                    position: "top-right",
                    autoClose: 1000,
                });
            });
    }


    const handleUpdateAmount = (id) => {
        editPriceList(id, { amount: editedAmount })
            .then((res) => {
                if (res.code === 200) {
                    toast.success(res.message, {
                        position: "top-right",
                        autoClose: 1000,
                    });
                    // Update the local state with the new amount
                    const newData = pricelist.map((item) => {
                        if (item._id === id) {
                            return { ...item, amount: editedAmount };
                        }
                        return item;
                    });
                    setPriceList(newData);
                    setEditingRowId(null);
                    setEditedAmount("");
                } else {
                    toast.warning(res.message, {
                        position: "top-right",
                        autoClose: 1000,
                    });
                }
            })
            .catch((error) => {
                toast.warning(error.message, {
                    position: "top-right",
                    autoClose: 1000,
                });
            });
    };


    const handleCancelEdit = () => {
        setEditingRowId(null);
        setEditedAmount("");
    };

    return (
        <>
            <div className="container-fluidd">
                <div className="col-md-12">
                    <div>
                        <SuperSideBar />
                        <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                            <AppHeader />
                            <div className="body flex-grow-1 px-3">
                                <h1 className="heading-for-every-page">Price List</h1>
                                <div className="serach-left" id="company-search">

                                    <MDBInputGroup>
                                        <MDBInput
                                            value={search}
                                            onChange={handleSearchChange}
                                            placeholder="Search"
                                        />
                                        <button className="search-btn" >
                                            <MDBIcon icon="search" />
                                        </button>
                                    </MDBInputGroup>
                                </div>
                                <div className="active-trip-outer">
                                    <div className="trips-head d-flex justify-content-between">

                                    </div>
                                    {loader ? (
                                        <>
                                            <AppLoader />
                                        </>
                                    ) : (
                                        <>
                                            {data?.length > 0 ? <CTable align="middle" className="mb-0" hover responsive>
                                                <CTableHead>
                                                    <CTableRow>

                                                        <CTableHeaderCell className="text-center">
                                                            Sr No.
                                                        </CTableHeaderCell>
                                                        <CTableHeaderCell className="text-center">
                                                            Vehicle Type
                                                        </CTableHeaderCell>
                                                        <CTableHeaderCell className="text-center">
                                                            Departure Place
                                                        </CTableHeaderCell>
                                                        <CTableHeaderCell className="text-center">
                                                            Arrival Place
                                                        </CTableHeaderCell>

                                                        <CTableHeaderCell className="text-center">
                                                            No of Persons
                                                        </CTableHeaderCell>
                                                        <CTableHeaderCell className="text-center">
                                                            Amount
                                                        </CTableHeaderCell>
                                                        <CTableHeaderCell className="text-center">
                                                            Status
                                                        </CTableHeaderCell>
                                                    </CTableRow>
                                                </CTableHead>
                                                <CTableBody>
                                                    {data?.length
                                                        ? data.map((item, index) => (
                                                            <CTableRow
                                                                className="text-center"
                                                                v-for="item in tableItems"
                                                                key={item._id}
                                                            >
                                                                <CTableDataCell>
                                                                    <div>{(pageNumbers - 1) * 10 + index + 1}</div>
                                                                </CTableDataCell>
                                                                <CTableDataCell>
                                                                    <div>{item?.vehicle_type}</div>
                                                                </CTableDataCell>
                                                                <CTableDataCell>
                                                                    <div>{item?.departure_place}</div>
                                                                </CTableDataCell>
                                                                <CTableDataCell>
                                                                    <div>{item?.arrival_place}</div>
                                                                </CTableDataCell>

                                                                <CTableDataCell>
                                                                    <div>{item?.number_of_person}</div>
                                                                </CTableDataCell>
                                                                <CTableDataCell className="d-flex action-icons driver-icons">
                                                                    {editingRowId === item._id ? (
                                                                        <>
                                                                            <CFormInput
                                                                                type="text"
                                                                                value={editedAmount}
                                                                                onChange={(e) =>
                                                                                    setEditedAmount(e.target.value)
                                                                                }
                                                                                style={{ width: "100px" }}
                                                                            />
                                                                            <CButton
                                                                                onClick={() => handleUpdateAmount(item._id)}
                                                                                className="mx-2"
                                                                            >
                                                                                {/* <MDBIcon icon="check" /> */}
                                                                                <img width="24" height="24" src="https://img.icons8.com/material-sharp/24/22C3E6/checkmark--v1.png" alt="checkmark--v1" />
                                                                            </CButton>
                                                                            <CButton onClick={handleCancelEdit}>
                                                                                {/* <MDBIcon icon="times" /> */}
                                                                                <img width="24" height="24" src="https://img.icons8.com/material-rounded/24/FA5252/multiply--v1.png" alt="multiply--v1" />
                                                                            </CButton>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <div>{item?.amount}</div>
                                                                            <div>
                                                                                <CButton
                                                                                    onClick={() => {
                                                                                        setEditingRowId(item._id);
                                                                                        setEditedAmount(item.amount);
                                                                                    }}
                                                                                >
                                                                                    <img src={editiconimg} alt="edit" />
                                                                                </CButton>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </CTableDataCell>
                                                                <CTableDataCell className="">

                                                                    <Switch
                                                                        checkedIcon={false}
                                                                        uncheckedIcon={false}
                                                                        height={18}
                                                                        width={35}
                                                                        onColor='#14822af5'
                                                                        onChange={() => {
                                                                            handleSpecialPlan(item);
                                                                        }}
                                                                        checked={item.status}
                                                                    />


                                                                    {/* <CButton id="btn_delete_fare" className="delete_vehilce" onClick={() => { setDeleteVisible(!visible); setSelectedFare(item) }}>
                                                                        <img src={deleteiconimg} alt="img" />
                                                                    </CButton> */}
                                                                </CTableDataCell>
                                                            </CTableRow>
                                                        ))
                                                        : ""}
                                                </CTableBody>
                                            </CTable> : <EmptyData />}
                                        </>
                                    )}
                                    <div className='pagination'>
                                        <ReactPaginate
                                            breakLabel="..."
                                            nextLabel=">"
                                            onPageChange={handlePageClick}
                                            pageRangeDisplayed={3}
                                            marginPagesDisplayed={1}
                                            pageCount={Math.ceil(allPagination / 10)}
                                            previousLabel="<"
                                            renderOnZeroPageCount={null}
                                            containerClassName="pagination"  
                                            activeClassName="active-page"  
                                            pageClassName="pagination-item" 
                                            pageLinkClassName="pagination-link"  
                                        />
                                    </div>

                                    {/* farelistmodalpopup */}



                                    {/* endfarelistpopup */}



                                    {/* StartDeletepopup */}


                                    <CModal alignment="center" visible={deleteVisible} onClose={() => setDeleteVisible(false)}>
                                        {/* <CModalHeader>
                          <CModalTitle>Edit Fare</CModalTitle>
                        </CModalHeader> */}
                                        <CModalBody>
                                            <CRow>

                                                <CCol xs={12}>
                                                    <CCard className="mb-4 delete_vehicle_popup">
                                                        <CCardBody>
                                                            <img src={deletepopup} alt="danger" />
                                                            <h2>Are you Sure</h2>
                                                            <p>You want to delete this Vehicle ?</p>

                                                        </CCardBody>
                                                        <div className="delete_vehicle_popup_outer">


                                                            <CButton className="delete_popup"
                                                                onClick={() =>
                                                                    deleteFareHandler(selectedFare._id)
                                                                }
                                                            >Delete</CButton>
                                                            <CButton className="cancel_popup" onClick={() => setDeleteVisible(false)}>
                                                                Cancel</CButton>
                                                        </div>
                                                    </CCard>
                                                </CCol>
                                            </CRow>
                                        </CModalBody>



                                    </CModal>




                                    {/* enddeletepopup */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PriceList

import React, { useMemo , useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import applogo from '../../assets/images/taxi-logo.png'
import sideNavBar from './Siderbardata';
import { SuperBar } from '../SuperAdmin/Sidebar/AppSideNavBar';
import { Link } from "react-router-dom";
import { getProfile } from '../../utils/api';
// console.log(sideNavBar,'nav')

const SidebarDriver = () => {
  const dispatch = useDispatch()
  const unfoldable = useSelector((state) => state.sidebarUnfoldable)
  const sidebarShow = useSelector((state) => state.sidebarShow)

  // console.log("Driveer sidebar ", sideNavBar)
  // const [sidebar  , setSidebar ] = useState([])
  const [sidebar, setSidebar] = useState(sideNavBar);

  // useEffect(() => {
  //   getUser();
  // }, [sideNavBar])

  // const getUser = async() => {
  //   const token = await localStorage.getItem('token');
  //   getProfile(token).then(response => {
  //     console.log("user --- ", response)

  //     if (response.code === 200) {
  //       if(response.result.is_special_plan_active) {
  //        let sidebardata = sideNavBar?.filter(item => item.name !== "Plans")
  //        setSidebar(sidebardata)
  //       }else  {
  //         let sidebardata = sideNavBar
  //         setSidebar(sidebardata)
  //       }
  //     }
  //   }).catch(error => {
  //     console.log(error)
  //   })
  // }


  useEffect(() => {
    const getUser = async () => {
      const token = await localStorage.getItem('token');
      try {
        const response = await getProfile(token);
        if (response.code === 200) {
          if (response.result.is_special_plan_active) {
            const filteredSidebar = sideNavBar.filter((item) => item.name !== 'Plans');
            // console.log(filteredSidebar , "Plans filtered");
            setSidebar(filteredSidebar);
          } else {
            setSidebar(sideNavBar);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    getUser();
  }, []);


  return (
    <CSidebar
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        dispatch({ type: 'set', sidebarShow: visible })
      }}
    >
      <CSidebarBrand className="d-none d-md-flex" to="/">
        {/* <CIcon className="sidebar-brand-full" icon={logoNegative} height={35} />
          <CIcon className="sidebar-brand-narrow" icon={sygnet} height={35} /> */}
        <Link to={`/dashboard`} >
          <img src={applogo} height={50} width={100} alt="App Logo" />
        </Link>
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <SuperBar className="sidebar_outer" items={sidebar} />
        </SimpleBar>
      </CSidebarNav>
    </CSidebar>
  )
}

export default SidebarDriver;
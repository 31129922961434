import React, { useEffect, useState } from "react";
import { CCard, CCardBody, CCol, CRow } from "@coreui/react";
import available from "../../assets/images/available.png";
import { stipeOnboard, verificationCompleted } from "../../../src/utils/api";
import { toast } from "react-toastify";
import { Navigate, useParams } from "react-router";
import { useNavigate } from "react-router";
import { CButton } from "@coreui/react";

const BankVerificationCompleted = () => {
  const navigate = useNavigate();
  const {id} = useParams()

  const [message, setMessage] = useState("");
 
  const buttonHandler = () => {
    navigate("/")
  };
  

  const fetchVerification = async () => {
    try {
      const response = await verificationCompleted(id);
      console.log("API Response:", response); 
      if (response?.code === 200) {
        toast.success(response?.message || "Bank Account Verification has been successfully completed.");
        setMessage(response?.message || "Bank Account Verification completed.");
      } else {
        toast.error(response?.message || "Verification failed.");
        setMessage(response?.message || "Verification failed.");
      }
    } catch (error) {
      console.error("API error:", error);
      toast.error("Something went wrong. Please try again.");
      setMessage("An error occurred during verification.");
    }
  };

  useEffect(() => {
    fetchVerification();
    
  }, []);

 

  return (
    <div>
    <div className="main-page" style={{ backgroundColor: "#0682ca" }}>
      <div className="container-outer" id="expired_outer" style={{ background: "#0682ca" }}>
        <div className="banner-inner-text col-md-4">
          <CRow>
            <CCol xs={12}>
              <CCard style={{boxShadow:"5px 5px 15px rgba(2, 1, 1, 0.5)"}}>
                <CCardBody className="py-5" style={{ textAlign: "center" }}>
                  <img src={available} width={60} />
                  <p className="mt-3 mb-0 success_title">
                  {/* Bank Account Verification has been successfully completed. */}
                  {message}
                  </p>
                  <CButton className="change_pwd_btn" style={{ marginTop: "10px", width:'30%',background: "#0682ca" }} onClick={buttonHandler}>
                        ok 
                   </CButton>
                </CCardBody>
                
              </CCard>
            </CCol>
          </CRow>
        </div>
      </div>
    </div>
  </div>
  )
}
export default BankVerificationCompleted

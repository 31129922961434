import React, { useState } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/footer";
// import { FaCar, FaUser, FaTruck, FaBuilding, FaMapMarkerAlt, FaWallet, FaCalendarAlt, FaSatelliteDish } from "react-icons/fa";
import hero from '../../assets/images/hero-image.png';
import aboutImg from '../../assets/images/home-taxi.png'
import { Link } from "react-router-dom";
//import background from '../assets/images/heroimg.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faEnvelope, faCommentDots } from "@fortawesome/free-solid-svg-icons";
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';

const Home = () => {
  const [selectedTab, setSelectedTab] = useState("drivers");


  const services = {
    drivers: {
      title: "For Drivers",
      icon: "bi bi-car-front-fill",
      features: [
        { icon: "fa fa-location-dot", text: "Real-time Trip Management" },
        { icon: "fa fa-credit-card", text: "Fast Payments & Invoicing" },
        { icon: "fa fa-calendar-check", text: "Flexible Working Hours" },
        { icon: "fa fa-satellite-dish", text: "GPS Dispatch System" },
      ],
    },
    passengers: {
      title: "For Passengers",
      icon: "bi bi-person-fill",
      features: [
        { icon: "fa fa-mobile-screen", text: "Mobile App for Bookings" },
        { icon: "bi bi-clock-fill", text: "Fast and Reliable Taxis" },
        { icon: "fa fa-money-bill", text: "Transparent Pricing" },
        { icon: "fa fa-car-side", text: "Trip History & Favorite Drivers" },
      ],
    },
    transport: {
      title: "For Transport Companies",
      icon: "bi bi-truck",
      features: [
        { icon: "fa fa-chart-line", text: "Central Dispatch Software" },
        { icon: "fa fa-warehouse", text: "Driver & Vehicle Management" },
        { icon: "fa fa-chart-bar", text: "Advanced Analytics" },
        { icon: "fa fa-link", text: "Links with Accounting Systems" },
      ],
    },
    commercial: {
      title: "For Commercial",
      icon: "bi bi-buildings",
      features: [
        { icon: "fa fa-file-invoice-dollar", text: "Corporate Trip Management" },
        { icon: "fa fa-calendar", text: "Expense Reports" },
        { icon: "fa fa-taxi", text: "Team Coordination" },
        { icon: "fa fa-briefcase", text: "Customized Services" },
      ],
    },
  };

  const features = [
    { title: "Advanced transportation management", description: "Optimize route planning and increase efficiency.", icon: "fa fa-route" },
    { title: "Live ride tracking", description: "Track vehicles and rides in real-time.", icon: "fas fa-map-marker-alt" },
    { title: "Intuitive user interface", description: "Easy to use, designed for speed and ease.", icon: "fas fa-desktop" },
    { title: "Smart dispatching", description: "Automatic assignment of rides.", icon: "fa fa-bolt" },
    { title: "Customized solutions", description: "Flexible options tailored to your needs.", icon: "fas fa-cogs" },
    { title: "More chances to win", description: "Earn additional revenue with better distribution.", icon: "fa fa-sack-dollar" }
  ];


  const pricingPlans = [
    { name: "Basic", price: 79, description: "For small taxi companies", features: ["Trip management", "Drivers App", "Advanced Reporting"], buttonText: "Start Now" },
    { name: "Pro", price: 179, description: "For growing companies", features: ["All Basic features", "Advanced Reporting", "API Integration"], buttonText: "Choose Pro" }
  ];
  const testimonials = [
    { text: "Dispatch’s software has improved our route planning tremendously! It’s intuitive and efficient.", author: "Eva Jensen" },
    { text: "Thanks to Dispatch we can now collaborate better with our drivers and save time!", author: "Mark de Boer" }
  ];
  const solutions = [
    { title: "For Partners", description: "Expand your reach and automate your taxi business with our advanced platform.", buttonText: "More information for partners", icon: <i className="fa-solid fa-handshake"></i> },
    { title: "For Drivers", description: "Manage your rides effortlessly, get more bookings and increase your earnings.", buttonText: "More information for drivers", icon: <i className="bi bi-car-front-fill"></i> }
  ];
  const faqs = [
    { question: "How does ride allocation work?", answer: "Our system uses smart algorithms to match drivers with passengers efficiently." },
    { question: "Can I change my subscription?", answer: "Yes, you can upgrade or downgrade your subscription at any time." },
    { question: "Are there discounts for companies?", answer: "Yes, we offer special discounts for fleet operators and large companies." }
  ];


  return (
    <>
      <Header />
      <div className="main-page">
        <div className="container-outer">
          {/* Hero Section */}
          <section className="hero-section">
            <div className="container">
              <div className="row align-items-center text-section">
                <div className="col-md-6">
                  <h1 className="bannertext text-start pb-4">The smart software for <br /> taxi dispatch and bookings</h1>
                  <p className="text-para text-start">Automate rides, increase efficiency, and provide a seamless customer experience—all-in-one software for taxi and transportation services.</p>
                  <div className="text-start mt-4">
                    <button className="btn-start-now fw-bold">Start Now for free</button>
                  </div>
                </div>
                <div className="col-md-6">
                  <img src="/images" alt="Taxi Dispatch software" />
                </div>
              </div>
            </div>
          </section>


          {/* Mobility Solution Section */}
          <div className="about-us-section">
            <div className="container">
              <div className=" text-center my-5">
                <h2 className="fw-bold dispatch-main-text">
                  Who is the <span className="text-dispatch">Idispatch</span> app the ideal mobility solution for?
                </h2>
                <p className="text-muted p-dispatch">
                  From taxis and transport companies to hotels and catering – Idispatch offers the all-in-one mobility solution.
                </p>

                <div className="row mt-5">
                  <div className="col-md-6 mb-4">
                    <div className="feature-card d-flex " style={{ height: '100%' }}>
                      <div className="icon-box1 me-3">
                        <i className="fas fa-car "></i>
                      </div>
                      <div className="text-start">
                        <h5 className=" text-black">Drivers App</h5>
                        <p className="textmuted ">Optimize journeys and manage drivers in real-time with our smart app.</p>
                        <div className="main-readmore mb-4 ">
                          <Link href="#" className="read-more-link fw-bold">Read More <i class="bi bi-chevron-right" style={{ fontSize: '11px' }}></i></Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 mb-4">
                    <div className="feature-card d-flex ">
                      <div className="icon-box1 me-3">
                        <i className="fas fa-user"></i>
                      </div>
                      <div className="text-start">
                        <h5 className=" text-black">Passenger App</h5>
                        <p className="textmuted ">Easily book, track and pay for rides through an intuitive mobile app.</p>
                        <div className="main-readmore mb-4">
                          <Link href="#" className="read-more-link fw-bold">Read More  <i class="bi bi-chevron-right" style={{ fontSize: '11px' }}> </i></Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 mb-4">
                    <div className="feature-card d-flex " style={{ height: '100%' }}>
                      <div className="icon-box1 me-3">
                        <i className="fas fa-bus"></i>
                      </div>
                      <div className="text-start">
                        <h5 className=" text-black">Transport Companies App</h5>
                        <p className="textmuted ">Manage your fleet, optimize routes and increase efficiency.</p>
                        <div className="main-readmore mb-4">
                          <Link href="#" className="read-more-link fw-bold">Read More <i class="bi bi-chevron-right" style={{ fontSize: '11px' }}> </i></Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 mb-4">
                    <div className="feature-card d-flex ">
                      <div className="icon-box1 me-3">
                        <i className="fas fa-briefcase"></i>
                      </div>
                      <div className="text-start">
                        <h5 className=" text-black">Business App</h5>
                        <p className="textmuted">Efficiently plan and manage journeys for business customers and employees.</p>
                        <div className="main-readmore mb-4">
                          <Link href="#" className="read-more-link fw-bold">Read More <i class="bi bi-chevron-right" style={{ fontSize: '11px' }}> </i></Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Service Section */}
          <section className="service-section py-5">
            <div className="container">
              <div className="row justify-content-center text-center text-white">
                <div className="col-lg-10">
                  <h2 className="fw-bold" style={{ fontSize: '35px' }}>
                    Which <span className="textdispatch">|</span> dispatch service is ideal for your business?
                  </h2>
                  <p className="text-gray" style={{ color: "#bbb0b0" }}>Save time and money with advanced dispatch, trip management, and invoicing tools.<br />Select the service that makes your business work smarter.
                  </p>
                </div>
              </div>

              {/* Tabs Section */}
              <div className="row justify-content-center">
                <div className="col-lg-10">
                  <div className="d-flex flex-wrap justify-content-center gap-3  service-main-button">
                    {Object.keys(services).map((key) => (
                      <button
                        key={key}
                        className={`service-button ${selectedTab === key ? "active" : ""}`}
                        onClick={() => setSelectedTab(key)}
                        style={{fontWeight:'600'}}
                      >
                        <i className={`${services[key].icon} me-2`}></i>
                        {services[key].title?.replace("For","")}
                      </button>
                    ))}
                  </div>
                </div>
              </div>

              {/* Service Details - Dynamically Rendered */}
              <div className="row justify-content-center">
                <div className="col-lg-12 active-cont" style={{marginBottom:'50px'}}>
                  <div className="service-box p-4">
                    <h5 className="d-flex align-items-center text-color">
                      <span className="icon-box1 me-2">
                        <i className={services[selectedTab].icon}></i>
                      </span>
                      {services[selectedTab].title}
                    </h5>
                    <div className="row g-3">
                      {services[selectedTab].features.map((feature, index) => (
                        <div key={index} className="col-md-6">
                          <div className="feature-card1 transition p-3 border">
                            <i className={`${feature.icon} me-2`}></i>
                            <p className="p-2 mt-2" style={{fontWeight:'500'}}>{feature.text}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* why choose dispatch Section */}
          <section className="container my-5">
            <h2 className=" text-black text-start" style={{ fontWeight: '800', fontSize: '35px' }}>Why choose <span className="text-color">Idispatch?</span></h2>
            <div className="text-left">
              <p className="">Discover how our software can make your taxi business more efficient and <br /> profitable. </p>
            </div>
            <div className="row mt-4">
              {features.map((feature, index) => (
                <div key={index} className="col-md-12 col-lg-4 mb-3">
                  <div className="card-choose transition p-4 h-100 shadow-sm d-flex gap-4">
                    <div className="icon-container mb-3">
                      <i className={`${feature.icon} fa-1x`}></i>
                    </div>
                    <div className="text-start mt-2">
                      <h5>{feature.title}</h5>
                      <p style={{ fontSize: '15px' }}>{feature.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </section>



          {/* our price Section */}
          {/* <section className="container-fluid text-center my-5 p-5 our-price">
            <h2 className=" text-black" style={{fontWeight:'800',fontSize:'35px'}}>Our <span className="text-color">Prices</span></h2>
            <p>Choose the plan that best suits your business and get started today.</p>
            <div className="row justify-content-center mt-4">
              {pricingPlans.map((plan, index) => (
                <div key={index} className="col-md-6 col-lg-4 mb-3">
                  <div className="card p-4 shadow-sm transition">
                    <h4 className="text-color">{plan.name}</h4>
                    <h2 className="fw-bold text-black">€{plan.price} <small>/month</small></h2>
                    <p>{plan.description}</p>
                    <hr class="my-6 border-gray-200"></hr>
                    <ul className="list-unstyled text-start">
                      {plan.features.map((item, i) => (
                        <li key={i} className="text-black"><i class="bi bi-check-lg"></i> {item}</li>
                      ))}
                    </ul>

                    <button className="btn btn-pro mt-3">{plan.buttonText}</button>
                  </div>
                </div>
              ))}
            </div>
          </section> */}

          <section className="container-fluid text-center my-5 p-5 our-price">
            <h2 className="text-black my-2" style={{ fontWeight: '800', fontSize: '35px' }}>
              Our <span className="text-color">Prices</span>
            </h2>
            <p className="my-2">Choose the plan that best suits your business and get started today.</p>

            <div className="row justify-content-center mt-4">
              {/* Basic Plan */}
              <div className="col-md-6 col-lg-4 mb-3 mt-4">
                <div className="card p-4 transition" style={{ borderRadius:'15px' }}>
                  <h4 className="text-color">Basic</h4>
                  <h2 className="fw-bold text-black">€79 <small>/month</small></h2>
                  <p>For small taxi companies</p>
                  <hr className="my-6 border-gray-200"></hr>
                  <ul className="list-unstyled text-start">
                    <li className="text-black"><i className="bi bi-check-lg"></i> Trip management</li>
                    <li className="text-black"><i className="bi bi-check-lg"></i> Drivers App</li>
                    <li className="text-black"><i className="bi bi-check-lg"></i> Advanced Reporting</li>
                  </ul>
                  <button className="btn btn-pro mt-3">Start Now</button>
                </div>
              </div>

              {/* Pro Plan */}
              <div className="col-md-6 col-lg-4 mb-3 mt-4">
              <div className="recomeneded">
              <p style={{marginBottom:'5px',color:'white',fontSize:'14px'}}>Recommended</p>
              </div>
                <div className="card p-4 transition" style={{ borderRadius:'15px' }}>
              
                  <h4 className="text-color">Pro</h4>
                  <h2 className="fw-bold text-black">€179 <small>/month</small></h2>
                  <p>For growing companies</p>
                  <hr className="my-6 border-gray-200"></hr>
                  <ul className="list-unstyled text-start">
                    <li className="text-black"><i className="bi bi-check-lg"></i> All Basic features</li>
                    <li className="text-black"><i className="bi bi-check-lg"></i> Advanced Reporting</li>
                    <li className="text-black"><i className="bi bi-check-lg"></i> API Integration</li>
                  </ul>
                  <button className="btn btn-pro mt-3">Choose Pro</button>
                </div>
              </div>
            </div>
          </section>


          {/* review Section */}
          <section className="container text-center my-5 ">
            <p className="text-muted ">What I-Dispatch users say about Idispatch</p>
            <h2 className=" text-black " style={{ fontWeight: '800', fontSize: '35px' }}>Customers gave their <span className="text-color">Feedback</span></h2>
            <div className="row justify-content-center mt-5">
              {testimonials.map((testimonial, index) => (
                <div key={index} className="col-md-6 col-lg-5 mb-3">
                  <div className="card-review p-4 transition">
                    <p className="text-black"><i class="bi bi-star-fill"> </i><i class="bi bi-star-fill"> </i><i class="bi bi-star-fill"> </i> <i class="bi bi-star-fill"> </i><i class="bi bi-star-fill"></i></p>
                    <p>"{testimonial.text}"</p>
                    <p className="fw-bold text-muted">- {testimonial.author}</p>
                  </div>
                </div>
              ))}
            </div>
          </section>


          {/* start now Section */}
          <section>
            <div className="container-fluid">
              <div className="row">
                <div className="strat-now">
                  <div className="col-md-12">
                    <h1 className="strat-now-head py-2">Start Now With Dispatch Mobility</h1>
                    <p className="text-light">Try our software for free and discover the benefits.</p>
                    <Link to="/register">
                    <div>
                      <button className=" btn-light sign-up-buton" >Sign up now!</button>
                    </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* solution for  Section */}
          <section className="container-fluid text-center solution-for">
            <h2 className=" text-black" style={{ fontWeight: '800', fontSize: '35px' }}>Who is our solution for?</h2>
            <p className="p-2">Discover how we help taxi companies and drivers make their work more efficient <br /> and profitable with our innovative software.</p>
            <div className="row mt-4 d-flex justify-content-center">
              {solutions.map((solution, index) => (
                <div key={index} className="col-md-6 col-lg-5 mb-3">
                  <div className="card p-5 transition shadow-sm d-flex  justify-content-center align-items-center">
                    <span class="icon-solution me-2">
                      <i className="">{solution.icon}</i>
                    </span>
                    <h5 className="p-2">{solution.title}</h5>
                    <p className="p-2" style={{ fontSize: "15px" }}>{solution.description}</p>
                    <button className="solu-butn">{solution.buttonText}</button>
                  </div>
                </div>
              ))}
            </div>
          </section>

          {/* faq Section */}
          <section className="container text-center my-5">
            <h2 className="" style={{ fontWeight: '800', fontSize: '35px' }}>Frequently Asked Questions</h2>
            <p className="pb-4">Here you will find answers to the most frequently asked questions about our platform.</p>
            {/* <div className="accordion" id="faqAccordion">
              {faqs.map((faq, index) => (
                <div key={index} className="accordion-item">
                  <h2 className="accordion-header" id={`heading${index}`}>
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`}>
                      {faq.question}
                    </button>
                  </h2>
                  <div id={`collapse${index}`} className="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                    <div className="accordion-body">{faq.answer}</div>
                  </div>
                </div>
              ))}
            </div> */}

            {/* <div class="space-y-6">

              <div class="faq-container">
                <button class="faq-toggle">
                  <span>How does ride allocation work?</span>
                  <span class="icon">+</span>
                </button>
                <div class="faq-content">
                  Our system uses smart algorithms to assign rides based on driver availability and location.
                </div>
              </div>

              <div class="faq-container">
                <button class="faq-toggle">
                  <span>Can I change my subscription?</span>
                  <span class="icon">+</span>
                </button>
                <div class="faq-content">
                  Yes, you can change your subscription at any time through your account settings or by contacting support.
                </div>
              </div>
              <div class="faq-container">
                <button class="faq-toggle">
                  <span>Are there discounts for companies?</span>
                  <span class="icon">+</span>
                </button>
                <div class="faq-content">
                  Yes, we offer special rates for companies that connect multiple drivers to our platform.
                </div>
              </div>

            </div> */}


            <div>
              <Accordion className="acordin-main">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography component="span">How does ride allocation work?</Typography>
                </AccordionSummary>
                <AccordionDetails className="acordin-detail">

                  Our system uses smart algorithms to assign rides based on driver availability and location.

                </AccordionDetails>
              </Accordion>
              <Accordion className="acordin-main">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography component="span">Can I change my subscription?</Typography>
                </AccordionSummary>
                <AccordionDetails className="acordin-detail">

                  Yes, you can change your subscription at any time through your account settings or by contacting support.

                </AccordionDetails>
              </Accordion>
              <Accordion className="acordin-main" defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3-content"
                  id="panel3-header"
                >
                  <Typography component="span">Are there discounts for companies?</Typography>
                </AccordionSummary>
                <AccordionDetails className="acordin-detail">

                  Yes, we offer special rates for companies that connect multiple drivers to our platform.

                </AccordionDetails>
              </Accordion>
            </div>

          </section>

          {/* stay informed Section */}
          <section>
            <div className="container-fluid">
              <div className="row">
                <div className="strat-now">
                  <div className="col-md-12">
                    <h1 className="strat-now-head py-2">Stay Informed</h1>
                    <p className="text-light">Subscribe to our newsletter and receive updates about new features.</p>
                    <form class="form-container">
                      <div class="input-container">
                        <svg class="input-icon" style={{ width: "20px" }} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="envelope" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                          <path fill="currentColor" d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"></path>
                        </svg>
                        <input type="email" placeholder="Your email address" class="input-field" required />
                      </div>

                      <button type="submit" class="enrol-btn">To enrol</button>
                    </form>

                  </div>
                </div>
              </div>
            </div>
          </section>

          {/*] contact Section */}
          <section className="container-contact text-center p-5">
            <div className="card p-5 shadow-lg mx-auto" style={{ maxWidth: "700px", backgroundColor: 'rgb(255 255 255)' }}>
              <h2 className="fw-bold">Contact Us</h2>
              <p>Do you have any questions? Fill out the form and we will contact you.</p>
              <form>
                <div className="mb-3 input-group form-main">
                  <span className="input-group-text input-text1">
                    <FontAwesomeIcon icon={faUser} style={{ color: "rgb(156, 163, 175)" }} />
                  </span>
                  <input type="text" className="form-input1 form-control " placeholder="Your Name" />
                </div>
                <div className="mb-3 input-group form-main">
                  <span className="input-group-text input-text1">
                    <FontAwesomeIcon icon={faEnvelope} style={{ color: "rgb(156, 163, 175)" }} />
                  </span>
                  <input type="email" className="form-control form-input1" placeholder="Your Email Address" />
                </div>
                <div className="mb-3 input-group form-main">
                  <span className="input-group-text  input-text1" style={{ display: 'block' }}>
                    <FontAwesomeIcon icon={faCommentDots} style={{ color: "rgb(156, 163, 175)" }} />
                  </span>
                  <textarea className="form-control form-input1" placeholder="Your Message" rows="4" cols="50" style={{ height: '150px', resize: 'none' }}></textarea>
                </div>
                <button type="submit" className="sen-buton w-100">Send</button>
              </form>
            </div>
          </section>


        </div>
      </div >

      {/* <Footer /> */}
    </>
  );
};

export default Home; 
import React, { useEffect, useState } from "react";
import { CCard, CCardBody, CCol, CRow } from "@coreui/react";
import clock from "../../assets/images/clock.png";
import { useNavigate, useParams } from "react-router";
import { CButton } from "@coreui/react";
import {verificationCompleted } from "../../../src/utils/api";
import { toast } from "react-toastify";

const BankAccountVeification = () => {
  const {id} = useParams()
  const navigate = useNavigate();
  const Handlerpending = () => {
    navigate("/")
  };

  const [message , setMessage] = useState("")

  const fetchVerification = async () => {
    try {
      const response = await verificationCompleted(id);
      console.log("API Response:", response);
      if (response?.code === 200) {
        toast.success( response?.message || "Bank Account Verification Pending.");
          setMessage(response?.message || "Bank Account Verification Pending.")
      } else {
        toast.error(response?.message || "Something went wrong");
        setMessage(response?.message || "Something went wrong")
        console.log("response", response)
      }
    } catch (error) {
      console.error("API error:", error);
      toast.error(error?.message || "An error occurred while verifying the bank account.");
      setMessage(error?.message || "An error occurred while verifying the bank account.")
    }
  };

  useEffect(() => {
    fetchVerification();

  }, []);

  return (
    <div>
      <div className="main-page" style={{ background: "#fdbd1b" }}>
        <div className="container-outer" id="expired_outer" style={{ background: "#0682ca" }}>
          <div className="banner-inner-text col-md-4">
            <CRow>
              <CCol xs={12}>
                <CCard style={{ boxShadow: "5px 5px 15px rgba(2, 1, 1, 0.5)" }}>
                  <CCardBody className="py-5" style={{ textAlign: "center" }}>
                    <img src={clock} width={50} />
                    <p className="mt-3 mb-0 success_title">
                      {message}
                      {/* Your bank account verification is still pending and awaiting completion */}
                    </p>
                    <CButton className="change_pwd_btn" style={{ marginTop: "10px", width: '30%', background: "#0682ca" }} onClick={Handlerpending}>
                      ok
                    </CButton>
                  </CCardBody>
                </CCard>
              </CCol>
            </CRow>
          </div>
        </div>
      </div>
    </div>
  )
}
export default BankAccountVeification

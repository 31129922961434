import React, { useEffect, useState } from "react";
import AppHeader from "../../TopBar/AppHeader";

import {
  Row,
  Col,
  Card,
} from 'react-bootstrap';

import {
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CButton,
  CModal,
  CModalBody,
  CCardBody,
  CRow,
  CCard,
  CCol,
  CFormInput,
  CFormLabel,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import { Link, useNavigate } from 'react-router-dom';
// import vehicle1 from '../../../assets/images/vehicle1.png';
import { deleteDriver, deleteVehicle, getVehicle, adminAllVehicle } from "../../../utils/api";
import PulseLoader from "react-spinners/PulseLoader";
import SuperSideBar from "../SiderNavBar/Sidebar";
import editvehicleicon from "../../../assets/images/editvehi.png";
import deletevehicleicon from "../../../assets/images/deletevehi.png"
import deletepopup from '../../../assets/images/deletepopup.png'
import { toast } from "react-toastify";
import EmptyData from "../../EmptyData";
import SuperAdminSideBar from "../../SuperAdmin/Sidebar/SideBar";
import AppLoader from "../../AppLoader";
import ReactPaginate from 'react-paginate';
import { MDBInputGroup, MDBInput, MDBIcon, MDBBtn } from "mdb-react-ui-kit";
import editiconimg from "../../../assets/images/editicon.png"


const LisOfVehicles = ({ role }) => {


  const [visible, setVisible] = useState(false)
  const [vehicle, setVehicle] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [loader, setLoader] = useState(false);
  const [activeTrip, setActiveTrip] = useState([]);

  const [currentPage, setCurrentPage] = React.useState(1);
  const recordPage = 10;
  const lastIndex = currentPage * recordPage;
  const firstIndex = lastIndex - recordPage;
  const data = vehicle



  const [allPagination, setAllPagination] = useState();
  const [pageNumbers, setPageNumbers] = useState(1);
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [search, setSearch] = useState("");


  const handlePageClick = (selectedPage) => {
    console.log("se", selectedPage)
    setPageNumbers(selectedPage.selected + 1)
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setPageNumbers(1);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setDebouncedSearch(search);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);


  const navigate = useNavigate();

  useEffect(() => {
    getVehicledetail();
  }, [debouncedSearch, pageNumbers]);


  const getVehicledetail = () => {
    let info = {
      name: search,
      page: pageNumbers,
      limit: 10,
    }
    setLoader(true)
    adminAllVehicle(info).then(res => {
      // console.log(res.result, 'vehicle')
      if (res?.code === 200) {
        setVehicle(res.result)
        setAllPagination(res?.totalCount)
      }
      setLoader(false)
    });
  }



  const deleteVehicleHandler = async () => {
    try {
      console.log(selectedId, 'vehicle deleted id')
      const deleteData = await deleteVehicle(selectedId);
      console.log(deleteData, "delete vehicle data")
      if (deleteData.code === 200) {
        setVisible(false);
        toast.success(`${deleteData.message}`, {
          position: 'top-right',
          autoClose: 1000,
        });
        // const newData = vehicle.filter(d => d._id != selectedId);
        // console.log(newData);
        // setVehicle(newData)
      } else {
        toast.warning(`${deleteData.message}`, {
          position: 'top-right',
          autoClose: 1000,
        });
      }
    } catch (error) {
      console.log(error)
    }
  }


  return (
    <>
      <div className="container-fluid px-0">
        <div className="col-md-12">
          <div>
            {role == "super" ? <SuperAdminSideBar /> : <SuperSideBar />}
            <div className="wrapper d-flex flex-column min-vh-100 bg-light">
              <AppHeader />
              <div className="body flex-grow-1 px-3" style={{ paddingBottom: "20px" }}>
                <div className="d-flex justify-content-between">

                  <h1 className="heading-for-every-page ms-2">
                    All Vehicle
                  </h1>
                  <div className="serach-left" id="company-search">

                    <MDBInputGroup>
                      <MDBInput
                        value={search}
                        onChange={handleSearchChange}
                        placeholder="Search"
                      />
                      <button className="search-btn" >
                        <MDBIcon icon="search" />
                      </button>
                    </MDBInputGroup>
                  </div>
                </div>
                <div className="active-trip-outer">


                  <div className='p-4 vehicle-cards'>
                    {loader ? (<>
                      <AppLoader />

                    </>) : (<>
                      {data.length > 0 ?

                        <CTable
                          align="middle"
                          className="mb-0 mt-3 text-center"
                          hover
                          responsive>
                          <CTableHead>
                            <CTableRow>
                              <CTableHeaderCell>
                                Sr. No.
                              </CTableHeaderCell>
                              <CTableHeaderCell>
                                Vehicle Number
                              </CTableHeaderCell>
                              <CTableHeaderCell>
                                Vehicle Model
                              </CTableHeaderCell>
                              <CTableHeaderCell>
                                Vehicle Make
                              </CTableHeaderCell>
                              <CTableHeaderCell>
                                Vehicle Type
                              </CTableHeaderCell>
                              <CTableHeaderCell>
                              Driver Name
                              </CTableHeaderCell>
                              <CTableHeaderCell>
                                Seating Capacity
                              </CTableHeaderCell>
                              <CTableHeaderCell>
                                Action
                              </CTableHeaderCell>
                              <CTableHeaderCell>
                                View Vehicle
                              </CTableHeaderCell>
                            </CTableRow>
                          </CTableHead>

                          <CTableBody>
                            {data?.map((item, index) => (
                              <CTableRow >
                                <CTableDataCell>
                                  <div>{firstIndex + index + 1}</div>
                                </CTableDataCell>
                                <CTableDataCell>
                                  <div>{item?.vehicle_number}</div>
                                </CTableDataCell>

                                <CTableDataCell>
                                  <div>{item?.vehicle_model}</div>
                                </CTableDataCell>
                                <CTableDataCell>
                                  <div>{item?.vehicle_make}</div>
                                </CTableDataCell>
                                <CTableDataCell>
                                  <div>{item?.vehicle_type}</div>
                                </CTableDataCell>
                                <CTableDataCell>
                                  <div>{item?.agency_user_id?.first_name} {item?.agency_user_id?.last_name}</div>
                                </CTableDataCell>
                                <CTableDataCell>
                                  <div>{item?.seating_capacity}</div>
                                </CTableDataCell>

                                <CTableDataCell>
                                  <CButton
                                    id="edit_company_btn"
                                    onClick={() => { setVisible(!visible); setSelectedId(item._id) }}>
                                    <img src={deletevehicleicon} alt="edit-icon" height={"34px"} />
                                  </CButton>
                                  <Link to={`/super-admin/vehicle/editvehicle/${item._id}`}>
                                    <CButton id="edit_company_btn" className="edit_vehicle"

                                    ><img src={editvehicleicon} alt="edit-icon" height={"24px"} /></CButton>
                                  </Link>

                                </CTableDataCell>

                                <CTableDataCell className="">
                                  <Link
                                    to={`/super-admin/vehicle/vehicle-details/${item._id}`}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      fill="currentColor"
                                      className="bi bi-eye-fill"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                                      <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                                    </svg>
                                  </Link>
                                </CTableDataCell>

                              </CTableRow>
                            ))}


                          </CTableBody>
                        </CTable>

                        : <></>}

                    </>)}
                    <div className='pagination'>
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={1}
                        pageCount={Math.ceil(allPagination / 10)}
                        previousLabel="<"
                        renderOnZeroPageCount={null}
                        containerClassName="pagination"  
                        activeClassName="active-page"  
                        pageClassName="pagination-item" 
                        pageLinkClassName="pagination-link"  
                      />
                    </div>

                    {/* StartDeletepopup */}


                    <CModal alignment="center" visible={visible} onClose={() => setVisible(false)}>
                      {/* <CModalHeader>
                      <CModalTitle>Edit Fare</CModalTitle>
                    </CModalHeader> */}
                      <CModalBody>
                        <CRow>

                          <CCol xs={12}>
                            <CCard className="mb-4 delete_vehicle_popup">
                              <CCardBody>
                                <img src={deletepopup} alt="danger" />
                                <h2>Are you Sure</h2>
                                <p>You want to delete this Vehicle ?</p>

                              </CCardBody>
                              <div className="delete_vehicle_popup_outer">


                                <CButton className="delete_popup"
                                  onClick={() => {
                                    deleteVehicleHandler()
                                  }}
                                >Delete</CButton>
                                <CButton className="cancel_popup" onClick={() => setVisible(false)}>
                                  Cancel</CButton>
                              </div>
                            </CCard>
                          </CCol>
                        </CRow>
                      </CModalBody>



                    </CModal>




                    {/* enddeletepopup */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LisOfVehicles;

import React, { useState } from "react";
import logo from '../../assets/images/taxi-logo.png';
import { Link } from "react-router-dom";
import personfill from '../../assets/images/person-fill.svg'
import GoogleTranslate from "../../GoogleTranslate";

const Header = () => {
  //const [showLinks, setShowLinks] = useState(false);
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  return (
    <header>

      <div className="container-header">
        <div className="main-header">
          <div className="logo d-flex">
        
            <div class="text-container">
              <span class="blue-text">I</span>
              <span class="black-text">dispatch</span>
              <span class="gray-text">mobility</span>
            </div>

            <div className="nav-bar">
              <nav className="navigation">
                <button
                  className="hamburger"
                  onClick={() => {
                    setIsNavExpanded(!isNavExpanded);
                  }}
                >
                  {/* icon from Heroicons.com */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="white"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
                <div
                  className={
                    isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
                  }
                >
                  <ul>
                    <li><a href="/">Home</a></li>
                    <li class="menu-item">
                      <button class="menu-button">
                        <span style={{ fontSize: '15px' }}>Products</span>
                        <svg class="icon" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                          <path fill="currentColor" d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"></path>
                        </svg>
                      </button>

                      <div class="dropdown-menu product-dropdown" >
                        <a href="#" class="dropdown-item drp">Driver app</a>
                        <a href="#" class="dropdown-item drp">Passenger app</a>
                        <a href="#" class="dropdown-item drp">Transport companies</a>
                        <a href="#" class="dropdown-item drp">Business service providers</a>
                      </div>
                    </li>

                    <li><Link to="/about">About Us</Link></li>
                    {/* <li><Link to="/get-a-car">Get a Car</Link></li> */}
                    {/* <li><Link to="/booking">Booking</Link></li> */}
                    <li><Link to="/contact">Contact </Link></li>
                    {/* <li><Link to="/plans">Plans</Link></li> */}
                    <div className="mobile-login-btn">
                      <li className="user-login"><Link to="/login">Login</Link></li>
                      {/* <li className="sign-list"><Link to="/signup">Sign Up</Link></li> */}
                    </div>
                   
                  </ul>
                </div>
              </nav>
            </div>
          </div>
          <div className="group-btns">
          <GoogleTranslate/>
            {/* <Link to="/register">
              <div className="header-buttons">
                <button className="login-btn1">Become a partner</button>
               
              </div>
            </Link> */}
            <Link to="/login">
              <div className="header-buttons">
                <span className="login-link">
                  <img src={personfill} alt="" className="login-icon" /> Login
                </span>
              </div>
            </Link>



          </div>
        </div>

      </div>

    </header>
  );
};

export default Header; 
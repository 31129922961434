// import React, { useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const stripePromise = loadStripe("pk_test_R9FCBxRL1OituIRiYkzbiBbn");
// import { Elements } from "@stripe/react-stripe-js";

import React, { useState } from "react";
import { useStripe, useElements, IbanElement } from "@stripe/react-stripe-js";

function IbanForm() {


    return (
        <Elements stripe={stripePromise}>
            <IbanComponent />
        </Elements>
    )
}

const IbanComponent = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) return;

        setLoading(true);

        // const ibanElement = elements.getElement(IbanElement);
        // console.log(ibanElement, "stripe", stripe);
        // const { token, error } = await stripe.createToken(ibanElement);

        const ibanElement = elements.getElement(IbanElement);
        const { token, error } = await stripe.createToken(ibanElement, {
            currency: 'eur', // Replace 'eur' with the appropriate currency code
        });

        console.log("Success", token, error);

        setLoading(false);

        // if (error) {
        //   console.error(error.message);
        // } else {
        //   console.log("Token:", token);
        //   // Send token to your backend
        //   await fetch("/create-bank-account", {
        //     method: "POST",
        //     headers: { "Content-Type": "application/json" },
        //     body: JSON.stringify({ token: token.id }),
        //   });
        // }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div>
                <IbanElement options={{ supportedCountries: ["SEPA"], placeholderCountry: "DE" }} />
            </div>
            <button type="submit" disabled={!stripe || loading}>
                {loading ? "Processing..." : "Submit"}
            </button>
        </form>
    );
};


export default IbanForm
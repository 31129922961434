import React, { useRef, useState } from 'react';
import AppHeader from '../../TopBar/AppHeader'
import SuperSideBar from '../SiderNavBar/Sidebar';
import { UploadCsvFile } from '../../../utils/api';
import { toast } from "react-toastify";

const UploadCsv = () => {
    const [file, setFile] = useState(null);
    const [uploadMessage, setUploadMessage] = useState('');
    const fileInputRef = useRef(null);
    const [loading, setLoading] = useState(false);


    const handleDownloadSample = () => {
        // Define CSV headers and sample data.
        const headers = [
            'Departure place',
            'Arrival place',
            'Number of persons',
            'Amount',
            'Synonym Departure',
            'Synonym arrival',
            'Vehicle type'
        ];
        // Sample row data; adjust values as needed.
        const sampleData = ["s Gravenmoer", "Schiphol", "8", "230", "", "", "Car"];

        // Combine headers and sample data into CSV string.
        const csvArray = [headers, sampleData];
        const csvContent = csvArray.map(row => row.join(",")).join("\n");

        // Create a Blob from the CSV content.
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        // Generate a temporary URL for the blob.
        const url = URL.createObjectURL(blob);

        // Create a temporary link and trigger the download.
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'sample.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    const handleDivClick = () => {
        fileInputRef.current?.click();
    };

    // Handle file selection
    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    // Upload the file to the server
    const handleUpload = async () => {

        if (!file) {
            setUploadMessage('Please select a file first.');
            return;
        }

        const validTypes = ['text/csv', 'application/vnd.ms-excel'];
    if (!validTypes.includes(file.type) && !file.name.endsWith('.csv')) {
        setUploadMessage('Invalid file type. Please select a CSV file.');
        toast.error('Invalid file type. Please select a CSV file.', {
            position: "top-right",
            autoClose: 1000,
        });
        return;
    }


        setLoading(true);
        const formData = new FormData();
        formData.append('file', file);


        UploadCsvFile(formData)
            .then((res) => {
                if (res?.code === 200) {
                    toast.success(`${res.message}`, {
                        position: "top-right",
                        autoClose: 1000,
                    });
                    setFile(null);
                    if (fileInputRef.current) {
                        fileInputRef.current.value = '';
                    }
                    setLoading(false);
                } else {
                    toast.warning(`${res.message}`, {
                        position: "top-right",
                        autoClose: 1000,
                    });
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.log(error);
                toast.warning(`${error.message}`, {
                    position: "top-right",
                    autoClose: 1000,
                });
                setLoading(false);
            });
    };

    return (
        <div className="container-fluidd">
            <div className="col-md-12">
                <div>
                    <SuperSideBar />
                    <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                        <AppHeader />
                        <div className="body flex-grow-1 px-3">
                            <h1 className="heading-for-every-page">Upload CSV</h1>
                            <div className="active-trip-outer">

                                <div style={{ marginBottom: '20px', textAlign: 'center' }}>
                                    <button onClick={handleDownloadSample} className="view_details_btn">
                                        Download Sample CSV File
                                    </button>
                                </div>
                                {/* Hidden file input */}
                                <input
                                    type="file"
                                    accept=".csv"
                                    onChange={handleFileChange}
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                />
                                {/* Clickable div to trigger file input */}
                                <div
                                    onClick={handleDivClick}
                                    style={{
                                        border: '2px dashed #ccc',
                                        padding: '20px',
                                        cursor: 'pointer',
                                        textAlign: 'center'
                                    }}
                                >
                                    Click here to upload Price List
                                </div>
                                {/* Show selected file name */}
                                {file && <div style={{ marginTop: '10px' }}>Selected File: {file.name}</div>}
                                <div className='w-50 mx-auto text-center'>
                                    <button onClick={handleUpload} style={{ marginTop: '20px' }} className='view_details_btn w-75 mx-auto'>
                                        {loading ? (
                                            // <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            <div class="spinner-border text-white" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>

                                        ) : (
                                            "Upload File"
                                        )}

                                    </button>
                                </div>

                                {uploadMessage && <div style={{ marginTop: '10px', color: "red" }}>{uploadMessage}</div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UploadCsv
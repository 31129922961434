import React, { useState, useEffect } from "react";
import AppHeader from "../../TopBar/AppHeader";
import {
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import locationimg from "../../../assets/images/location.png";
import refreshImg from "../../../assets/images/refresh.png";
import crossImg from "../../../assets/images/cross-arrow.png";
import downarrowImg from "../../../assets/images/down-arrow.png";
import moment from "moment";
//import background from '../assets/images/heroimg.png';
import * as geolib from "geolib";
import { getTrip } from "../../../utils/api";
//import PulseLoader from "react-spinners/PulseLoader";
import { PulseLoader } from "react-spinners";
import SuperSideBar from "../SiderNavBar/Sidebar";
import EmptyData from "../../EmptyData";
import { MDBInputGroup, MDBInput, MDBIcon, MDBBtn } from 'mdb-react-ui-kit';
import AppLoader from "../../AppLoader";
import { Link } from "react-router-dom";

const SuperCompleteTrip = () => {
  const [completeTrip, setCompleteTrip] = useState([]);
  const [loader, setLoader] = useState(false);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageLimit, setPageLimit] = React.useState(3);
  const [maxPage, setMaxPage] = React.useState(3);
  const [minPage, setMinPage] = React.useState(0);
  const recordPage = 10;
  const lastIndex = currentPage * recordPage;
  const firstIndex = lastIndex - recordPage;
  const data = completeTrip?.slice(firstIndex, lastIndex);
  const nPage = Math.ceil(completeTrip?.length / recordPage);
  const number = [...Array(nPage + 1).keys()].slice(1);
  const [dateFilter, setDateFilter] = useState("all"); // State to store selected date filter

  const handleDateFilterChange = (event) => {
    setDateFilter(event.target.value);
  };
  const pageNumber = number.map((num, i) => {
    if (num < maxPage + 1 && num > minPage) {
      return (
        <>
          <li
            key={i}
            className={currentPage == num ? `active_btn ` : `unactive_btn`}
          >
            <button onClick={() => changePage(num)}>{num}</button>
          </li>
        </>
      );
    } else {
      return null;
    }
  });

  const handlePrePage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
      if ((currentPage - 1) % pageLimit == 0) {
        setMaxPage(maxPage - pageLimit);
        setMinPage(minPage - pageLimit);
      }
    }
  };

  const handleNextPage = () => {
    if (currentPage !== nPage) {
      setCurrentPage(currentPage + 1);
      if (currentPage + 1 > maxPage) {
        setMaxPage(maxPage + pageLimit);
        setMinPage(minPage + pageLimit);
      }
    }
  };

  const changePage = (id) => {
    setCurrentPage(id);
  };

  let pageIncreament = null;
  if (data.length > maxPage) {
    pageIncreament = <li onClick={handleNextPage}>&hellip;</li>;
  }
  const [search, setSearch] = useState("")


  const options = ['Cash', 'Hotel Account', 'Card', 'ON ACCOUNT'];
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const handleCheckboxChange = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((opt) => opt !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedOptions([]); // Clear all selections
    } else {
      setSelectedOptions([...options]); // Select all options
    }
    setSelectAll(!selectAll);
  };

  React.useEffect(() => {
    if (selectedOptions.length === options.length && !selectAll) {
      setSelectAll(true);
    } else if (selectedOptions.length !== options.length && selectAll) {
      setSelectAll(false);
    }
  }, [selectedOptions, options, selectAll]);


  useEffect(() => {
    setLoader(true);
    getTrip("Completed", search, dateFilter, selectedOptions).then((res) => {
      // console.log(res.result, "vehicle");
      if (res?.code === 200) {
        setCompleteTrip(res.result);
      }
      setLoader(false);
    });
  }, [search, dateFilter, selectedOptions]);

  return (
    <>
      <div className="container-fluidd">
        <div className="col-md-12">
          <div>
            <SuperSideBar />

            <div className="wrapper d-flex flex-column min-vh-100 bg-light">
              <AppHeader />
              <div className="body flex-grow-1 px-3">
                <h1 className="heading-for-every-page">Completed Trip </h1>
                <div className="company-complete-trips">
                  <div className="serach-left" id="complete-trip-search">
                    <MDBInputGroup>
                      <MDBInput placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)} />

                    </MDBInputGroup>
                  </div></div>
                <div className="active-trip-outer" id="completed-trips">
                  <div className="d-flex">


                  <div className="filter-right">
                      <select
                        value={dateFilter}
                        onChange={handleDateFilterChange}
                        style={{
                          backgroundColor: '#a0c0f7',
                          color: 'black', // You can change the color to match your design
                          border: '1px solid #ccc', // You may adjust the border color and width
                          borderRadius: '5px', // You can adjust the border radius as needed
                          padding: '5px', // You can adjust the padding as needed
                          fontWeight: "bold"
                        }}
                      >
                        <option value="all">All</option>
                        <option value="this_week">This Week</option>
                        <option value="this_month">This Month</option>
                        <option value="this_year">This Year</option>
                      </select>

                    </div>

                    <div className="multi-select-dropdown ms-2" style={{ position: 'relative', width: '200px' }}>


                      <button
                        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                        style={{
                          width: '100%',
                          padding: '5px',
                          textAlign: 'left',
                          background: '#a0c0f7',
                          border: '1px solid #ccc',
                          cursor: 'pointer',
                          borderRadius: '8px',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          fontSize: '14px',
                        }}
                      >
                        {/* Display the selected options count or default text */}
                        <span>
                          {selectedOptions.length > 0
                            ? `${selectedOptions.length} selected`
                            : 'Select Pay Type'}
                        </span>

                        {/* Conditional icon based on dropdown state */}
                        <img
                          width="24"
                          height="24"
                          src={
                            isDropdownOpen
                              ? 'https://img.icons8.com/material-outlined/24/collapse-arrow.png'
                              : 'https://img.icons8.com/material-outlined/24/expand-arrow--v1.png'
                          }
                          alt={isDropdownOpen ? 'Collapse Arrow' : 'Expand Arrow'}
                        />
                      </button>


                      {/* Dropdown Menu */}
                      {isDropdownOpen && (
                        <div
                          style={{
                            position: 'absolute',
                            top: '100%',
                            left: 0,
                            right: 0,
                            background: '#fff',
                            border: '1px solid #ccc',
                            maxHeight: '150px',
                            overflowY: 'auto',
                            zIndex: 1000,
                          }}
                        >
                          {/* Select All Option */}
                          <div style={{ padding: '5px', borderBottom: '1px solid #eee', display: 'flex' }}>
                            <input
                              type="checkbox"
                              checked={selectAll}
                              onChange={handleSelectAllChange}
                            />{' '}
                            <label className="ms-2">Select All</label>
                          </div>


                          {options.map((option) => (
                            <div key={option} style={{ padding: '5px', display: 'flex' }}>
                              <input
                                type="checkbox"
                                checked={selectedOptions.includes(option)}
                                onChange={() => handleCheckboxChange(option)}
                              />{' '}
                              <label className="ms-2">{option}</label>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>

                  



                  </div>
                  {/* <div className="trips-head d-flex justify-content-between">
            <div className="box-shd d-flex justify-content-between">
            <div className="left-trip-content">
          <h2> List of Complete Trips</h2>
          </div>
          <div className="right-trip-content">
            <img src={refreshImg}/>
            <img src={downarrowImg}/>
            <img src={crossImg}/>
            </div>
            </div>
          </div> */}
                  {loader ? (
                    <>
                      <AppLoader />
                    </>
                  ) : (
                    <>
                      {data?.length > 0 ? <CTable align="middle" className="mb-0" hover responsive>
                        <CTableHead>
                          <CTableRow>
                            {/* <CTableHeaderCell className="text-center">
                <CIcon icon={cilPeople} />
              </CTableHeaderCell> */}
                            <CTableHeaderCell className="text-center">
                              S. No.
                            </CTableHeaderCell>
                            <CTableHeaderCell className="text-center">
                              Customer
                            </CTableHeaderCell>
                            <CTableHeaderCell className="text-center">
                              Trip ID
                            </CTableHeaderCell>
                            <CTableHeaderCell className="text-center">
                              Driver Name
                            </CTableHeaderCell>
                            <CTableHeaderCell className="text-center">
                              Trip From
                            </CTableHeaderCell>
                            <CTableHeaderCell className="text-center">
                              Trip To
                            </CTableHeaderCell>
                            <CTableHeaderCell className="text-center">
                              Pay type
                            </CTableHeaderCell>
                            <CTableHeaderCell className="text-center">
                              Comment
                            </CTableHeaderCell>
                            <CTableHeaderCell className="text-center">
                              Start Time
                            </CTableHeaderCell>
                            <CTableHeaderCell className="text-center">
                              End Time
                            </CTableHeaderCell>
                            <CTableHeaderCell className="text-center">
                              Distance
                            </CTableHeaderCell>
                            <CTableHeaderCell className="text-center">View Trip</CTableHeaderCell>
                            {/* <CTableHeaderCell className="text-center">Fare</CTableHeaderCell> */}
                            <CTableHeaderCell className="text-center">
                              View Route
                            </CTableHeaderCell>

                          </CTableRow>
                        </CTableHead>
                        <CTableBody>
                          {data?.length > 0
                            ? data.map((item, index) => (
                              <CTableRow
                                className="text-center"
                                v-for="item in tableItems"
                                key={item._id}
                              >
                                <CTableDataCell>
                                  <div>{firstIndex + index + 1}</div>
                                </CTableDataCell>
                                <CTableDataCell>
                                  <div>{item?.company_name}</div>
                                </CTableDataCell>
                                <CTableDataCell>
                                  <div>{item.trip_id}</div>
                                </CTableDataCell>
                                <CTableDataCell>
                                  <div>{item.driver_name}</div>
                                </CTableDataCell>
                                <CTableDataCell>
                                  <div>{item.trip_from.address.slice(0, 20) + `${item.trip_from.address.length < 21 ? "" : "..."}`}</div>
                                </CTableDataCell>
                                <CTableDataCell>
                                  <div>{item.trip_to.address.slice(0, 20) + `${item.trip_to.address.length < 21 ? "" : "..."}`}</div>
                                </CTableDataCell>
                                <CTableDataCell>
                                  <div>
                                    {item?.pay_option}
                                  </div>
                                </CTableDataCell>
                                <CTableDataCell>
                                  <div>
                                    {item?.comment}
                                  </div>
                                </CTableDataCell>
                                <CTableDataCell>
                                  <div>
                                    {moment(item.pickup_date_time).format(
                                      "MMM Do YYYY, h:mm a"
                                    )}
                                  </div>
                                </CTableDataCell>

                                <CTableDataCell>
                                  <div>
                                    {moment(item.pickup_date_time).format(
                                      "MMM Do YYYY, h:mm:ss a"
                                    )}
                                  </div>
                                </CTableDataCell>
                                <CTableDataCell>
                                  <div>{`${(
                                    geolib.getDistance(
                                      {
                                        latitude: item.trip_from.log,
                                        longitude: item.trip_from.lat,
                                      },
                                      {
                                        latitude: item.trip_to.log,
                                        longitude: item.trip_to.lat,
                                      }
                                    ) * 0.00062137
                                  ).toFixed(2)} Miles`}</div>
                                </CTableDataCell>
                                {/* <CTableDataCell>
                  <div>{item.fare}</div>
                </CTableDataCell>               */}
                                <CTableDataCell>
                                  <div className="view_details_btn">
                                    <Link to={`/trips/view-trip-details/${item._id}`}>
                                      View Details
                                    </Link>
                                  </div>
                                </CTableDataCell>
                                <CTableDataCell className="text-center location-icons">
                                  <Link to={`/trips/view-trip-location/${item._id}`}>
                                    <img src={locationimg} alt="img" />
                                  </Link>
                                </CTableDataCell>
                              </CTableRow>
                            ))
                            : ""}
                        </CTableBody>
                      </CTable> : <EmptyData />}
                      {
                        data?.length > 0 ?
                          <div
                            className="pagination-outer"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <div
                              className="prev_btn"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <button onClick={() => handlePrePage()}>
                                Previous
                              </button>
                            </div>
                            <div className="previous-page">
                              <ul>
                                {pageNumber}
                                <button className="dots_btn">
                                  {pageIncreament}
                                </button>
                              </ul>
                            </div>
                            <div className="next_btn">
                              <button onClick={() => handleNextPage()}>Next</button>
                            </div>
                          </div>
                          : ""
                      }
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
    </>
  );
};

export default SuperCompleteTrip;

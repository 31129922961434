import React, { useEffect, useState, useContext } from "react";
import AppHeader from "../../TopBar/AppHeader";
import { useFormik } from "formik";
import deleteiconimg from "../../../assets/images/deleteicon.png";
import deletepopup from "../../../assets/images/deletepopup.png";
import * as Yup from "yup";
import clsx from "clsx";
import editicon from "../../../assets/images/editicon.png";

import {
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CButton,
  CCard,
  CCardBody,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CRow,
} from "@coreui/react";
//import refreshImg from '../../../assets/images/refresh.png';
//import crossImg from '../../../assets/images/cross-arrow.png';
//import downarrowImg from '../../../assets/images/down-arrow.png'
//import background from '../assets/images/heroimg.png';
import accepticonimg from "../../../assets/images/accept.png";
import rejecticonimg from "../../../assets/images/rejecticon.png";
//import editicon from '../../../assets/images/editicon.png'
import SuperSideBar from "../SiderNavBar/Sidebar";

import { Link } from "react-router-dom";
import {
  allocateDriver,
  getDriver,
  getTrip,
  getVehicle,
  getVehicleByType,
  tripsUpdate,
} from "../../../utils/api";
import { toast } from "react-toastify";
import moment from "moment";
import { PulseLoader } from "react-spinners";
import AppLoader from "../../AppLoader";
import EmptyData from "../../EmptyData";
import { MDBInputGroup, MDBInput, MDBIcon, MDBBtn } from 'mdb-react-ui-kit';
import { Navigate, useNavigate } from 'react-router-dom';
import { socketContext } from "../../../App";
const tableExample = [
  {
    Srnum: "1",
    tripid: "123",
    vehicletype: "SUV",
    tripfrom: "mohali",
    tripto: "chandigarh",
    time: "10:20AM",
  },
];
const SuperPendingTrip = () => {
  const { socket } = useContext(socketContext);
  const [pendinTrip, setPendingTrip] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loader, setLoader] = useState(false);
  const [driver, setDriver] = useState([]);
  const [vehicle, setVehicle] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedVehicleType, setSelectedVehicleType] = useState(null);
  const [delvisible, setDelvisible] = useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageLimit, setPageLimit] = React.useState(3);
  const [maxPage, setMaxPage] = React.useState(3);
  const [minPage, setMinPage] = React.useState(0);
  const [dateFilter, setDateFilter] = useState("all"); // State to store selected date filter

   const navigate = useNavigate();


  const handleDateFilterChange = (event) => {
    setDateFilter(event.target.value);
  };
  const recordPage = 10;
  const lastIndex = currentPage * recordPage;
  const firstIndex = lastIndex - recordPage;
  const data = pendinTrip.slice(firstIndex, lastIndex);
  const nPage = Math.ceil(pendinTrip?.length / recordPage);
  const number = [...Array(nPage + 1).keys()].slice(1);

  const pageNumber = number.map((num, i) => {
    if (num < maxPage + 1 && num > minPage) {
      return (
        <>
          <li
            key={i}
            className={currentPage == num ? `active_btn ` : `unactive_btn`}
          >
            <button onClick={() => changePage(num)}>{num}</button>
          </li>
        </>
      );
    } else {
      return null;
    }
  });

  const handlePrePage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
      if ((currentPage - 1) % pageLimit == 0) {
        setMaxPage(maxPage - pageLimit);
        setMinPage(minPage - pageLimit);
      }
    }
  };

  const handleNextPage = () => {
    if (currentPage !== nPage) {
      setCurrentPage(currentPage + 1);
      if (currentPage + 1 > maxPage) {
        setMaxPage(maxPage + pageLimit);
        setMinPage(minPage + pageLimit);
      }
    }
  };

  const changePage = (id) => {
    setCurrentPage(id);
  };
  let pageIncreament = null;
  if (data?.length > maxPage) {
    pageIncreament = <li onClick={handleNextPage}>&hellip;</li>;
  }
  const [search, setSearch] = useState("")

  const options = ['Cash', 'Hotel Account', 'Card', 'ON ACCOUNT'];
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const handleCheckboxChange = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((opt) => opt !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedOptions([]); // Clear all selections
    } else {
      setSelectedOptions([...options]); // Select all options
    }
    setSelectAll(!selectAll);
  };

  React.useEffect(() => {
    if (selectedOptions.length === options.length && !selectAll) {
      setSelectAll(true);
    } else if (selectedOptions.length !== options.length && selectAll) {
      setSelectAll(false);
    }
  }, [selectedOptions, options, selectAll]);




  async function onLoadComponent() {
    setLoader(true);


    getTrip("Pending", search, dateFilter, selectedOptions)
      .then((res) => {
        // console.log(res.result, "pending trip vehicle");
        if (res?.code === 200) {
          setPendingTrip(res.result);
          getDriver()
            .then((res) => {
              // console.log(res.result, "pending trip driver");
              if (res?.code === 200) {
                setDriver(res.result.filter(driver => driver.status));
              }
            })
            .catch((err) => {
              throw err;
            });
        }
      })
      .catch((err) => {
        setLoader(false);
      })
      .finally(() => {
        setLoader(false);
      });
  }
  useEffect(() => {
    onLoadComponent();
  }, [search, dateFilter, selectedOptions]);
  useEffect(() => {
    getVehicleByType(selectedVehicleType).then((res) => {
      // console.log(res?.result, "pending trip vehicle type");
      if (res?.code === 200) {
        setVehicle(res?.result);
      }
    });
  }, [selectedVehicleType]);

  const [selectDriver, setSelectDriver] = useState();
  const [selectVehicle, setSelectVehicle] = useState();
  const [errors, setErrors] = useState({
    driver_name: false,
    vehicle: false,
  });
  function handeleAllocate() {
    setLoader(true);

    const newErrors = { ...errors };
    let valid = true;
    if (!selectDriver || selectDriver?.length < 1) {
      newErrors.driver_name = true;
      valid = false;
    }
    if (!selectVehicle || selectVehicle?.length < 1) {
      newErrors.vehicle = true;
      valid = false;
    }
    setErrors(newErrors);
    const data = {
      driver_name: selectDriver,
      vehicle: selectVehicle,
      status: "Accepted",
    };
    if (!valid) {
      // setVisible(false);
      return;
    }
    console.log("data for allocating driver", data);
    console.log("errors for allocating driver", errors);
    console.log("id for allocating driver", selectedId);
    allocateDriver(data, selectedId).then((res) => {
      console.log(res.result, "allocated done");
      if (res?.data?.code === 200) {
        toast.success(`${res.data.message}`, {
          position: "top-right",
          autoClose: 1000,
        });
        const newTrips = pendinTrip.filter((item) => {
          return item._id != selectedId;
        });
        setPendingTrip(newTrips);
      } else {
        toast.warning(`${res.data.message}`, {
          position: "top-right",
          autoClose: 1000,
        });
      }
    });
    setLoader(false);
    setVisible(false);
  }
  function handleDeletItem() {
    const data = {
      trip_status: "Canceled",
    };
    tripsUpdate(selectedId, data).then((res) => {
      console.log(res.result, "cancele done");
      if (res?.code === 200) {
        toast.success(`${res.message}`, {
          position: "top-right",
          autoClose: 1000,
        });
        const newTrips = pendinTrip.filter((item) => {
          return item._id != selectedId;
        });
        setPendingTrip(newTrips);
        setDelvisible(false)
      } else {
        toast.warning(`${res.message}`, {
          position: "top-right",
          autoClose: 1000,
        });
      }
    });
  }

  const handleDelet = (id) => {
    setDelvisible(id)
  }

  useEffect(() => {
    if (!visible) {
      setSelectDriver(null);
      setSelectVehicle(null);
      setSelectedId(null);
      setErrors({
        driver_name: false,
        vehicle: false,
      });
    }
  }, [visible]);




  useEffect(() => {
    if (!socket) {
      console.error('Socket is not defined');
      return;
    }

    // Log to confirm the listener is set up

    // Setup the event listener
    socket.on('refreshTrip', () => {
      console.log('Socket event "tripAcceptedBYDriver" received');
      // alert('Trip accepted by driver');
      onLoadComponent();
      // if (trip) {
      //   console.log(trip, 'refresh trip');
      // } else {
      //   console.error('Received invalid trip data:', trip);
      // }
    });

    // Cleanup the listener when the component unmounts
    // return () => {
    //   // console.log('Removing the socket listener');
    //   // socket.off('tripAcceptedBYDriver');
    // };
  }, [socket])

  return (
    <>
      <div className="container-fluidd">
        <div className="col-md-12">
          <div>
            <SuperSideBar />

            <div className="wrapper d-flex flex-column min-vh-100 bg-light">
              <AppHeader />
              <div className="body flex-grow-1 px-3">
                <h1 className="heading-for-every-page">Pending Trips</h1>
                <div className="company-pending-trips">
                  <div className="serach-left" id="pending-trip-search">
                    <MDBInputGroup>
                      <MDBInput placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)} />

                    </MDBInputGroup>
                  </div></div>
                <div className="active-trip-outer" id="pending-trips">

                  <div className="d-flex">

                    <div className="filter-right">
                      <select
                        value={dateFilter}
                        onChange={handleDateFilterChange}
                        style={{
                          backgroundColor: '#a0c0f7',
                          color: 'black', // You can change the color to match your design
                          border: '1px solid #ccc', // You may adjust the border color and width
                          borderRadius: '5px', // You can adjust the border radius as needed
                          padding: '5px', // You can adjust the padding as needed
                          fontWeight: "bold"
                        }}
                      >
                        <option value="all">All</option>
                        <option value="this_week">This Week</option>
                        <option value="this_month">This Month</option>
                        <option value="this_year">This Year</option>
                      </select>

                    </div>

                    <div className="multi-select-dropdown ms-2" style={{ position: 'relative', width: '200px' }}>


                      <button
                        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                        style={{
                          width: '100%',
                          padding: '5px',
                          textAlign: 'left',
                          background: '#a0c0f7',
                          border: '1px solid #ccc',
                          cursor: 'pointer',
                          borderRadius: '8px',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          fontSize: '14px',
                        }}
                      >
                        {/* Display the selected options count or default text */}
                        <span>
                          {selectedOptions.length > 0
                            ? `${selectedOptions.length} selected`
                            : 'Select Pay Type'}
                        </span>

                        {/* Conditional icon based on dropdown state */}
                        <img
                          width="24"
                          height="24"
                          src={
                            isDropdownOpen
                              ? 'https://img.icons8.com/material-outlined/24/collapse-arrow.png'
                              : 'https://img.icons8.com/material-outlined/24/expand-arrow--v1.png'
                          }
                          alt={isDropdownOpen ? 'Collapse Arrow' : 'Expand Arrow'}
                        />
                      </button>


                      {/* Dropdown Menu */}
                      {isDropdownOpen && (
                        <div
                          style={{
                            position: 'absolute',
                            top: '100%',
                            left: 0,
                            right: 0,
                            background: '#fff',
                            border: '1px solid #ccc',
                            maxHeight: '150px',
                            overflowY: 'auto',
                            zIndex: 1000,
                          }}
                        >
                          {/* Select All Option */}
                          <div style={{ padding: '5px', borderBottom: '1px solid #eee', display: 'flex' }}>
                            <input
                              type="checkbox"
                              checked={selectAll}
                              onChange={handleSelectAllChange}
                            />{' '}
                            <label className="ms-2">Select All</label>
                          </div>


                          {options.map((option) => (
                            <div key={option} style={{ padding: '5px', display: 'flex' }}>
                              <input
                                type="checkbox"
                                checked={selectedOptions.includes(option)}
                                onChange={() => handleCheckboxChange(option)}
                              />{' '}
                              <label className="ms-2">{option}</label>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>

                  </div>


                  {/* <div className="trips-head d-flex justify-content-between">
 <div className="box-shd d-flex justify-content-between">
 <div className="left-trip-content">
 <h2>List of Pending Trips</h2>
 </div>
 <div className="right-trip-content">
 <img src={refreshImg} />
 <img src={downarrowImg} />
 <img src={crossImg} />
 <Link to="/taxi/trips/addnewbooking">
 <CButton className="add_company_btn">Add New Booking</CButton>
 </Link>
 </div>
 </div>
 </div> */}
                  {loader ? (
                    <AppLoader />
                  ) : (
                    <>

                      {data?.length > 0 ? <CTable align="middle" className="mb-0" hover responsive>
                        <CTableHead>
                          <CTableRow>
                            <CTableHeaderCell className="text-center">
                              S. No.
                            </CTableHeaderCell>
                            <CTableHeaderCell className="text-center">
                              Customer
                            </CTableHeaderCell>
                            <CTableHeaderCell className="text-center">
                              Trip ID
                            </CTableHeaderCell>
                            <CTableHeaderCell className="text-center">
                              Vehicle Type
                            </CTableHeaderCell>
                            <CTableHeaderCell className="text-center">
                              Trip From
                            </CTableHeaderCell>
                            <CTableHeaderCell className="text-center">
                              Trip To
                            </CTableHeaderCell>
                            <CTableHeaderCell className="text-center">
                              Pay type
                            </CTableHeaderCell>
                            <CTableHeaderCell className="text-center">
                              Comment
                            </CTableHeaderCell>
                            <CTableHeaderCell className="text-center">
                              Commission
                            </CTableHeaderCell>
                            <CTableHeaderCell className="text-center">
                              Time
                            </CTableHeaderCell>
                            <CTableHeaderCell className="text-center">
                              Action
                            </CTableHeaderCell>
                            <CTableHeaderCell className="text-center">View Ride</CTableHeaderCell>
                          </CTableRow>
                        </CTableHead>

                        <CTableBody>
                          {data?.length > 0
                            ? data?.map((item, index) => (
                              <CTableRow
                                className="text-center"
                                v-for="item in tableItems"
                                key={item._id}
                              >
                                <CTableDataCell>
                                  <div>{firstIndex + index + 1}</div>
                                </CTableDataCell>
                                <CTableDataCell>
                                  <div>{item.hotel_name}</div>
                                </CTableDataCell>
                                <CTableDataCell>
                                  <div>{item.trip_id}</div>
                                </CTableDataCell>
                                <CTableDataCell>
                                  <div>{item.vehicle_type}</div>
                                </CTableDataCell>

                                <CTableDataCell>
                                  <div>{item?.trip_from?.address.slice(0, 20) + `${item?.trip_from?.address?.length < 21 ? "" : "..."}`}</div>
                                </CTableDataCell>
                                <CTableDataCell>
                                  <div>{item?.trip_to?.address.slice(0, 20) + `${item?.trip_to?.address?.length < 21 ? "" : "..."}`}</div>
                                </CTableDataCell>
                                <CTableDataCell>
                                  <div>
                                    {item?.pay_option}
                                  </div>
                                </CTableDataCell>
                                <CTableDataCell>
                                  <div>
                                    {item?.comment}
                                  </div>
                                </CTableDataCell>
                                <CTableDataCell>
                                  <div>
                                    {item?.commission?.commission_value}{item?.commission?.commission_type != "Fixed" ? "%" : ""}
                                  </div>
                                </CTableDataCell>
                                <CTableDataCell>
                                  <div>
                                    {moment(item.pickup_date_time).format(
                                      "MMMM Do YYYY, h:mm a"
                                    )}
                                  </div>
                                </CTableDataCell>
                                <CTableDataCell className="pending-trips-icons">
                                  <div style={{
                                    display: "flex",
                                    justifyContent: "space-evenly",
                                    alignItems: "center",
                                  }} className="pending-icons-outer">


                                    <div>
                                      {/* <Link to={`/taxi/allocate-driver/${item._id}`}> */}
                                        <CButton id="allocate_driver"
                                          className="allocate_accept_driver"
                                          onClick={() => {
                                            if (item.commission.commission_value === 0 ) {
                                              toast.warning("Please add the necessary details to the trip. Once completed, you will be able to allocate the trip.", {
                                                position: "top-right",
                                                autoClose: 3000,
                                              });
                                            }
                                            else {
                                              navigate(`/taxi/allocate-driver/${item._id}`)
                                            }
                                          }}
                                        >
                                          <img src={accepticonimg} alt="images" />
                                        </CButton>
                                      {/* </Link> */}
                                    </div>




                                    <div
                                      onClick={() => { setSelectedId(item._id); setDelvisible(true) }}
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      className="reject_icon"
                                    >
                                      <img src={deleteiconimg} alt="images" />
                                    </div>
                                    <div>
                                      <Link
                                        to={`/taxi/trips/editpendingtrips/${item._id}`}
                                      >
                                        <CButton className="allocate_accept_driver">
                                          <img src={editicon} alt="img" />
                                        </CButton>
                                      </Link>
                                    </div>
                                  </div>
                                </CTableDataCell>
                                <CTableDataCell>
                                  <div className="view_details_btn">
                                    <Link to={`/trips/view-trip-details/${item._id}`}>
                                      View Details
                                    </Link>
                                  </div>
                                </CTableDataCell>
                              </CTableRow>
                            ))
                            : ""}
                        </CTableBody>
                      </CTable> : <EmptyData />}
                    </>
                  )}

                  {
                    data?.length > 0 ?
                      <div
                        className="pagination-outer"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div
                          className="prev_btn"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <button onClick={() => handlePrePage()}>
                            Previous
                          </button>
                        </div>
                        <div className="previous-page">
                          <ul>
                            {pageNumber}
                            <button className="dots_btn">
                              {pageIncreament}
                            </button>
                          </ul>
                        </div>
                        <div className="next_btn">
                          <button onClick={() => handleNextPage()}>Next</button>
                        </div>
                      </div>
                      : ""
                  }
                  {/* allocatedriver */}

                  <CModal
                    alignment="center"
                    visible={visible}
                    onClose={() => setVisible(false)}
                  >
                    <CModalHeader>
                      <CModalTitle>Allocate Driver</CModalTitle>
                    </CModalHeader>
                    <CModalBody>
                      <CRow>
                        <CCol xs={12}>
                          <CCard className="mb-4">
                            <CCardBody>
                              <CForm className="row g-3">
                                <CCol md={6}>
                                  <CFormLabel htmlFor="inputvehicletype">
                                    Vehicle
                                  </CFormLabel>
                                  <CFormSelect
                                    id="inputallocatevehicle"
                                    name="Vehicle"
                                    autoComplete="off"
                                    onChange={(e) => {
                                      setSelectVehicle(e.target.value);
                                      if (
                                        !e?.target?.value ||
                                        e.target.value?.length < 1
                                      ) {
                                        setErrors({ ...errors, vehicle: true });
                                      } else {
                                        setErrors({
                                          ...errors,
                                          vehicle: false,
                                        });
                                      }
                                    }}
                                  >
                                    <option default>Select Vehicle</option>
                                    {vehicle.map((item) => {
                                      return (
                                        <option value={item._id}>
                                          {item.vehicle_model}
                                        </option>
                                      );
                                    })}
                                  </CFormSelect>
                                  {errors.vehicle && (
                                    <span
                                      style={{ color: "red" }}
                                      className="text-danger"
                                    >
                                      Select Vehicle required
                                    </span>
                                  )}
                                </CCol>
                                <CCol md={6}>
                                  <CFormLabel htmlFor="inputdtriver">
                                    Driver
                                  </CFormLabel>
                                  <CFormSelect
                                    id="inputallocatedriver"
                                    name="Driver"
                                    autoComplete="off"
                                    onChange={(e) => {
                                      setSelectDriver(e.target.value);
                                      if (
                                        !e?.target?.value ||
                                        e.target.value?.length < 1
                                      ) {
                                        setErrors({
                                          ...errors,
                                          driver_name: true,
                                        });
                                      } else {
                                        setErrors({
                                          ...errors,
                                          driver_name: false,
                                        });
                                      }
                                    }}
                                  >
                                    <option default>Select Driver</option>
                                    {driver.map((item) => {
                                      return (
                                        <option
                                          value={item._id}
                                        >{`${item.first_name} ${item.last_name}`}</option>
                                      );
                                    })}
                                  </CFormSelect>
                                  {errors.driver_name && (
                                    <span
                                      style={{ color: "red" }}
                                      className="text-danger"
                                    >
                                      Select driver
                                    </span>
                                  )}
                                </CCol>

                                <CCol xs={12}>
                                  <div
                                    className="d-flex justify-content-center"
                                    style={{ marginTop: "40px" }}
                                  >
                                    <CButton
                                      onClick={handeleAllocate}
                                      className="submit-btn"
                                    >
                                      Submit
                                    </CButton>
                                    <CButton
                                      onClick={() => {
                                        setVisible(false);
                                      }}
                                      className="cancel-btn"
                                    >
                                      Cancel
                                    </CButton>
                                  </div>
                                </CCol>
                              </CForm>
                            </CCardBody>
                          </CCard>
                        </CCol>
                      </CRow>
                    </CModalBody>

                  </CModal>

                  <CModal
                    alignment="center"
                    visible={delvisible}
                    onClose={() => setDelvisible(false)}
                  >
                    <CModalBody>
                      <CRow>
                        <CCol xs={12}>
                          <CCard className="mb-4 delete_vehicle_popup">
                            <CCardBody>
                              <img src={deletepopup} alt="danger" />
                              <h2>Are you Sure</h2>
                              <p>You want to delete this trip ?</p>
                            </CCardBody>
                            <div className="delete_vehicle_popup_outer">
                              <CButton
                                className="delete_popup"
                                onClick={() => handleDeletItem(delvisible)}
                              >
                                Delete
                              </CButton>
                              <CButton
                                className="cancel_popup"
                                onClick={() => setDelvisible(false)}
                              >
                                Cancel
                              </CButton>
                            </div>
                          </CCard>
                        </CCol>
                      </CRow>
                    </CModalBody>
                  </CModal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
    </>
  );
};

export default SuperPendingTrip;
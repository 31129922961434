import { useEffect } from "react";

const GoogleTranslate = () => {
    
    useEffect(() => {
        const addScript = document.createElement("script");
        addScript.type = "text/javascript";
        addScript.src = "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
        document.body.appendChild(addScript);
    
        window.googleTranslateElementInit = () => {
          new window.google.translate.TranslateElement(
            { 
             includedLanguages: "en,nl" },
            "google_translate_element"
          );
        };
      }, []);

  return (

      <div id="google_translate_element"></div>
     
  );
};

export default GoogleTranslate;

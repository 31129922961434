import React, { createContext, useEffect, useState } from 'react';
import './assets/css/App.scss';
import Routerpage from '../src/routes/router';
import './scss/style.scss'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import userContext from './utils/context';
import { getProfile, checkUSerPlan } from './utils/api';
import { Navigate, useNavigate } from 'react-router-dom';
import "bootstrap-icons/font/bootstrap-icons.css";
import AppLoader from './components/AppLoader';
import PrivateRoute from './routes/PrivateRoute';
import socket from './utils/socket';
export const socketContext = createContext();
import '@fortawesome/fontawesome-free/css/all.min.css';
import { CModal, CModalBody, CModalTitle, CModalHeader, CButton, CModalFooter } from '@coreui/react';
import loginbg from './assets/images/login-bg.png';

function App() {

  const [appLoaded, setAppLoaded] = useState(false)
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [refreshUser, setRefreshUser] = useState(false);
  const [tripStatus, setTripStatus] = useState(false);
  const [planCheck, setPlancheck] = useState(false);
  const [triptype, setTripType] = useState("")
  const [tripMessage, setTripMessage] = useState({})
  const [driver, setDriver] = useState({});
  const location = window.location.pathname
  // console.log("location", location)
  function refreshUserData() {
    setRefreshUser(!refreshUser);
  };
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  function onLoadApp() {
    setLoading(true);
    setAppLoaded(false);
    // console.log('token: from local storage' + token);
    if (!token) {
      setLoading(false)
      if (!(location == "/forgot-password" || location == "/newvisits" || location == "/driverRoute" || location == "/driver-verification" || location.includes("booking-form") || location == "/new-password" || location == "/enter-otp" || location == "/unauthorized" || location?.includes("/booking-staff-form") || location == "/register" || location == "/login" || location.includes("/subscription-payment-success") || location.includes("/subscription-payment-fail") || location.includes("widget") || location == "/privacy")) {
        return navigate("/")
      }
      return

    }
    // console.log("path founder running on react app =====>>>>>,", user)
    getProfile(token).then(res => {
      // console.log(res, 'profile data')
      if (res?.code === 200) {
        setUser(res.result)
        if (res.result.role === 'COMPANY') {
          socket.emit("addWebUser", { token })
        } else if (res.result.role === 'DRIVER') {
          socket.emit("addWebNewDriver", { token })
        } else if (user?.role === "SUPER_ADMIN" || user?.role === "HOTEL" || user?.role === "ADMIN") {
          socket.emit("addWebUser", { token: localStorage.getItem('token') })
        }
        checkPlanDetails(res.result);
      } else {
        console.log("remove token from wrong app")
        localStorage.clear();
        navigate("/")
      }
    }).catch((err) => {
      console.log("remove token from catch app")
      localStorage.clear();
      navigate("/")
    }).finally(() => {
      setLoading(false);
      setAppLoaded(true);
    })
  }

  const checkProfile = () => {
    getProfile(token).then(res => {
      if (res?.code === 200) {
        setUser(res.result)
      checkPlanDetails(res.result);

      } 
    }).catch((err) => {
     console.error(err);
    }).finally(() => {
     
    })
  }

  useEffect(() => {
    onLoadApp()

  }, [token, refreshUser])

  useEffect(() => {

   
    const pathsToCheck = [
      "/taxi/trips/requesttrips",
      "/taxi/trips/pendingtrips",
      "/taxi/add-company",
      "/taxi/fare/addfare",
      "/taxi/account-share-list/pendingaccess",
      "/taxi/account-share-list/partneraccess",
      "/trips-payment",
      "/account-access",
      "/all-account-trips",

    ];

    if (pathsToCheck.includes(location)) {
      checkProfile();
    }

    if (localStorage.getItem('partnerAccount') == 'true' && location === '/pricing') {
      toast.error("You don't have permission to access  plan");
      navigate('/')
    }

    if (location === '/pricing' && user?.is_special_plan_active) {
      toast.error("You don't have permission to access  plan , You have a special plan");
      navigate('/')
    }


  }, [location]);



  const checkPlanDetails = (data) => {
    // alert('Please');

    checkUSerPlan().then(res => {
      if (res?.code === 200) {
      }
      else if (res?.code === 401) {
        console.log("rest=============" , data.role , data.is_special_plan_active)
        console.log("rest=============" , data)
        if ((data.role === "COMPANY" || data.role === "DRIVER" && data.isVerified) && data.is_special_plan_active === false ) {
          setPlancheck(true);
        } else {
          setPlancheck(false);
        }
      }
    }).catch((err) => {
      console.log("User not have plan", err);
    }).finally(() => {

    });
  }




  useEffect(() => {
    // console.log("socket code is start", user)
    socket.connect();
    socket.on("connection", () => {
      console.log("Connected socket")
    })


    if (user) {

      console.log("User connected new", user)
      // alert(user?.role)
      if (user?.role === "COMPANY") {
        socket.emit("addWebUser", { token: localStorage.getItem('token') })
      } else if (user?.role === "DRIVER") {
        socket.emit("addWebNewDriver", { token: localStorage.getItem('token') })
      } else if (user?.role === "SUPER_ADMIN" || user?.role === "HOTEL" || user?.role === "ADMIN") {
        socket.emit("addWebUser", { token: localStorage.getItem('token') })
      }

    }


    socket.on("userConnection", (data) => {
      // console.log("userConnection socket", data)
    })


    socket.on("tripCancelledBYDriver", ({ trip, driver , cancellation_reason , message }) => {
      if (trip) {
        console.log(trip, "Test Trip 1" , driver , cancellation_reason , message );
        setTripStatus(true);
        setTripType("cancel");
        setTripMessage(trip);
        setDriver(driver);
      } else {
        console.error("Received invalid trip data:", trip);
      }
    })


    socket.on("tripAcceptedBYDriver", ({ trip }) => {
      if (trip) {
        console.log(trip, "Test Trip 2");
        setTripStatus(true);
        setTripType("accept");
        setTripMessage(trip);
      } else {
        console.error("Received invalid trip data:", trip);
      }

    })

    socket.on("tripNotAcceptedBYDriver", ({ trip }) => {
      if (trip) {
        console.log(trip, "Test Trip 3");
        setTripStatus(true);
        setTripType("notAccept");
        setTripMessage(trip);
      } else {
        console.error("Received invalid trip data:", trip);
      }

    })

    socket.on('accountTerminated' , ({userDetail}) => {
      setTripStatus(true);
      setTripType("Block");
      // setTripMessage(trip);
      localStorage.clear();
      navigate("/");
    })


    socket.on("noShow", (trip) => {
      console.log("No show", trip);
      if (trip) {
        console.log(trip, "Test Trip 3");
        setTripStatus(true);
        setTripType("noShow");
        setTripMessage(trip?.trip_data);
      } else {
        console.error("Received invalid trip data:", trip);
      }

    })

    socket.on("retrivedTrip", ({ trip }) => {
      if (trip) {
        console.log("trip reterieve", trip);
        setTripStatus(true);
        setTripType("retrieveTrip");
        setTripMessage(trip);
      } else {
        console.error("Received invalid Reterive Trip ", trip);
      }
    })


    socket.on("connect", () => {
      console.log("Connected socket successfully");

    })
    return () => {
      socket.disconnect();
    }


  }, [user, token])

  const navigateToPlans = (data) => {

    if (localStorage.getItem('partnerAccount') == 'true') {
      toast.error("You don't have permission to access  plan");
      setPlancheck(data);
      navigate('/')
    } else {
      navigate('/pricing')
      setPlancheck(data);
    }
  }

  return (
    <socketContext.Provider value={{ socket }}>
      <userContext.Provider value={{ user, setUser, appLoaded, refreshUserData }}>
        {loading ? <AppLoader /> : <div className="App">
          <PrivateRoute />
          <ToastContainer />
          <CModal alignment="center" visible={tripStatus} onClose={() => setTripStatus(!tripStatus)} backdrop="static">
           {
            triptype != 'Block' ? <CModalHeader>
            <CModalTitle id="LiveDemoExampleLabel">Trip Status</CModalTitle>
          </CModalHeader> : <CModalHeader>
            <CModalTitle id="LiveDemoExampleLabel">Block Message</CModalTitle>
          </CModalHeader>
           } 
            <CModalBody>
              <div className="container-login" style={{
                backgroundImage: `url(${loginbg})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "100%",
              }}>
                <div className='text-center'>
                  {
                    triptype === 'cancel' ? <p> The trip with trip ID {tripMessage?.trip_id} has been canceled by {driver?.first_name} {driver?.last_name} due to {tripMessage?.cancellation_reason} </p>
                      : triptype === 'accept' ? <p>The trip with trip ID {tripMessage?.trip_id} is  Accepted by Driver</p>
                        : triptype === 'notAccept' ? <p>The trip with trip ID {tripMessage?.trip_id} is Not Accepted by Driver</p>
                          : triptype === 'noShow' ? <p> The driver was unable to locate the customer at the designated location for trip id {tripMessage?.trip_id}.</p>
                            : triptype === "retrieveTrip" ? <p>The trip has been Retrieved by Company</p> 
                            : triptype === "Block" ? <p>You have been blocked. If you believe this is an error, please contact the support team for further assistance.</p> : ''
                  }
                </div>
              </div>
            </CModalBody>

            {/* <CModalFooter>
              <CButton color="secondary" onClick={() => setTripStatus(false)}>
                Close
              </CButton>
            </CModalFooter> */}
          </CModal>
          <CModal alignment="center" visible={planCheck} backdrop="static">
            {/* <CModalHeader>
              <CModalTitle id="LiveDemoExampleLabel">Purchase a Plan</CModalTitle>
            </CModalHeader> */}
            <CModalBody>
              <div className='text-center'>
                <h3 style={{ color: '#0682ca' }}>Purchase a Plan</h3>
              </div>
              <div className='text-center'>
                To access and utilize all the features of this website, please purchase a suitable plan.
              </div>
            </CModalBody>

            <CModalFooter>
              <CButton className='buy_simple w-75 mx-auto' onClick={() => navigateToPlans(false)}>
                Buy Plan
              </CButton>
            </CModalFooter>
          </CModal>
        </div>}
      </userContext.Provider>
    </socketContext.Provider>
  );
}

export default App;
import React, { useEffect, useState, useContext } from "react";
import AppHeader from "../../TopBar/AppHeader";

import {
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CButton,
} from '@coreui/react'
import locationimg from '../../../assets/images/location.png';
import { getRecentTrip, getTrip  , adminUpdatePayment} from "../../../utils/api";
import moment from "moment";
import SuperAdminSideBar from "../Sidebar/SideBar";
import EmptyData from "../../EmptyData";
import AppLoader from "../../AppLoader";
import Dropdown from 'react-bootstrap/Dropdown';
import filterImg from '../../../assets/images/filter-icon.png'
import { tripEnum } from "../../../utils/saticData";
import { MDBInputGroup, MDBInput, MDBIcon, MDBBtn } from 'mdb-react-ui-kit';
import { useParams } from "react-router";
import { Link, useSearchParams } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import { socketContext } from "../../../App";
import Switch from "react-switch";
import { toast } from "react-toastify";


const SuperRecentTrips = () => {
  const { socket } = useContext(socketContext);
  const [dateFilter, setDateFilter] = useState("all");
  let [query, setQuery] = useSearchParams();
  const status = query.get("filter");
  const [filterData, setFilterData] = useState([]);
  const [allFilterData, setAllFilterData] = useState([]);
  // const [selectedValue, setSelectedValue] = useState("All"); // Initial selected valu

  const handleSelect = (eventKey) => {
    setSelectedType(eventKey); // Update the selected value when an item is selected
    setDateFilter("all")
  };
  const [selectedType, setSelectedType] = useState("All");
  const [pendinTrip, setPendingTrip] = useState([])
  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);

  const recordPage = 10;
  const lastIndex = currentPage * recordPage;
  const firstIndex = lastIndex - recordPage;
  const data = allFilterData?.slice(firstIndex, lastIndex);
  const nPage = Math.ceil(allFilterData?.length / recordPage);
  const number = [...Array(nPage + 1).keys()].slice(1);

  const handleDateFilterChange = (event) => {
    setDateFilter(event.target.value);
  };


  const [search, setSearch] = useState("")
  const [allPagination, setAllPagination] = useState();
  const [pageNumbers, setPageNumbers] = useState(1);
  const [debouncedSearch, setDebouncedSearch] = useState("");


  const options = ['Cash', 'Hotel Account', 'Card', 'ON ACCOUNT'];
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const handleCheckboxChange = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((opt) => opt !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedOptions([]); // Clear all selections
    } else {
      setSelectedOptions([...options]); // Select all options
    }
    setSelectAll(!selectAll);
  };

  React.useEffect(() => {
    if (selectedOptions.length === options.length && !selectAll) {
      setSelectAll(true);
    } else if (selectedOptions.length !== options.length && selectAll) {
      setSelectAll(false);
    }
  }, [selectedOptions, options, selectAll]);



  const handlePageClick = (selectedPage) => {
    console.log("se", selectedPage)
    setPageNumbers(selectedPage.selected + 1)
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setPageNumbers(1);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setDebouncedSearch(search);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);


  // useEffect(() => {
  //   if (!selectedType || selectedType === "All") setFilterData(pendinTrip);
  //   else {
  //     console.log("selectedType in else: ", selectedType)
  //     setFilterData(pendinTrip.filter(i => i.trip_status == selectedType))
  //   }
  // }, [selectedType])




  useEffect(() => {
    if (!socket) {
      console.error('Socket is not defined');
      return;
    }


    socket.on('refreshTrip', () => {
      console.log('Socket event "tripAcceptedBYDriver" received');
      //   alert('Trip accepted by driver');

      getRecent();
    });


  }, [socket])

  useEffect(() => {
    getRecent();
  }, [debouncedSearch, pageNumbers, selectedOptions, selectedType])

  const getRecent = () => {
    setLoader(true)
    setDateFilter("all")

    console.log("selectedType", selectedType);

    let datas = {
      comment: search,
      page: pageNumbers,
      limit: 10,
      pay_option: JSON.stringify(selectedOptions),
      trip_status: selectedType
    }

    getRecentTrip(true, datas).then(res => {
      if (res?.code == 200 && res?.result) {
        console.log(res.result, "chekc msgs received")
        setPendingTrip(res?.result)
        setAllFilterData(res.result)
        setAllPagination(res?.totalCount)
        // if (!selectedType || selectedType === "All") setFilterData(res?.result);
        // else {
        //   console.log("selectedType in else: ", selectedType)
        //   setFilterData(res?.result.filter(i => i.trip_status == selectedType))
        // }
      }
    }).finally(() => { setLoader(false) })
  }


  const filterByDate = (startDate, endDate) => {
    const filteredData = allFilterData.filter(item => {
      const createdAt = moment(item.createdAt);
      return createdAt.isBetween(startDate, endDate, 'days', '[]');
    });
    return filteredData;
  };

  const handleFilterThisWeek = () => {
    const startDate = moment().startOf('week');
    const endDate = moment().endOf('week');
    const filteredData = filterByDate(startDate, endDate);
    setFilterData(filteredData);
  };

  const handleFilterThisMonth = () => {
    const startDate = moment().startOf('month');
    const endDate = moment().endOf('month');
    const filteredData = filterByDate(startDate, endDate);
    setFilterData(filteredData);
  };

  const handleFilterThisYear = () => {
    const startDate = moment().startOf('year');
    const endDate = moment().endOf('year');
    const filteredData = filterByDate(startDate, endDate);
    setFilterData(filteredData);
  };
  const filterDataByDate = () => {
    switch (dateFilter) {
      case "this_week":
        return handleFilterThisWeek();
      case "this_month":
        return handleFilterThisMonth();
      case "this_year":
        return handleFilterThisYear();
      default:
        return setFilterData(allFilterData)
    }
  };
  useEffect(() => {
    filterDataByDate()
  }, [dateFilter])


  const handleStatusChange = (dat) => {
    adminUpdatePayment(dat._id)
          .then((res) => {
            console.log("status changed", res);
            if (res.code === 200) {
              const newCompanyData = data.map((i) => {
                console.log(i.is_paid , dat.is_paid ,"items")
                if (i._id == dat._id) {
                  i.is_paid = !dat.is_paid;
                  return i;
                }
                return i;
              });
              setAllFilterData(newCompanyData);
              // getCompanyDetail();
              toast.success("Trip is paid Successfully", {
                position: "top-right",
                autoClose: 1000,
              });
            }else{
              toast.error(`${res.message}`, {
                position: "top-right",
                autoClose: 1000,
              });
            }
          
          })
          .catch((error) => {
            console.log(error);
            // toast.warning(`${res.message}`, {
            //   position: "top-right",
            //   autoClose: 1000,
            // });
          });

  }

  return (
    <>



      <div className="container-fluidd">
        <div className="col-md-12">
          <div>
            <SuperAdminSideBar />
            <div className="wrapper d-flex flex-column min-vh-100 bg-light">
              <AppHeader />
              <div className="body flex-grow-1 px-3">
                <h1 className="heading-for-every-page">All Trips</h1>

                <div className="filter-outer">
                  <div className="serach-left" id="recent-trip-search">
                    <MDBInputGroup>
                      <MDBInput placeholder="Search" value={search} onChange={handleSearchChange} />

                    </MDBInputGroup></div>

                  <div className="multi-select-dropdown" style={{ position: 'relative', width: '200px' }}>


                    <button
                      onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                      style={{
                        width: '100%',
                        padding: '8px',
                        textAlign: 'left',
                        background: '#f8f9fa',
                        border: '1px solid #ccc',
                        cursor: 'pointer',
                        borderRadius: '8px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        fontSize: '12px',
                      }}
                    >
                      {/* Display the selected options count or default text */}
                      <span>
                        {selectedOptions.length > 0
                          ? `${selectedOptions.length} selected`
                          : 'Select Pay Type'}
                      </span>

                      {/* Conditional icon based on dropdown state */}
                      <img
                        width="24"
                        height="24"
                        src={
                          isDropdownOpen
                            ? 'https://img.icons8.com/material-outlined/24/collapse-arrow.png'
                            : 'https://img.icons8.com/material-outlined/24/expand-arrow--v1.png'
                        }
                        alt={isDropdownOpen ? 'Collapse Arrow' : 'Expand Arrow'}
                      />
                    </button>


                    {/* Dropdown Menu */}
                    {isDropdownOpen && (
                      <div
                        style={{
                          position: 'absolute',
                          top: '100%',
                          left: 0,
                          right: 0,
                          background: '#fff',
                          border: '1px solid #ccc',
                          maxHeight: '150px',
                          overflowY: 'auto',
                          zIndex: 1000,
                        }}
                      >
                        {/* Select All Option */}
                        <div style={{ padding: '5px', borderBottom: '1px solid #eee', display: 'flex' }}>
                          <input
                            type="checkbox"
                            checked={selectAll}
                            onChange={handleSelectAllChange}
                          />{' '}
                          <label className="ms-2">Select All</label>
                        </div>


                        {options.map((option) => (
                          <div key={option} style={{ padding: '5px', display: 'flex' }}>
                            <input
                              type="checkbox"
                              checked={selectedOptions.includes(option)}
                              onChange={() => handleCheckboxChange(option)}
                            />{' '}
                            <label className="ms-2">{option}</label>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="filter-right">

                    <Dropdown onSelect={handleSelect}>
                      <Dropdown.Toggle id="dropdown-basic">
                        <img src={filterImg} />
                        {selectedType}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {tripEnum.map((item, i) => {
                          return <Dropdown.Item key={i} eventKey={item}
                            onClick={() => {
                              setSelectedType(item)
                            }}
                          >{item}</Dropdown.Item>
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                {loader ? <AppLoader /> :

                  <div className="active-trip-outer">
                    <div className="trips-head d-flex justify-content-between">

                    </div>
                    <div className="filter-right">
                      <select
                        value={dateFilter}
                        onChange={handleDateFilterChange}
                        style={{
                          backgroundColor: '#a0c0f7',
                          color: 'black', // You can change the color to match your design
                          border: '1px solid #ccc', // You may adjust the border color and width
                          borderRadius: '5px', // You can adjust the border radius as needed
                          padding: '5px', // You can adjust the padding as needed
                          fontWeight: "bold"
                        }}
                      >
                        <option value="all">All</option>
                        <option value="this_week">This Week</option>
                        <option value="this_month">This Month</option>
                        <option value="this_year">This Year</option>
                      </select>

                    </div>

                    {data?.length > 0 ? <CTable align="middle" className="mb-0 table-container" hover responsive>

                      <CTableHead>

                        <CTableRow>

                          <CTableHeaderCell className="text-center">S. No.</CTableHeaderCell>
                          <CTableHeaderCell className="text-center">Trip ID</CTableHeaderCell>
                          <CTableHeaderCell className="text-center">Company Name</CTableHeaderCell>
                          <CTableHeaderCell className="text-center">Trip From</CTableHeaderCell>
                          <CTableHeaderCell className="text-center">Trip To</CTableHeaderCell>
                          <CTableHeaderCell className="text-center">
                            Comment
                          </CTableHeaderCell>
                          <CTableHeaderCell className="text-center">
                            Pay type
                          </CTableHeaderCell>
                          <CTableHeaderCell className="text-center">Date</CTableHeaderCell>
                          <CTableHeaderCell className="text-center">Time</CTableHeaderCell>
                          <CTableHeaderCell className="text-center">Paid Status</CTableHeaderCell>
                          <CTableHeaderCell className="text-center">Payment Collected</CTableHeaderCell>
                          <CTableHeaderCell className="text-center">Status</CTableHeaderCell>
                          <CTableHeaderCell className="text-center">Invoice</CTableHeaderCell>
                          <CTableHeaderCell className="text-center">View Ride</CTableHeaderCell>

                        </CTableRow>
                      </CTableHead>
                      <CTableBody>
                        {data?.map((item, index) => {
                          const status = item.trip_status;
                          let background = "#067A88"
                          if (status === 'Active') background = "linear-gradient(90deg, #FF6A00 0%, #FFA625 100%) "
                          else if (status === 'Accepted') background = 'linear-gradient(90deg, #FF6A00 0%, #FFA625 100%)'
                          else if (status === 'Booked') background = 'linear-gradient(90deg, #FF5370 0%, #FF869A 100%)'
                          else if (status === 'Completed') background = "linear-gradient(90deg, #05D41F 0%, rgba(38, 228, 15, 0.9) 100%)"
                          else if (status === 'Canceled') background = 'red'
                          else if (status === 'NoShow') background = '#d69700'

                          return (
                            <CTableRow className="text-center" v-for="item in tableItems" key={index}>
                              <CTableDataCell >
                                {/* <div>{firstIndex + index + 1}</div> */}
                                <div>{(pageNumbers - 1) * 10 + index + 1}</div>
                              </CTableDataCell>
                              <CTableDataCell>
                                <div>{item.trip_id}</div>
                              </CTableDataCell>
                              <CTableDataCell>
                                <div>{item.company_name}</div>
                              </CTableDataCell>
                              <CTableDataCell>
                                <div>{item.trip_from?.address?.length < 20 ? item.trip_from?.address : item.trip_from?.address?.slice(0, 18) + "..."}</div>
                              </CTableDataCell>
                              <CTableDataCell>
                                <div>{item.trip_to?.address?.length < 20 ? item.trip_to?.address : item.trip_to?.address?.slice(0, 18) + "..."}</div>
                              </CTableDataCell>
                              <CTableDataCell>
                                <div>
                                  {item?.comment}
                                </div>
                              </CTableDataCell>
                              <CTableDataCell>
                                <div>
                                  {item?.pay_option}
                                </div>
                              </CTableDataCell>
                              <CTableDataCell>
                                <div>{moment(item.pickup_date_time).format("MMM Do YYYY")}</div>
                              </CTableDataCell>
                              <CTableDataCell>
                                <div>{moment(item.pickup_date_time).format("h:mm a")}</div>
                              </CTableDataCell>

                             

                              <CTableDataCell>
                                <div>
                                  {
                                      item.trip_status === "Completed" ?
                                      <Switch
                                      checkedIcon={false}
                                      uncheckedIcon={false}
                                      height={18}
                                      width={35}
                                      onChange={() => {
                                        handleStatusChange(item);
                                      }}
                                      checked={item.is_paid}
                                    /> : null
                                  }
                                </div>
                              </CTableDataCell>

                              <CTableDataCell>
                                <div>
                                  {item?.payment_collcted}
                                </div>
                              </CTableDataCell>

                              <CTableDataCell className="text-start location-icons d-flex">
                                <span style={{
                                  background,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  padding: "8px",
                                  borderRadius: "8px",
                                  fontWeight: "normal",
                                  color: "#fff",
                                  width: '100px',
                                  
                                }}>{status}</span>
                               

                              </CTableDataCell>
                              <CTableDataCell>
                                <div>
                                {
                                  item?.is_paid && item.trip_status === "Completed" && item?.payment_collcted === "MANUALLY" ?
                                    <div className="view_details_btn ms-2">
                                      <Link to={`/super-admin/trips/invoice/${item._id}`}>
                                        Invoice
                                      </Link>
                                    </div> : 
                                    item?.is_paid && item.trip_status === "Completed" && item?.payment_collcted === "ONLINE" ?
                                    <div className="view_details_btn ms-2">
                                    <Link to={`${item?.hosted_invoice_url}`}>
                                      Invoice
                                    </Link>
                                  </div> : null
                                } 
                                </div>
                              </CTableDataCell>

                              <CTableDataCell>
                                <div className="view_details_btn">
                                  <Link to={`/trips/view-trip-details/${item._id}`}>
                                    View Details
                                  </Link>
                                </div>
                              </CTableDataCell>
                            </CTableRow>

                          )
                        })}
                      </CTableBody>
                    </CTable> : <EmptyData />}

                    <div className='pagination'>
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={1}
                        pageCount={Math.ceil(allPagination / 10)}
                        previousLabel="<"
                        renderOnZeroPageCount={null}
                        forcePage={pageNumbers - 1}
                        containerClassName="pagination"  
                        activeClassName="active-page"  
                        pageClassName="pagination-item" 
                        pageLinkClassName="pagination-link"  
                      />
                    </div>

                  </div>}
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

    </>
  );
};

export default SuperRecentTrips; 
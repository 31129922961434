import React, { useEffect, useState, useContext, useRef } from "react";
import SuperAdminSideBar from "../Sidebar/SideBar";
import AppLoader from "../../AppLoader";
import AppHeader from "../../TopBar/AppHeader";
import { getTripById } from "../../../utils/api";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { usePDF } from 'react-to-pdf';



function InvoiceTrip() {

    const pendingId = useParams();
    const { toPDF, targetRef } = usePDF({ filename: 'Invoice.pdf' });


    useEffect(() => {
        getTripById(pendingId.id).then((res) => {
            console.log(res.result);
            setTrip(res.result);
        }).catch((err) => {
            console.log(err);
        });
    }, []);

    const [trip, setTrip] = useState([]);

    return (
        <div className="container-fluidd">
            <div className="col-md-12">
                <div>
                    <SuperAdminSideBar />
                    <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                        <AppHeader />
                        <div className="body flex-grow-1 px-3">
                            <div className="w-100 text-end">
                                <button onClick={toPDF} className=" view_details_btn me-4  ">Download PDF</button>

                            </div>
                            {/* <h1 className="heading-for-every-page">Invoice</h1> */}
                            <div className="px-5 mt-3" ref={targetRef}>


                                <div className="d-flex  justify-content-between align-items-center " style={{ paddingTop: '50px' }}>
                                    <h4 className="invoice_head">Invoice</h4>
                                    <h3 className="invoice_log">IDISPATCH MOBILITY</h3>
                                </div>

                                <div className="d-flex mt-3">
                                    <div className="me-2 invoice_detail">
                                        <p className="mb-0">Invoice number</p>
                                        <p className="mb-0">Date of issue</p>
                                        {/* <p className="mb-0">Expiration date</p> */}
                                    </div>
                                    <div className="ms-2 invoice_details">
                                        <p className="mb-0">{trip.trip_id}</p>
                                        <p className="mb-0"> {moment(trip.updatedAt).format("MMMM Do YYYY")}</p>
                                        {/* <p className="mb-0">te</p> */}
                                    </div>
                                </div>

                                <div className="d-flex mt-4">
                                    <div className="me-5 pe-4 invoice_detail">
                                        <p className="mb-0">IDISPATCH MOBILITY</p>
                                        <p className="mb-0">Spinakerhof 74 <br />
                                            1034 MN Amsterdam</p>
                                        <p className="mb-0">Nederland</p>
                                        <p className="mb-0">invoice@idispatch.nl</p>

                                    </div>
                                    <div className="ms-5  ps-4 invoice_details">
                                        <p className="mb-0">Invoice to</p>
                                        <p className="mb-0">{trip?.driverInfo?.email}</p>
                                        {/* <p className="mb-0">te</p> */}
                                    </div>
                                </div>

                                <div className="mt-5 ">
                                    {
                                        trip?.commission?.commission_type === 'Fixed' ?
                                            <h4 style={{ fontWeight: '600', color: 'black' }}>€{(trip?.commission?.commission_value).toFixed(2)} expires on {moment(trip.updatedAt).format("MMMM Do YYYY")}</h4>
                                            :
                                            <h4 style={{ fontWeight: '600', color: 'black' }}>€{(trip?.commission?.commission_value / 100 * trip?.price).toFixed(2)} expires on {moment(trip.updatedAt).format("MMMM Do YYYY")}</h4>

                                    }

                                </div>

                                <div className="mt-5 " style={{ width: '98%', margin: 'auto' }}>
                                    <div className="row">
                                        <div className="col col-lg-6 col-md-6 col-sm-2 col-xs-2 fnt_size  p-0">
                                            Description
                                        </div>
                                        <div className="col col-lg-2 col-md-2 col-sm-1 col-xs-1 fnt_size ">
                                            Number
                                        </div>
                                        <div className="col col-lg-2 col-md-2 col-sm-1 col-xs-1 fnt_size">
                                            Unit price
                                        </div>
                                        <div className="col col-lg-2 col-md-2 col-sm-1 col-xs-1 text-end p-0 fnt_size">
                                            Amount
                                        </div>
                                        <hr className="my-1 hr_new" />
                                        <div className="col col-lg-6 col-md-6 col-sm-2 col-xs-2  fnt_size2 p-0">
                                            {trip.comment}<br />
                                            {moment(trip.pickup_date_time).format("MMMM Do YYYY, h:mm a")}
                                        </div>
                                        <div className="col col-lg-2 col-md-2 col-sm-1 col-xs-1 fnt_size2 ">
                                            1
                                        </div>
                                        <div className="col col-lg-2 col-md-2 col-sm-1 col-xs-1 fnt_size2 ">

                                        {
                                                trip?.commission?.commission_type === 'Fixed' ?
                                                    <p className="mb-0 fnt_size2"> €{(trip?.commission?.commission_value).toFixed(2)} </p>
                                                    :
                                                    <p className="mb-0 fnt_size2"> €{(trip?.commission?.commission_value / 100 * trip?.price).toFixed(2)} </p>
                                            }
                                            
                                        </div>
                                        <div className="col col-lg-2 col-md-2 col-sm-1 col-xs-1 text-end fnt_size2 p-0">
                                        {
                                                trip?.commission?.commission_type === 'Fixed' ?
                                                    <p className="mb-0 fnt_size2"> €{(trip?.commission?.commission_value).toFixed(2)} </p>
                                                    :
                                                    <p className="mb-0 fnt_size2"> €{(trip?.commission?.commission_value / 100 * trip?.price).toFixed(2)} </p>
                                            }
                                        </div>
                                    </div>

                                    <div className="row mb-5">
                                        <div className="col-md-6">

                                        </div>
                                        <div className="col-md-3 fnt">
                                            { trip?.commission?.commission_type === 'Fixed' ?
                                            <p className="mb-0 fnt_size2">Subtotal €{(trip?.commission?.commission_value).toFixed(2)}  </p> :
                                            <p className="mb-0 fnt_size2">Subtotal €{(trip?.commission?.commission_value / 100 * trip?.price).toFixed(2)}  </p>

                                            }
                                            
                                            <p className="mb-0 fnt_size2">Total excluding tax</p>
                                            {
                                                trip?.commission?.commission_type === 'Fixed' ?
                                                    <p className="mb-0 fnt_size2">VAT (21% of €{(trip?.commission?.commission_value).toFixed(2)})</p>

                                                    :
                                                    <p className="mb-0 fnt_size2">VAT (21% of {(trip?.commission?.commission_value / 100 * trip?.price).toFixed(2)})</p>
                                            }

                                            <p className="mb-0 fnt_size2">Total</p>
                                            <p className="mb-0 fnt_size2">Amount to be paid</p>

                                        </div>
                                        <div className="col-md-3 text-end fnt p-0">
                                            {
                                                trip?.commission?.commission_type === 'Fixed' ?
                                                <p className="mb-0 fnt_size2"> €{(trip?.commission?.commission_value).toFixed(2)}  </p> :
                                                <p className="mb-0 fnt_size2"> €{(trip?.commission?.commission_value / 100 * trip?.price).toFixed(2)}  </p>

                                            }
                                            <p className="mb-0 fnt_size2">€ 00</p>
                                            {
                                                trip?.commission?.commission_type === 'Fixed' ?
                                                    <p className="mb-0 fnt_size2"> €{((trip?.commission?.commission_value * 21) / 100).toFixed(2)} </p>
                                                    :
                                                    <p className="mb-0 fnt_size2"> €{(((trip?.commission?.commission_value / 100 * trip?.price) * 21) / 100).toFixed(2)} </p>
                                            }
                                            {
                                                trip?.commission?.commission_type === 'Fixed' ?
                                                    <p className="mb-0 fnt_size2"> €{(((trip?.commission?.commission_value * 21) / 100) + (trip?.commission?.commission_value)).toFixed(2)} </p>
                                                    :
                                                    <p className="mb-0 fnt_size2"> €{((((trip?.commission?.commission_value / 100 * trip?.price) * 21) / 100) + (trip?.commission?.commission_value / 100 * trip?.price)).toFixed(2)} </p>
                                            }
                                            {
                                                trip?.commission?.commission_type === 'Fixed' ?
                                                    <p className="mb-0 fnt_size2"> €{(((trip?.commission?.commission_value * 21) / 100) + (trip?.commission?.commission_value)).toFixed(2)} </p>
                                                    :
                                                    <p className="mb-0 fnt_size2"> €{((((trip?.commission?.commission_value / 100 * trip?.price) * 21) / 100) + (trip?.commission?.commission_value / 100 * trip?.price)).toFixed(2)} </p>
                                            }
                                            {/* <p className="mb-0 fnt_size2"> €{trip?.price}</p>
                                            <p className="mb-0 fnt_size2"> €{trip?.price}</p> */}
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InvoiceTrip
import React, { useState, useEffect, useContext } from "react";
import AppHeader from "../../TopBar/AppHeader";
import SideBar2 from "../SideBar2";
// import SuperSideBar from "../../Taxi/SiderNavBar/Sidebar";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import moment from "moment";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormCheck,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CRow,
} from "@coreui/react";

import * as geolib from "geolib";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import backtovehicle from "../../../assets/images/left-arrow.png";
import { getFare, getTripById, getVehicleType, tripsUpdate, getCompany, getAddressPriceList } from "../../../utils/api";
import AppLoader from "../../AppLoader";
import { toast } from "react-toastify";
import { SuperBar } from "../../SuperAdmin/Sidebar/AppSideNavBar";
import SuperSideBar from "../../Taxi/SiderNavBar/Sidebar";
import { distanceBetweenTwoPoints } from "../../../utils/helpingFunction";
import { flushSync } from "react-dom";
import userContext from "../../../utils/context";

const EditpendingTrip = ({ role }) => {
  const [fares, setFares] = useState([]);

  const [selectedFare, setSelectedFare] = useState(null);
  const [refreshPrice, setRefreshPrice] = useState(false);
  const [selectedFrom, setSelectedFrom] = useState(true);
  const [selectedTo, setSelectedTo] = useState(true);
  const { user, setUser } = useContext(userContext)
  const [loadedData, setLoadedData] = useState(false)
  const pendingId = useParams();

  const navigate = useNavigate();
  const [price, setPrice] = useState("")

  const [pickupDate, setpickupDate] = useState(new Date());
  const [passengers, setPassengers] = useState([]);
  const [vehicle, setVehicle] = useState([]);
  const [currentTime, SetCurrentTime] = useState({
    hour: {
      hour: new Date().getHours(),
      minute: new Date().getMinutes() + 1,
    },
    minute: 0,
  });
  const [passengerError, setPassengerError] = useState([{
    name: false,
    phone: false,
    email: false,
    address: false,
  }])
  const [inputData, setInputData] = useState({
    id: "",
    vehicle: "",
    customer: "",
    trip_from: { address: "", lat: null, log: null },
    trip_to: { address: "", lat: null, log: null },
    pick_up_date: new Date(),
    passenger_detail: [],
    commission_type: "Fixed",
    commission_value: "",
    comment: "",
    pay_option: "Cash",
    passengerCount: "1",
    name: "",
    phone: "",
    email: "",
    address: "",
  });
  const [errors, setErrors] = useState({
    vehicle: null,
    customer: null,
    trip_from: null,
    trip_to: null,
    pick_up_date: null,
    passenger_detail: [],
    commission_type: null,
    commission_value: null,
    comment: null,
    pay_option: null,
    passengerCount: null,
    name: null,
    phone: null,
    email: null,
    address: null,
  });
  const [inputs, setInputs] = useState([
  ]);
  const [tripFrom, setTripFrom] = useState("");
  const [tripFromCoordinates, setTripFromCoordinates] = useState(null);
  const [tripTo, setTrimTo] = useState("");
  const [tripToCoordinates, setTripToCoordinates] = useState(null);
  const [loading, setLoading] = useState(false);

  const [priseList, setPriceList] = useState([])


  const incrementPassengerCount = () => {
    const currentCount = Number(inputData.passengerCount) || 0;
    const newCount = currentCount + 1;
    setInputData({ ...inputData, passengerCount: newCount });
    // Clear errors if any
    setErrors({ ...errors, passengerCount: null });
  };

  const decrementPassengerCount = () => {
    const currentCount = Number(inputData.passengerCount) || 0;
    // Only decrement if current count is greater than 0
    if (currentCount > 1) {
      setInputData({ ...inputData, passengerCount: currentCount - 1 });
      setErrors({ ...errors, passengerCount: null });
    }
  };



  useEffect(() => {
    getAddressPriceList()
      .then((res) => {
        if (res?.code === 200) {
          setPriceList(res?.allPriceList)
        } else {
        }
      })
      .catch((err) => {
        console.log("AddressLIst --", err)
      })
  }, []);


  const [TrpFrm, setTrpFrm] = useState("");
  const [TrpTo, setTrpTo] = useState("");

  useEffect(() => {
    checkMatchingRoute();
  }, [TrpFrm, TrpTo, inputData.vehicle, inputData.passengerCount])


  const checkMatchingRoute = () => {

    console.log("match object" , TrpFrm , TrpTo);
    if (TrpFrm && TrpTo) {
      console.log("match object" , TrpFrm , TrpTo);
      const matchingRoutes = priseList.filter(route =>
        TrpFrm?.toLowerCase().includes(route.departure_place?.toLowerCase()) &&
        TrpTo?.toLowerCase().includes(route.arrival_place?.toLowerCase())
      );

      if (matchingRoutes?.length > 0) {
        // alert("Matched Route Found!");
        const formVehicleType = inputData.vehicle
        const passengerCount = Number(inputData.passengerCount);
        let filtervehicle = vehicle?.filter(vechile => vechile?.name === formVehicleType)
        console.log("Filter", filtervehicle[0]?.otherName)
        console.log("match object", matchingRoutes, passengerCount, filtervehicle[0]?.otherName);
        let selectedRoute = null;
        if (formVehicleType && passengerCount) {
          // Compare vehicle type in a case-insensitive manner.
          const vehicleTypeLower = filtervehicle[0]?.otherName.toLowerCase();
          if (passengerCount <= 4) {
            selectedRoute = matchingRoutes.find(route =>
              route.vehicle_type.toLowerCase() === vehicleTypeLower &&
              route.number_of_person === 4
            );
            console.log(selectedRoute, "selectedRoute1");
          } else if (passengerCount >= 5 && passengerCount <= 8) {
            selectedRoute = matchingRoutes.find(route =>
              route.vehicle_type.toLowerCase() === vehicleTypeLower &&
              route.number_of_person === 8
            );
            console.log(selectedRoute, "selectedRoute2");
          }
        }
        // If a route is found based on vehicle type and passenger count,
        // set the form's fixedPrice.
        if (selectedRoute) {
          setPrice(selectedRoute.amount.toString());
        } else {
          console.log("No matching route found based on vehicle type and passenger count.");
          priceCalculator();
        }

      } else {
        console.log("No route match found based on departure/arrival.");
        priceCalculator();
      }
    }
    else {
      // priceCalculator();
    }



  }


  const priceCalculator = async () => {

    let distance = null;
    // if (inputData?.trip_from?.log && inputData?.trip_to?.log) {
    //   distance = (geolib.getDistance(
    //     {
    //       latitude: inputData?.trip_from?.lat,
    //       longitude: inputData?.trip_from?.log
    //     },
    //     {
    //       latitude: inputData?.trip_to?.lat,
    //       longitude: inputData?.trip_to?.log,
    //     }
    //   ) / 1000
    //   ).toFixed(2);
    // }
    distance = await distanceBetweenTwoPoints({
      lat: inputData?.trip_from?.lat,
      lng: inputData?.trip_from?.log,
    },
      {
        lat: inputData?.trip_to?.lat,
        lng: inputData?.trip_to?.log,
      }) || distance
    console.log("distance is from priceCalculator", distance);
    console.log("🚀 ~ priceCalculator ~ selectedFare:", selectedFare)
    if (distance) {
      setPrice((distance * selectedFare?.vehicle_fare_per_km).toFixed(2))
    } else {
      setPrice("0")
    }
  }
  useEffect(() => { priceCalculator() }, [refreshPrice])
  useEffect(() => {
    console.log("🚀 ~ EditpendingTrip ~ selectedFare:", selectedFare)
  }, [selectedFare])

  const handleSelectTripFrom = async (selectedAddress) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);
      setErrors({ ...errors, trip_from: null });
      const newInputData = inputData;
      inputData.trip_from.address = selectedAddress;
      inputData.trip_from.lat = latLng.lat;
      inputData.trip_from.log = latLng.lng;
      setInputData(newInputData);
      setTripFrom(selectedAddress);
      setTripFromCoordinates(latLng);
      setSelectedFrom(true);
      // setRefreshPrice(!refreshPrice)
      setTrpFrm(selectedAddress)
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleSelectTripTo = async (selectedAddress) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);
      setErrors({ ...errors, trip_to: null });
      const newInputData = inputData;
      inputData.trip_to.address = selectedAddress;
      inputData.trip_to.lat = latLng.lat;
      inputData.trip_to.log = latLng.lng;
      setInputData(newInputData);
      setTrimTo(selectedAddress);
      setTripToCoordinates(latLng);
      setSelectedTo(true)
      // setRefreshPrice(!refreshPrice)
      setTrpTo(selectedAddress)
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handlepickupDateChange = (date) => {
    setpickupDate(date);
  };

  const addPassenger = () => {
    setInputs([
      ...inputs,
      { name: "", email: "", phone: "", address: "" },
    ]);
  };

  function customSetHours(date, hour) {
    if (date instanceof Date) {
      const newDate = new Date(date);
      newDate.setHours(hour);
      return newDate;
    } else {
      throw new Error("Invalid Date object");
    }
  }
  function customSetMinutes(date, minute) {
    if (date instanceof Date) {
      const newDate = new Date(date);
      newDate.setMinutes(minute);
      return newDate;
    } else {
      throw new Error("Invalid Date object");
    }
  }

  const removePassenger = (index) => {
    const updatedPassengers = inputs.filter((_, i) => i !== index);
    setInputs(updatedPassengers);
  };
  const [customer, setCustomer] = useState([]);
  // useEffect(() => {

  // }, []);
  const formValidation = (inputs) => {
    const data = [...inputs];
    var re = /\S+@\S+\.\S+/;
    const phoneRegex = /^[0-9]{10}$/;
    let valid = true;
    for (let index = 0; index < data?.length; index++) {
      // const element = data[index];
      if (data[index].name == "") {
        data[index].nameCheck = "Name required";
        data[index].nameLengthCheck = "";
        valid = false;
      } else if (/^\d+$/.test(data[index].name)) {
        data[index].nameCheck = "Name should not be a number";
        data[index].nameLengthCheck = "";
        valid = false;
      } else if (data[index].name?.length < 3) {
        data[index].nameLengthCheck = "Please enter valid name";
        data[index].nameCheck = "";
        valid = false;
      } else {
        data[index].nameCheck = "";
        data[index].nameLengthCheck = "";
        // valid = true;
      }

      if (data[index].email == "") {
        data[index].emailCheck = "Email required";
        data[index].emailFormat = "";
        valid = false;
      } else if (!re.test(data[index].email)) {
        data[index].emailFormat = "Invalid Email";
        data[index].emailCheck = "";
        valid = false;
      } else {
        data[index].emailCheck = "";
        data[index].emailFormat = "";
        // valid = true;
      }
      if (data[index].phone == "") {
        data[index].phoneCheck = "Phone required";
        data[index].phoneLengthCheck = "";
        valid = false;
      }
      // else if (!phoneRegex.test(data[index].phone)) {
      // data[index].phoneCheck = "Enter only digit";
      // data[index].phoneLengthCheck = "";
      // valid = false;
      // } 
      else if (
        data[index].phone?.length < 7 ||
        data[index].phone?.length > 16
      ) {
        data[index].phoneLengthCheck = "Please enter valid phone number";
        data[index].phoneCheck = "";
        valid = false;
      } else {
        data[index].phoneCheck = "";
        data[index].phoneLengthCheck = "";
        // valid = true;
      }
      if (data[index].address == "") {
        data[index].addressCheck = "Address required";
        data[index].addressLengthCheck = "";
        valid = false;
      } else if (
        data[index].address?.length < 7 ||
        data[index].address?.length > 50
      ) {
        data[index].addressLengthCheck = "Please enter valid address";
        data[index].addressCheck = "";
        valid = false;
      } else {
        data[index].addressCheck = "";
        data[index].addressLengthCheck = "";
        // valid = true;
      }
    }

    setInputs(data);
    return valid;
  };

  const geteditid = () => {
    setLoading(true)
    getVehicleType().then((res) => {
      console.log(res.result, "vehicleType");
      if (res?.code === 200) {
        const vehicleFromApi = res.result;
        // setVehicle(res.result);
        getFare().then((res) => {
          console.log(res?.result, "fares");
          if (res?.code === 200) {
            const fareFromApi = res?.result;


            const newVehicle = [];
            vehicleFromApi.forEach((item) => {
              console.log("res2.result", fareFromApi)
              fareFromApi.forEach(fare => {
                if (fare.vehicle_type == item.name) {
                  newVehicle.push(item);
                }
              })
            })
            getCompany({ role: "HOTEL", name: "" })
              .then((res) => {
                console.log(res?.result, "customer");
                var values = res.result;
                if (res?.code === 200) {
                  // values = res?.result.filter((item) => item.status);
                  console.log("check result", values);
                  if (values) setCustomer(values);
                } else {
                }

                setVehicle(newVehicle);
                setFares(fareFromApi);
                getTripById(pendingId.id)
                  .then((res) => {
                    console.log("pending trips id", res);
                    if (res?.code == 200) {
                      const value = res.result;
                      setPrice(value?.price || "0")
                      setTripFrom(value.trip_from.address)
                      const matchingCustomer = values.find(
                        (item) => item._id === value.created_by
                      );

                      console.log(matchingCustomer, "chamakta ________________   hu a plus")

                      const customerName = matchingCustomer?._id

                      console.log("tests ______________", values, value.created_by, customerName)

                      // setTrpFrm(value.trip_from.address),
                      // setTrpTo(value.trip_to.address)


                      setTrimTo(value.trip_to.address)
                      setInputData({
                        id: value._id,
                        vehicle: value.vehicle_type,
                        customer: customerName,
                        trip_from: {
                          address: value.trip_from.address,
                          lat: value.trip_from.lat,
                          log: value.trip_from.log,
                        },
                        trip_to: {
                          address: value.trip_to.address,
                          lat: value.trip_to.lat,
                          log: value.trip_to.log,
                        },
                      

                        pick_up_date: new Date(value.pickup_date_time),
                        passenger_detail: [],
                        pay_option: value?.pay_option,

                        commission_type: value?.commission?.commission_type,
                        commission_value: value?.commission?.commission_value,
                        passengerCount: value?.passengerCount,
                        name: value?.customerDetails?.name,
                        phone: value?.customerDetails?.phone,
                        email: value?.customerDetails?.email,
                        address: value?.customerDetails?.address,

                        
                      });
                      handlepickupDateChange(new Date(value.pickup_date_time))

                      let passenger_detail = value.passenger_detail;
                      let add_on = [];
                      for (let value of passenger_detail) {
                        add_on.push({
                          name: value.name,
                          email: value.email,
                          phone: value.phone,
                          address: value.address,
                        });
                      }
                      setInputs(add_on);


                    }
                  })
              })
          }
        })
      }
    }).finally(() => {
      setTimeout(() => {
        // setFareOnVehicleType(inputData.vehicle_type)
        setLoading(false)
      }, 1500);
    });

  };

  useEffect(() => {
    if (inputData.vehicle !== "") {
      setFareOnVehicleType(inputData.vehicle)
    }
  }, [inputData.vehicle])

  const handleBlur = (index, key) => {
    const newPassengersError = [...passengerError]
    newPassengersError[index][key] = true;
    setPassengerError(newPassengersError);
    console.log(passengerError)
  };
  const adddata = () => {
    let id = inputData.id
    let data = inputData;
    let valid = true;
    let newErrors = { ...errors };
    const errorRes = formValidation(inputs);
    console.log("data beafore vehicle", vehicle);
    if (
      !data.trip_from.lat ||
      !data.trip_from.log ||
      tripFrom.address?.length < 1 ||
      !selectedFrom
    ) {
      console.log("enter valid trip from");
      valid = false;
      newErrors.trip_from = "Please enter valid trip from address";
    }
    if (
      !data.trip_to.lat ||
      !data.trip_to.log ||
      data.trip_to.address?.length < 1 ||
      !selectedTo
    ) {
      valid = false;
      newErrors.trip_to = "Please enter valid trip to address";
    }
    if (
      data.trip_from.address == data.trip_to.address ||
      (data.trip_from.lat == data.trip_to.lat &&
        data.trip_from.log == data.trip_to.log)
    ) {
      valid = false;
      toast.warning(`Please select different trip to address`, {
        position: "top-right",
        autoClose: 1000,
      });
      newErrors.trip_to = "Please select different trip to address";
    }
    if (data.vehicle?.length < 1) {
      valid = false;
      newErrors.vehicle = "Please select valid vehicle";
    }
    if (inputData.pick_up_date?.length < 1) {
      valid = false;
      newErrors.pick_up_date = "Please select valid pick-up date";
    }
    if (price <= 0) {
      valid = false;
      toast.warning(`Price should be greater than 0`, {
        position: "top-right",
        autoClose: 1000,
      });
    }
    if (role != "hotel" && (parseFloat(inputData.commission_value) <= 0 || inputData.commission_value.length == 0)) {
      valid = false;
      newErrors.commission_value = "Value should be greater than 0";
    }
    if (role != "hotel" &&
      (inputData.commission_type == "Percentage" &&
        parseFloat(inputData.commission_value) > 100)
    ) {
      valid = false;
      newErrors.commission_value = "Value should be less than equal 100";
    }
    if (role != "hotel" &&
      (inputData.commission_type == "Fixed" &&
        parseFloat(price) > 0 &&
        parseFloat(inputData.commission_value) > parseFloat(price))
    ) {
      valid = false;
      newErrors.commission_value = "Value should be less than trip price";
    }
    if(inputData.email.length >= 1) {
      let pattern = /^[\w-+.]+@([\w-]+\.)+[\w-]{2,5}$/;
      if (!pattern.test(inputData.email)) {
        valid = false;
        newErrors.email = "Please enter a valid Email address";
      }
    }
   
    if (!valid) {
      setErrors(newErrors);
      return console.log(errors);
    }
    data.customerDetails = {
      name: inputData.name,
      address: inputData.address,
      email: inputData.email,
      phone: inputData.phone,
    }
    data.passenger_detail = inputs;
    console.log("data beafore api", data);
    if (errorRes) {
      data.vehicle_type = data.vehicle;
      data.price = price
      delete data.vehicle;
      data.pickup_date_time = data.pick_up_date;
      delete data.pick_up_date;
      data.created_by = data.customer;
      data.hotel_id = data.customer ? data.customer : null;
      delete data.customer;
      data.created_by_company_id = user._id
      data.commission = {
        commission_type: data.commission_type,
        commission_value: data.commission_value,
      };
      tripsUpdate(id, data).then((res) => {
        console.log("response---->>>>", res);
        if (res.code === 200) {
          toast.success(`${res.message}`, {
            position: "top-right",
            autoClose: 1000,
          });
          back()

        } else {
          toast.warning(`${res.message}`, {
            position: "top-right",
            autoClose: 1000,
          });
        }
      });
    } else {
      toast.warning("Please Enter Passenger Detail", {
        position: "top-right",
        autoClose: 1000,
      });
    }
  };

  useEffect(() => {
    geteditid();
  }, []);

  const back = () => {
    navigate(`${role == "hotel" ? "" : "/taxi"}/trips/pendingtrips`);
  }
  const addOnChangeHandler = (e, index) => {
    let arr = inputs;
    console.log(inputs);
    let obj = arr[index];
    obj[e.target.name] = e.target.value;
    arr[index] = obj;
    setInputs([...arr]);
    formValidation(inputs)
  };
  const handlePriceChange = (event) => {
    const inputValueRegex = /^\d*\.?\d{0,2}$/; // Regular expression to allow up to 2 decimal places

    if (inputValueRegex.test(event.target.value) || event.target.value === '') {
      setPrice(event.target.value);
    }
  };
  const setFareOnVehicleType = (vehicle_type) => {
    console.log("🚀 ~ setFareOnVehicleType ~ setFareOnVehicleType:", "Run from function setFareOnVehicleType", fares, vehicle_type);

    fares.forEach((fare) => {
      console.log("🚀 ~ fares.forEach ~ fares:", fares)
      if (fare.vehicle_type == vehicle_type) {
        setSelectedFare(fare);
        console.log("selectecd fare is", fare)
      }
    })
  }
  return (
    <>
      <div className="container-fluidd">
        <div className="col-md-12">
          <div>
            {role == "hotel" ? < SideBar2 /> : <SuperSideBar />}

            <div className="wrapper d-flex flex-column min-vh-100 bg-light">
              <AppHeader />
              <div
                className="body flex-grow-1 px-3"
                style={{ paddingBottom: "20px" }}
              >
                <h1 className="heading-for-every-page">
                  <Link to={`${role == "hotel" ? "" : "/taxi"}/trips/pendingtrips`}>
                    <img src={backtovehicle} alt="edit" /> Edit Pending Trip
                  </Link>
                </h1>
                {loading ? <AppLoader /> : <div className="active-trip-outer">
                  <CRow>
                    <CCol xs={12}>
                      <CCard className="mb-4">
                        {/* <CCardHeader>
 <strong>Add Trip Details</strong>
 </CCardHeader> */}
                        <CCardBody>
                          <CForm className="row g-3">
                            {/* <CCol md={6}>
 <CFormLabel htmlFor="inputtripdname">Driver Name</CFormLabel>
 <CFormSelect name="driver_name" onChange={inputHandler}>
 <option default>Select Driver</option>
 {driver?.map((e, i) => {
 return (
 <>
 <option value={e._id}>{e.first_name}</option>
 </>
 )
 })}


 </CFormSelect>
 </CCol> */}
                            <CCol md={6}>
                              <CFormLabel htmlFor="inputvehicletype">
                                Vehicle Type <span className="asterisk-mark">*</span>
                              </CFormLabel>
                              <CFormSelect
                                name="vehicle"
                                value={inputData.vehicle}
                                onChange={(data) => {
                                  console.log(data.target.value);
                                  // setFareOnVehicleType(data.target.value);
                                  setInputData({
                                    ...inputData,
                                    vehicle: data.target.value,
                                  });
                                  // setRefreshPrice(!refreshPrice)

                                  if (data.target.value < 1) {
                                    setErrors({
                                      ...errors,
                                      vehicle: "Please select vehicle",
                                    });
                                  } else {
                                    setErrors({ ...errors, vehicle: null });
                                  }
                                }}
                              >
                                {/* <option selected>{inputData.vehicle}</option> */}
                                {vehicle?.map((e, i) => {
                                  return (

                                    <option key={i} value={e?.name}>{e?.otherName ? e?.otherName : e?.name}</option>

                                  );
                                })}
                              </CFormSelect>
                              {errors.vehicle && (
                                <span
                                  style={{ color: "red" }}
                                  className="text-danger"
                                >
                                  {errors.vehicle}
                                </span>
                              )}
                            </CCol>

                            <CCol md={6}>
                              <CFormLabel htmlFor="inputvehicletype">
                                Customer
                                {/* <span className="asterisk-mark">*</span> */}
                              </CFormLabel>

                              <CFormSelect
                                name="customer"
                                value={inputData.customer}
                                onChange={(data) => {
                                  console.log(data.target.value);

                                  const customerById = customer.find(item => item._id === data.target.value);
                                  if (customerById && customerById?.hotel_location) {
                                    console.log("🚀 ~ SuperRequestTrip ~ customerById:", customerById, customerById?.hotel_location?.address)
                                    setTripFrom(customerById?.hotel_location?.address)
                                    setInputData({
                                      ...inputData,
                                      customer: data.target.value,
                                      trip_from: customerById?.hotel_location
                                    });
                                    setRefreshPrice(!refreshPrice)
                                  }
                                  if (data.target.value < 1) {
                                    setErrors({
                                      ...errors,
                                      customer: "Please select vehicle",
                                    });
                                  } else {
                                    setErrors({ ...errors, customer: null });
                                  }
                                }}
                              >
                                <option default>Select Customer</option>

                                {customer?.map((e, i) => {
                                  return (
                                    <>
                                      <option value={e._id}>
                                        {e.company_name}
                                      </option>
                                    </>
                                  );
                                })}

                              </CFormSelect>
                              {loadedData && customer?.length == 0 && <span
                                style={{ color: "red" }}
                                className="text-danger"
                              >
                                You should first add customer before requesting a trip.
                              </span>}
                              {errors.customer && (
                                <span
                                  style={{ color: "red" }}
                                  className="text-danger"
                                >
                                  {errors.customer}
                                </span>
                              )}
                            </CCol>


                            {/* <CCol xs={6}>
                              <CFormLabel htmlFor="inputtripfrom">
                                Passenger Count
                              </CFormLabel>
                              <CFormInput
                                id="inputtripfrom"
                                value={inputData.passengerCount}
                                type="number"
                                onInput={(e) => {
                                  if (e.target.value.length > 20) {
                                    setErrors({
                                      ...errors,
                                      passengerCount: "Max 20 characters allowed",
                                    });
                                    return;
                                  } else {
                                    setErrors({ ...errors, passengerCount: null });
                                  }
                                  setInputData({
                                    ...inputData,
                                    passengerCount: e.target.value,
                                  });
                                }}
                              />
                              {errors.passengerCount && (
                                <span
                                  style={{ color: "red" }}
                                  className="text-danger"
                                >
                                  {errors.passengerCount}
                                </span>
                              )}
                            </CCol> */}


                            <CCol xs={6}>
                              <CFormLabel htmlFor="passengerCountDisplay">
                                Passenger Count
                              </CFormLabel>
                              <div className="d-flex align-items-center justify-content-center">
                                <CButton onClick={decrementPassengerCount} color="secondary">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0" />
                                  </svg>
                                </CButton>
                                {/* Display the passenger count as text */}
                                <div id="passengerCountDisplay" key={inputData.passengerCount} className="mx-3">
                                  {inputData.passengerCount} Passengers
                                </div>
                                <CButton onClick={incrementPassengerCount} color="secondary">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
                                  </svg>
                                </CButton>
                              </div>
                              {errors.passengerCount && (
                                <span style={{ color: "red" }} className="text-danger">
                                  {errors.passengerCount}
                                </span>
                              )}
                            </CCol>

                            <CCol md={6}>
                              <CFormLabel htmlFor="inputpickupdate">
                                Pickup Date and Time <span className="asterisk-mark">*</span>
                              </CFormLabel>
                              <br />
                              <DatePicker
                                selected={pickupDate}
                                className="form-control"
                                showTimeSelect
                                timeIntervals={1}
                                minTime={customSetHours(
                                  customSetMinutes(
                                    new Date(),
                                    currentTime.minute
                                  ),
                                  currentTime.hour
                                )}
                                maxTime={customSetHours(
                                  customSetMinutes(new Date(), 59),
                                  23
                                )}
                                dateFormat="MM/dd/yyyy hh:mm a"
                                minDate={new Date()}
                                onChange={(data) => {
                                  console.log("🚀 ~ EditpendingTrip ~ data:", data)

                                  setpickupDate(data);
                                  setInputData({
                                    ...inputData,
                                    pick_up_date: data,
                                  });
                                  if (data < 1) {
                                    setErrors({
                                      ...errors,
                                      pick_up_date:
                                        "Please add valid date for pickup date",
                                    });
                                  } else {
                                    setErrors({
                                      ...errors,
                                      pick_up_date: null,
                                    });
                                  }
                                }}
                              />
                            </CCol>

                            <CCol xs={6}>
                              <CFormLabel htmlFor="inputtripfrom">
                                Trip From<span className="asterisk-mark">*</span>
                              </CFormLabel>
                              <PlacesAutocomplete
                                value={tripFrom}

                                onChange={(data) => {
                                  setSelectedFrom(false);
                                  console.log(data);
                                  setTripFrom(data);

                                  setErrors({
                                    ...errors,
                                    trip_from:
                                      "Please add valid trip from address",
                                  });

                                }}
                                onSelect={handleSelectTripFrom}
                              >
                                {({
                                  getInputProps,
                                  suggestions,
                                  getSuggestionItemProps,
                                  loading,
                                }) => (
                                  <div>
                                    <CFormInput
                                      id="inputtripfrom"
                                      {...getInputProps({
                                        placeholder: "Enter a location",
                                      })}
                                    />
                                    <div className="suugestion-div">
                                      <div className="suggestion-inner">
                                        {loading && <div>Loading...</div>}
                                        {suggestions
                                          .slice(0, 3)
                                          .map((suggestion, i) => (
                                            <div
                                              key={i}
                                              {...getSuggestionItemProps(
                                                suggestion
                                              )}
                                            >
                                              {suggestion.description}
                                            </div>
                                          ))}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </PlacesAutocomplete>
                              {!selectedFrom && (
                                <span
                                  style={{ color: "red" }}
                                  className="text-danger"
                                >
                                  {errors.trip_from}
                                </span>
                              )}
                              {/* <CFormSelect
 maxLength="50"
 className="form-control bg-transparent"
 name="vehicle_type"
 autoComplete="off"
 >
 <option default></option>
 </CFormSelect> */}
                            </CCol>
                            <CCol xs={6}>
                              <CFormLabel htmlFor="inputtripto">
                                Trip To <span className="asterisk-mark">*</span>
                              </CFormLabel>
                              <PlacesAutocomplete
                                value={tripTo}

                                onChange={(data) => {
                                  console.log(data);
                                  setSelectedTo(false);
                                  setTrimTo(data);

                                  setErrors({
                                    ...errors,
                                    trip_to:
                                      "Please add valid trip to address",
                                  });

                                }}
                                onSelect={handleSelectTripTo}
                              >
                                {({
                                  getInputProps,
                                  suggestions,
                                  getSuggestionItemProps,
                                  loading,
                                }) => (
                                  <div>
                                    <CFormInput
                                      id="inputtripto"
                                      {...getInputProps({
                                        placeholder: "Enter a location",
                                      })}
                                    />
                                    <div className="suugestion-div">
                                      <div className="suggestion-inner">
                                        {loading && <div>Loading...</div>}
                                        {suggestions
                                          .slice(0, 3)
                                          .map((suggestion, i) => (
                                            <div
                                              key={i}
                                              {...getSuggestionItemProps(
                                                suggestion
                                              )}
                                            >
                                              {suggestion.description}
                                            </div>
                                          ))}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </PlacesAutocomplete>
                              {!selectedTo && (
                                <span
                                  style={{ color: "red" }}
                                  className="text-danger"
                                >
                                  {errors.trip_to}
                                </span>
                              )}
                              {/* <CFormSelect
 maxLength="50"
 className="form-control bg-transparent"
 name="vehicle_type"
 autoComplete="off"
 >
 <option default></option>
 </CFormSelect> */}
                              {/* <CFormInput id="inputtripto" name="trip_to" onChange={inputHandler} /> */}
                            </CCol>
                            <CCol xs={6}>
                              <CFormLabel htmlFor="inputfixedprice">
                                Price 
                              </CFormLabel>
                              <CFormInput id="inputfixedprice" name="fixed_price"
                                type="number"
                                step="0.01"
                                disabled={role == "hotel"}
                                value={isNaN(Number(price)) ? "0.00" : Number(price).toFixed(2)}
                                onChange={(e) => handlePriceChange(e)}
                              />


                            </CCol>

                            {role != "hotel" && <CCol md={6}>
                              <CFormLabel htmlFor="inputvehicletype">
                                Pay Type <span className="asterisk-mark">*</span>
                              </CFormLabel>
                              <CFormSelect
                                name="pay"
                                value={inputData.pay_option}
                                onChange={(data) => {
                                  console.log(data.target.value);
                                  setInputData({
                                    ...inputData,
                                    pay_option: data.target.value,
                                  });
                                  if (data.target.value < 1) {
                                    setErrors({
                                      ...errors,
                                      pay_option:
                                        "Please select commission type",
                                    });
                                  } else {
                                    setErrors({ ...errors, pay_option: null });
                                  }
                                }}
                              >
                                <option value={"Cash"} selected>
                                  Cash
                                </option>
                                <option value="Card">Card</option>
                                <option value='Hotel Account'>Hotel Account</option>
                                <option value='ON ACCOUNT'>ON ACCOUNT</option>
                              </CFormSelect>
                            </CCol>}
                            {role != "hotel" && <CCol md={6}>
                              <CFormLabel htmlFor="inputvehicletype">
                                Commission Type <span className="asterisk-mark">*</span>
                              </CFormLabel>
                              <CFormSelect
                                name="commission_type"
                                value={inputData.commission_type}
                                onChange={(data) => {
                                  setInputData((prev) => {
                                    const newValue = prev;
                                    newValue.commission_type = data.target.value;
                                    newValue.commission_value = "";
                                    return newValue;
                                  });
                                  if (data.target.value < 1) {
                                    setErrors({
                                      ...errors,
                                      commission_type: "Please select commission type",
                                    });
                                  } else {
                                    setErrors({ ...errors, commission_type: null });
                                  }
                                }}
                              >
                                <option value={"Fixed"} selected>Fixed</option>
                                {/* <option value="Fixed">Cash</option> */}
                                <option value='Percentage'>Percentage</option>


                              </CFormSelect>

                            </CCol>}
                            {role != "hotel" && <CCol xs={6}>
                              <CFormLabel htmlFor="inputtripfrom">
                                Commission Value <span className="asterisk-mark">*</span>
                              </CFormLabel>
                              <CFormInput
                                id="inputtripfrom"
                                type="number"
                                value={inputData.commission_value}
                                onChange={(e) => {
                                  if (inputData.commission_type == "Percentage") {
                                    if (e.target.value > 100) {
                                      setErrors({
                                        ...errors, commission_value: "Value should be smaller than 100"
                                      })
                                      setInputData({
                                        ...inputData,
                                        commission_value: e.target.value,
                                      });
                                      return
                                    }
                                  }
                                  setInputData({
                                    ...inputData,
                                    commission_value: e.target.value,
                                  });
                                  setErrors({
                                    ...errors, commission_value: null
                                  })
                                }}
                              />
                              {errors.commission_value && (
                                <span
                                  style={{ color: "red" }}
                                  className="text-danger"
                                >
                                  {errors.commission_value}
                                </span>
                              )}
                            </CCol>}

                            <CCol xs={6}>
                              <CFormLabel htmlFor="inputtripfrom">
                                Passenger Name
                              </CFormLabel>
                              <CFormInput
                                id="inputtripfrom"
                                value={inputData.name}
                                onChange={(e) => {
                                  if (e.target.value.length > 20) {
                                    setErrors({
                                      ...errors,
                                      name: "Max 20 characters allowed",
                                    });
                                    return;
                                  } else {
                                    setErrors({ ...errors, name: null });
                                  }
                                  setInputData({
                                    ...inputData,
                                    name: e.target.value,
                                  });
                                }}
                              />
                              {errors.name && (
                                <span
                                  style={{ color: "red" }}
                                  className="text-danger"
                                >
                                  {errors.name}
                                </span>
                              )}
                            </CCol>
                            <CCol xs={6}>
                              <CFormLabel htmlFor="inputtripfrom">
                                Passenger Address
                              </CFormLabel>
                              <CFormInput
                                id="inputtripfrom"
                                value={inputData.address}
                                onChange={(e) => {
                                  if (e.target.value.length > 50) {
                                    setErrors({
                                      ...errors,
                                      address: "Max 50 characters allowed",
                                    });
                                    return;
                                  } else {
                                    setErrors({ ...errors, address: null });
                                  }
                                  setInputData({
                                    ...inputData,
                                    address: e.target.value,
                                  });
                                }}
                              />
                              {errors.address && (
                                <span
                                  style={{ color: "red" }}
                                  className="text-danger"
                                >
                                  {errors.address}
                                </span>
                              )}
                            </CCol>
                            <CCol xs={6}>
                              <CFormLabel htmlFor="inputtripfrom">
                                Passenger Email
                              </CFormLabel>
                              <CFormInput
                                id="inputtripfrom"
                                value={inputData.email}
                                type="email"
                                onChange={(e) => {
                                  if (e.target.value.length > 20) {
                                    setErrors({
                                      ...errors,
                                      email: "Max 20 characters allowed",
                                    });
                                    return;
                                  } else {
                                    setErrors({ ...errors, email: null });
                                  }
                                  setInputData({
                                    ...inputData,
                                    email: e.target.value,
                                  });
                                }}
                              />
                              {errors.email && (
                                <span
                                  style={{ color: "red" }}
                                  className="text-danger"
                                >
                                  {errors.email}
                                </span>
                              )}
                            </CCol>
                            <CCol xs={6}>
                              <CFormLabel htmlFor="inputtripfrom">
                                Passenger Phone
                              </CFormLabel>
                              <CFormInput
                                id="inputtripfrom"
                                value={inputData.phone}
                                type="number"
                                onChange={(e) => {
                                  if (e.target.value.length > 16) {
                                    setErrors({
                                      ...errors,
                                      phone: "Max 16 characters allowed",
                                    });
                                    return;
                                  } else {
                                    setErrors({ ...errors, phone: null });
                                  }
                                  setInputData({
                                    ...inputData,
                                    phone: e.target.value,
                                  });
                                }}
                              />
                              {errors.phone && (
                                <span
                                  style={{ color: "red" }}
                                  className="text-danger"
                                >
                                  {errors.phone}
                                </span>
                              )}
                            </CCol>
                          </CForm>
                        </CCardBody>
                      </CCard>
                    </CCol>
                  </CRow>

                  <CRow className="passenger-details">
                    {inputs?.map((passenger, index) => {
                      const error = {
                        name: false,
                        email: false,
                        phone: false,
                        address: false,
                      };
                      return (
                        <CCol xs={12} key={index}>
                          <CCard className="mb-4">
                            <CCardHeader>
                              <strong>Passenger Details</strong>
                              {index >= 0 && (
                                <CButton
                                  type="button"
                                  onClick={() => removePassenger(index)}
                                  className="remove_passenger_btn"
                                >
                                  - Remove Passenger
                                </CButton>
                              )}
                            </CCardHeader>
                            <CCardBody>
                              <CForm className="row g-3">
                                <CCol md={6}>
                                  <CFormLabel htmlFor="inputname"

                                  >
                                    Name<span className="asterisk-mark">*</span>
                                  </CFormLabel>
                                  <CFormInput
                                    aria-label="name"
                                    name="name"
                                    value={passenger.name || ""}
                                    onChange={(e) => {
                                      addOnChangeHandler(e, index);
                                    }}
                                    onBlur={() => {
                                      handleBlur(index, "name")
                                    }}
                                  />
                                  {passengerError[index].name && <div style={{ color: "red" }}>
                                    {passenger.nameCheck}
                                    <br />
                                    {passenger.nameLengthCheck}
                                  </div>}
                                </CCol>
                                <CCol xs={6}>
                                  <CFormLabel htmlFor="inputphnno"

                                  >
                                    Phone<span className="asterisk-mark">*</span>
                                  </CFormLabel>
                                  <CFormInput
                                    id="inputphnno"
                                    name="phone"
                                    type="number"
                                    value={passenger.phone || ""}
                                    onChange={(e) => {
                                      addOnChangeHandler(e, index);
                                    }}
                                    onBlur={() => {
                                      handleBlur(index, "phone")
                                    }}
                                  />
                                  {passengerError[index].phone && <div style={{ color: "red" }}>
                                    {passenger.phoneCheck}
                                    <br />
                                    {passenger.phoneLengthCheck}
                                  </div>}
                                </CCol>
                                <CCol xs={6}>
                                  <CFormLabel htmlFor="inputtemailadd"

                                  >
                                    Email Address<span className="asterisk-mark">*</span>
                                  </CFormLabel>
                                  <CFormInput
                                    id="inputemailadd"
                                    name="email"
                                    value={passenger.email || ""}
                                    onChange={(e) => {
                                      addOnChangeHandler(e, index);

                                    }}
                                    onBlur={() => {
                                      handleBlur(index, "email")
                                    }}
                                  />
                                  {passengerError[index].email && <div style={{ color: "red" }}>
                                    {passenger.emailCheck}
                                    <br />
                                    {passenger.emailFormat}
                                  </div>}
                                </CCol>
                                <CCol xs={6}>
                                  <CFormLabel htmlFor="inputaddress"

                                  >
                                    Address<span className="asterisk-mark">*</span>
                                  </CFormLabel>
                                  <CFormInput
                                    id="inputaddress"
                                    name="address"
                                    value={passenger.address || ""}
                                    onChange={(e) => {
                                      addOnChangeHandler(e, index);
                                    }}
                                    onBlur={() => {
                                      handleBlur(index, "address")
                                    }}
                                  />
                                  {passengerError[index].address && <div style={{ color: "red" }}>
                                    {passenger.addressCheck}
                                    <br />
                                    {passenger.addressLengthCheck}
                                  </div>}
                                </CCol>
                              </CForm>
                            </CCardBody>
                          </CCard>
                        </CCol>
                      );
                    })}
                    {/* <CCol xs={12}>
                      <div
                        className="d-flex justify-content-end"
                        style={{ marginTop: "40px" }}
                      >
                        <CButton
                          type="button"
                          onClick={addPassenger}
                          className="add_passenger_btn"
                        >
                          + Add Passenger
                        </CButton>
                      </div>
                    </CCol> */}
                  </CRow>
                  <CCol xs={12}>
                    <div
                      className="d-flex justify-content-center"
                      style={{ marginTop: "40px" }}
                    >
                      <CButton type="submit" className="submit-btn" onClick={adddata}>
                        Submit
                      </CButton>
                      <CButton type="button" className="cancel-btn" onClick={back}>
                        Cancel
                      </CButton>
                    </div>
                  </CCol>
                </div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditpendingTrip;
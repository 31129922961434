
import React, { useState , useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import SimpleBar from 'simplebar-react';
//import { AppSidebarNav } from './AppSidebarNav'
import 'simplebar-react/dist/simplebar.min.css';
import applogo from '../../../assets/images/taxi-logo.png'
// sidebar nav config
//import navigation from '../../_nav';
import { SuperNavBar } from './Appsidenavbar';
import navigation from '../SiderNavBar/navbar';
import { Link } from "react-router-dom";
import { getProfile } from '../../../utils/api';
// console.log(navigation,'nav')
//const SideBar2 = () => (
//   <SimpleBar style={{ maxHeight: 300 }}>
//   <AppSidebarNav items={navigation} />
//     </SimpleBar>
//);

const SuperSideBar = () => {
    
     const dispatch = useDispatch()
     const unfoldable = useSelector((state) => state.sidebarUnfoldable)
     const sidebarShow = useSelector((state) => state.sidebarShow)

     const [sidebar, setSidebar] = useState(navigation);


     useEffect(() => {
      const getUser = async () => {
        const token = await localStorage.getItem('token');
        try {
          const response = await getProfile(token);
          if (response.code === 200) {
            if (response.result.is_special_plan_active) {
              const filteredSidebar = navigation.filter((item) => item.name !== 'Plans');
              setSidebar(filteredSidebar);
            } else {
              setSidebar(navigation);
            }
          }
        } catch (error) {
          console.log(error);
        }
      };
  
      getUser();
    }, []);
  
    //  console.log("Dispatch sidebar --" , navigation)
    return (
      <CSidebar
        position="fixed"
        unfoldable={unfoldable}
      visible={sidebarShow}
        onVisibleChange={(visible) => {
          dispatch({ type: 'set', sidebarShow: visible })
        }}
      >
        <CSidebarBrand className="d-none d-md-flex" to="/">
          {/* <CIcon className="sidebar-brand-full" icon={logoNegative} height={35} />
          <CIcon className="sidebar-brand-narrow" icon={sygnet} height={35} /> */}
            <Link to={`/dashboard`} >
          <img src={applogo} height={50} width={100}/>
          </Link>
        </CSidebarBrand>
        <CSidebarNav>
          <SimpleBar>
            {/* <AppSidebarNav items={navigation} /> */}
            <SuperNavBar className="sidebar_outer" items={sidebar}/>
          </SimpleBar>
        </CSidebarNav>
        {/* <CSidebarToggler
          className="d-none d-lg-flex"
          onClick={() => dispatch({ type: 'set', sidebarUnfoldable: !unfoldable })}
        /> */}
      </CSidebar>
    )
  }

export default SuperSideBar;
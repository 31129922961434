import React, { useState, useEffect } from "react";
import AppHeader from '../../TopBar/AppHeader'
import SuperAdminSideBar from '../Sidebar/SideBar'
import {
    CButton,
    CCard,
    CCardBody,
    CCol,
    CForm,
    CFormInput,
    CFormLabel,
    CRow,
    CFormSelect
} from '@coreui/react'

import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { changeSettings , getSettings } from "../../../utils/api";
import { toast } from 'react-toastify';

const Setting = () => {

    const navigate = useNavigate();

    const initialValues = {
        commision: "",
        pre_notification_time: "",
        // minimum_fare: "",
        // price_per_min: "",
        // waiting_fare: "",

    };


    const validationSchema = Yup.object().shape({
        commision: Yup.string().trim().required("Admin fare  is required"),
        pre_notification_time: Yup.string().trim().required("Driver notification time  is required"),
        // pre_notification_time: Yup.number().max(20, "Driver notification time less than or equal to 20 minutes").required("Driver notification time  is required"),
        // minimum_fare: Yup.number().max(1000, "Minimum Fare must be less than or equal to 1000 in euro").required("Minimum Fare  is required"),
        // price_per_min: Yup.number().max(1000, "Vehicle Fare Per Minute must be less than or equal to 1000 in euro").required("Vehicle Fare  is required"),
        // waiting_fare: Yup.number().max(1000, "Waiting Fare must be less than or equal to 1000 in euro").required("Waiting Fare is required"),
    });

    const [submitLoader, setSubmitLoader] = useState(false)


    useEffect(() => {
        getSetting();
    }, []);

    const getSetting = () => {
        getSettings().then((res) => {
            console.log("response---->>>>", res)
            if (res?.code === 200) {
                formik.setValues({
                    commision: res.message[0].value,
                    pre_notification_time: res.message[1].value,
                    // minimum_fare: res.result.minimum_fare,
                    // price_per_min: res.result.price_per_min,
                    // waiting_fare: res.result.waiting_fare,
                })
            }
        }).catch(error => console.log(error))
    }


    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            console.log("values", values);

            setSubmitLoader(true)

            changeSettings(values).then((res) => {
                console.log("response---->>>>", res)
                if (res?.code === 200) {
                  toast.success(`${res?.message}`, {
                    position: 'top-right',
                    autoClose: 1000,
                  });
                //   navigate("/taxi/fare/listoffares")
                } else {
                  toast.warning(`${res.message}`, {
                    position: 'top-right',
                    autoClose: 1000,
                  });
                }
              }).catch(error=> toast.warning(`There is some problem`, {
                position: 'top-right',
                autoClose: 1000,
              })).finally(()=>{
                setSubmitLoader(false)
              })


        },
    });


    const back = () => {
        navigate("/taxi/fare/listoffares")
    }

    return (
        <>
            <div className="container-fluidd">
                <div className="col-md-12">
                    <div>
                        <SuperAdminSideBar />

                        <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                            <AppHeader />
                            <div
                                className="body flex-grow-1 px-3"
                                style={{ paddingBottom: "20px" }}
                            >
                                <h1 className="heading-for-every-page">Settings</h1>
                                <div className="active-trip-outer" id="fare_management_page">
                                    <div className="trips-head d-flex justify-content-between">
                                    </div>
                                    <CRow>

                                        <CCol xs={12}>
                                            <CCard className="mb-4">
                                                <CCardBody>


                                                    <form onSubmit={formik.handleSubmit} noValidate className="row g-3">

                                                        <CCol md={6}>
                                                            <CFormLabel htmlFor="inputvehicle_fare_per_km">Admin commision<span className="asterisk-mark">*</span></CFormLabel>
                                                            <CFormInput
                                                                aria-label="vehicle fare"
                                                                type="text"
                                                                {...formik.getFieldProps("commision")}
                                                                className={clsx(
                                                                    "form-control bg-transparent",
                                                                    {
                                                                        "is-invalid":
                                                                            formik.touched.commision && formik.errors.commision,
                                                                    },
                                                                    {
                                                                        "is-valid":
                                                                            formik.touched.commision && !formik.errors.commision,
                                                                    }
                                                                )}
                                                                name="commision"
                                                                autoComplete="off" />
                                                            {formik.errors.commision && formik.touched.commision ? (
                                                                <div className="text-danger">{formik.errors.commision}</div>
                                                            ) : null}
                                                        </CCol>

                                                        <CCol md={6}>
                                                            <CFormLabel htmlFor="inputvehicle_fare_per_km">Driver pre notification Time <span className="asterisk-mark">*</span> (in minutes)</CFormLabel>
                                                            <CFormInput
                                                                aria-label="vehicle fare"
                                                                type="number"
                                                                {...formik.getFieldProps("pre_notification_time")}
                                                                className={clsx(
                                                                    "form-control bg-transparent",
                                                                    {
                                                                        "is-invalid":
                                                                            formik.touched.pre_notification_time && formik.errors.pre_notification_time,
                                                                    },
                                                                    {
                                                                        "is-valid":
                                                                            formik.touched.pre_notification_time && !formik.errors.pre_notification_time,
                                                                    }
                                                                )}
                                                                name="pre_notification_time"
                                                                autoComplete="off" />
                                                            {formik.errors.pre_notification_time && formik.touched.pre_notification_time ? (
                                                                <div className="text-danger">{formik.errors.pre_notification_time}</div>
                                                            ) : null}
                                                        </CCol>

                                                        {/* <CCol xs={6}>
                                                            <CFormLabel htmlFor="inputvehicleminfare">Minimum Fare  (in €)<span className="asterisk-mark">*</span></CFormLabel>
                                                            <CFormInput type="number" id="inputvehicleminfare"    {...formik.getFieldProps("minimum_fare")}

                                                                className={clsx(
                                                                    "form-control bg-transparent",
                                                                    {
                                                                        "is-invalid":
                                                                            formik.touched.minimum_fare && formik.errors.minimum_fare,
                                                                    },
                                                                    {
                                                                        "is-valid":
                                                                            formik.touched.minimum_fare && !formik.errors.minimum_fare,
                                                                    }
                                                                )}
                                                                name="minimum_fare"
                                                                autoComplete="off" />
                                                            {formik.errors.minimum_fare && formik.touched.minimum_fare ? (
                                                                <div className="text-danger">{formik.errors.minimum_fare}</div>
                                                            ) : null}
                                                        </CCol>
                                                        <CCol xs={6}>
                                                            <CFormLabel htmlFor="inputmindistance">Vehicle Fare (in €) Per Minute <span className="asterisk-mark">*</span></CFormLabel>
                                                            <CFormInput type="number" id="inputmindistance"   {...formik.getFieldProps("price_per_min")}

                                                                className={clsx(
                                                                    "form-control bg-transparent",
                                                                    {
                                                                        "is-invalid":
                                                                            formik.touched.price_per_min && formik.errors.price_per_min,
                                                                    },
                                                                    {
                                                                        "is-valid":
                                                                            formik.touched.price_per_min && !formik.errors.price_per_min,
                                                                    }
                                                                )}
                                                                name="price_per_min"
                                                                autoComplete="off" />
                                                            {formik.errors.price_per_min && formik.touched.price_per_min ? (
                                                                <div className="text-danger">{formik.errors.price_per_min}</div>
                                                            ) : null}
                                                        </CCol>
                                                        <CCol xs={6}>
                                                            <CFormLabel htmlFor="inputwaiting_fare">Waiting Fare  (in €)<span className="asterisk-mark">*</span>
                                                            </CFormLabel>
                                                            <CFormInput type="number" id="inputwaiting_fare" {...formik.getFieldProps("waiting_fare")}

                                                                className={clsx(
                                                                    "form-control bg-transparent",
                                                                    {
                                                                        "is-invalid":
                                                                            formik.touched.waiting_fare && formik.errors.waiting_fare,
                                                                    },
                                                                    {
                                                                        "is-valid":
                                                                            formik.touched.waiting_fare && !formik.errors.waiting_fare,
                                                                    }
                                                                )}
                                                                name="waiting_fare"
                                                                autoComplete="off" />
                                                            {formik.errors.waiting_fare && formik.touched.waiting_fare ? (
                                                                <div className="text-danger">{formik.errors.waiting_fare}</div>
                                                            ) : null}
                                                        </CCol> */}


                                                        <CCol xs={12}>
                                                            <div className="d-flex justify-content-center" style={{ marginTop: "40px" }}>
                                                                <CButton type="submit" className="submit-btn" disabled={submitLoader}>{submitLoader ? <ClipLoader color="#000000" /> : "Submit"}</CButton>
                                                                <CButton type="button" className="cancel-btn" onClick={back}>Cancel</CButton>
                                                            </div>
                                                        </CCol>
                                                    </form>


                                                </CCardBody>
                                            </CCard>
                                        </CCol>
                                    </CRow>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Setting
import React, { useEffect, useState, useContext, useRef } from "react";
import backtovehicle from '../../../assets/images/left-arrow.png';
import { Link } from 'react-router-dom';
import {
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
    CButton,
    CModal,
    CModalBody,
    CCardBody,
    CRow,
    CCard,
    CCol,
    CFormInput,
    CFormLabel,
    CModalHeader,
    CModalTitle,
} from "@coreui/react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import ReactPaginate from 'react-paginate';
import SuperAdminSideBar from "../Sidebar/SideBar";
import AppHeader from "../../TopBar/AppHeader";
import PieCharts from "../../Charts/PieCharts";
import { AllHotelsDetail, editCompanyDetail, getCompanydetailId , BlockUser } from "../../../utils/api";
import AppLoader from "../../AppLoader";
import { MDBInputGroup, MDBInput, MDBIcon, MDBBtn } from "mdb-react-ui-kit";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from "react-places-autocomplete";
import editiconimg from "../../../assets/images/editicon.png";
import { ClipLoader } from "react-spinners";
import EmptyData from "../../EmptyData";
import * as XLSX from 'xlsx';
import Switch from "react-switch";
import { toast } from "react-toastify";



const AllHotels = () => {

    const [hotelDetail, setHotelDetail] = useState([]);
    const [loading, setLoading] = useState(false);
    const [allPagination, setAllPagination] = useState();
    const [pageNumbers, setPageNumbers] = useState(1);
    const [search, setSearch] = useState("");
    const [debouncedSearch, setDebouncedSearch] = useState("");
    const [currentPage, setCurrentPage] = React.useState(1);


    const recordPage = 10;
    const lastIndex = currentPage * recordPage;
    const firstIndex = lastIndex - recordPage;
    const data = hotelDetail?.slice(firstIndex, lastIndex);
    const nPage = Math.ceil(hotelDetail?.length / recordPage);
    const number = [...Array(nPage + 1).keys()].slice(1);

    const handlePageClick = (selectedPage) => {
        console.log("se", selectedPage)
        setPageNumbers(selectedPage.selected + 1)
    };

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
        setPageNumbers(1);
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            setDebouncedSearch(search);
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
    }, [search]);

    useEffect(() => {
        getHotelDetails()
    }, [debouncedSearch, pageNumbers])

    const getHotelDetails = () => {

        setLoading(true);

        let datas = {
            search: search,
            page: pageNumbers,
            limit: 10,
        }

        AllHotelsDetail(datas).then((res) => {
            setHotelDetail(res?.result, "Hotels");
            setAllPagination(res?.totalCount)
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
            console.error("Error fetching getHotelDetails :", error);
        });
    }

    const exportToExcel = () => {
        // Prepare data for export
        const exportData = data.map((item, index) => ({
            'Sr. No.': firstIndex + index + 1,
            'Hotel ID': item?.company_id,
            'Hotel Name': item?.company_name,
            'Company Name': item?.hotel_company_name,
            'Email': item?.email,
            'Phone': item?.phone,
        }));

        // Create a new workbook and sheet
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(exportData);

        // Add the sheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Hotels');

        // Trigger download
        XLSX.writeFile(workbook, 'HotelDetails.xlsx');
    };

    const [editVisible, setEditVisible] = useState(false);
    const [address, setAddress] = useState("");
    const [formLoader, setFormLoader] = useState(false)
    const [touched, setTouched] = useState(false)

    const [addressError, setAddressError] = useState(false);
    const [selectAddress, setSelectAddress] = useState(true)
    const [PasswordChangeEmail, setPasswordChangeEmail] = useState(null)


    const handleSelectAddress = async (selectedAddress) => {
        try {
            setSelectAddress(true);
            formik.setFieldValue("land", selectedAddress)
            setAddress(selectedAddress);
            setAddressError(false);

        } catch (error) {
            console.error("Error:", error);
        }
    };


    const [inputData, setInputData] = useState({
        _id: "",
        company_name: "",
        company_id: "",
        post_code: "",
        description: "",
        phone: "",
        website: "",
        first_name: "",
        last_name: "",
        tel_contact_number: "",
        email: "",
        commision: "",
        requestTrip: "",
    });

    const initialValues = {
        _id: "",
        company_name: "",
        company_id: "",
        post_code: "",
        house_number: "",
        description: "",
        affiliated_with: "",
        phone: "",
        website: "",
        tx_quality_mark: "",
        first_name: "",
        last_name: "",
        tel_contact_number: "",
        email: "",
        commision: "",
        requestTrip: "",
    };

    const validationSchema = Yup.object().shape({
        company_name: Yup.string().trim()

            .matches(/^[^\d]+$/, 'Customer Name is not valid')
            .max(50, "Customer Name is must be less than 50")
            .required("Customer Name is required"),
        company_id: Yup.string().trim().max(20).required("Hotel ID is required"),
        post_code: Yup.string().trim().max(10).required("Postcode is required"),

        phone: Yup.string().trim()
            .matches(/^[0-9]+$/, "Must be only digits")
            .required("Phone Number is required"),
        email: Yup.string().trim().email().required("Email Address is required"),

    });

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            setFormLoader(true)
            if (address.length < 1 || !selectAddress) {
                setAddressError(true);
                setTouched(true);
                setFormLoader(false)
                return;
            }
            console.log("values", values);
            const id = values._id
            editCompanyDetail(id, {
                company_name: values.company_name,
                land: values.land,
                post_code: values.post_code,
                company_id: values.company_id,
                // description: values.description,
                // affiliated_with: values.affiliated_with,
                phone: values.phone,
                // website: values.website,
                // tx_quality_mark: values.tx_quality_mark,
                // first_name: values.first_name,
                // last_name: values.last_name,
                // tel_contact_number: values.p_number,
                email: values.email,
            })
                .then((res) => {
                    console.log("ressssssssssss", res);
                    if (res.data.code == 200) {
                        // getCompanyDetail();
                        getHotelDetails();
                        setEditVisible(!editVisible);
                        toast.success(`${res.data.message}`, {
                            position: 'top-right',
                            autoClose: 1000,
                        });
                    } else {
                        toast.warning(`${res.data.message}`, {
                            position: 'top-right',
                            autoClose: 1000,
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                }).finally(() => {
                    setFormLoader(false)
                });
        },
    });

    function handleStatusChange(item) {
    
        let sur = {
          _id : item._id,
          is_blocked : (!item.is_blocked).toString(),
          role :  "HOTEL"
        }
        BlockUser(sur)
          .then((res) => {
            console.log("status changed", res);
            if (res.code === 200) {
              const newCompanyData = hotelDetail.map((i) => {
                if (i._id == item._id) {
                  i.is_blocked = !item.is_blocked;
                  return i;
                }
                return i
              })
              setHotelDetail(newCompanyData)
            }
            toast.success(`${res.message}`, {
              position: "top-right",
              autoClose: 1000,
            });
          })
          .catch((error) => {
            console.log(error);
            toast.warning(`${error.message}`, {
              position: "top-right",
              autoClose: 1000,
            });
          });
      }


    const handleEdit = (id) => {
        getCompanydetailId(id)
            .then((res) => {
                console.log("company detail by id--------------", res);
                if (res?.code == 200) {
                    setInputData(res.result);
                    setAddress(res?.result?.land)
                    const values = res.result
                    formik.setValues({ ...values })
                    setPasswordChangeEmail(res.result.email)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };


    return (
        <>
            <div className='container-fluidd'>
                <div className="col-md-12">
                    <div>
                        <SuperAdminSideBar />
                        <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                            <AppHeader />
                            <div className="body flex-grow-1 px-3">
                                <div className="d-flex justify-content-between">
                                    <h1 className="heading-for-every-page ms-2">
                                        All Hotels
                                    </h1>
                                   
                                    <div className="serach-left" id="company-search">

                                        <MDBInputGroup>
                                            <MDBInput
                                                value={search}
                                                onChange={handleSearchChange}
                                                placeholder="Search"
                                            />
                                            <button className="search-btn" >
                                                <MDBIcon icon="search" />
                                            </button>
                                        </MDBInputGroup>
                                    </div>
                                </div>


                                <div className='TripDetail'>
                                    {/* <h5 className='ps-4'>Trips Details of </h5> */}
                                    <div className='active-trip-outer mx-2'>

                                        {loading ? <AppLoader /> : (<>
                                            {data.length > 0 ?

                                                <CTable
                                                    align="middle"
                                                    className="mb-0 mt-3"
                                                    hover
                                                    responsive>
                                                    <CTableHead>
                                                        <CTableRow>
                                                            <CTableHeaderCell>
                                                                Sr. No.
                                                            </CTableHeaderCell>
                                                            <CTableHeaderCell>
                                                                Hotel ID
                                                            </CTableHeaderCell>
                                                            <CTableHeaderCell>
                                                                Hotel Name
                                                            </CTableHeaderCell>
                                                            <CTableHeaderCell>
                                                                Company Name
                                                            </CTableHeaderCell>
                                                            <CTableHeaderCell>
                                                                Email
                                                            </CTableHeaderCell>
                                                            <CTableHeaderCell>
                                                                Blocked
                                                            </CTableHeaderCell>
                                                            <CTableHeaderCell>
                                                                Phone
                                                            </CTableHeaderCell>
                                                            <CTableHeaderCell>
                                                                Action
                                                            </CTableHeaderCell>
                                                        </CTableRow>
                                                    </CTableHead>

                                                    <CTableBody>
                                                        {data?.map((item, index) => (
                                                            <CTableRow >
                                                                <CTableDataCell>
                                                                    {/* <div>{firstIndex + index + 1}</div> */}
                                                                    <div>{(pageNumbers - 1) * 10 + index + 1}</div>
                                                                </CTableDataCell>
                                                                <CTableDataCell>
                                                                    <div>{item?.company_id}</div>
                                                                </CTableDataCell>

                                                                <CTableDataCell>
                                                                    <div>{item?.company_name}</div>
                                                                </CTableDataCell>
                                                                <CTableDataCell>
                                                                    <div>{item?.hotel_company_name}</div>
                                                                </CTableDataCell>
                                                                <CTableDataCell>
                                                                    <div>{item?.email}</div>
                                                                </CTableDataCell>
                                                                <CTableDataCell>
                                                                    <Switch
                                                                        checkedIcon={false}
                                                                        uncheckedIcon={false}
                                                                        height={18}
                                                                        width={35}
                                                                        onColor='#d00c0c'
                                                                        onChange={() => {
                                                                            handleStatusChange(item);
                                                                        }}
                                                                        checked={item.is_blocked}
                                                                    />
                                                                </CTableDataCell>
                                                                <CTableDataCell>
                                                                    <div>{item?.phone}</div>
                                                                </CTableDataCell>

                                                                <CTableDataCell>
                                                                    <CButton
                                                                        id="edit_company_btn"
                                                                        className="pt-1"
                                                                        onClick={() => setEditVisible(!editVisible)}
                                                                    >
                                                                        <img
                                                                            src={editiconimg}
                                                                            alt="edit"
                                                                            onClick={() => handleEdit(item._id)}
                                                                            style={{ height: '33px', width: '34px' }}
                                                                        />{" "}
                                                                    </CButton>
                                                                    <Link to={`/super-admin/hotel-chart/${item?._id}`}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            width="23"
                                                                            height="23"
                                                                            fill="currentColor"
                                                                            className="bi bi-pie-chart-fill mx-1 mt-1"
                                                                            viewBox="0 0 16 16"
                                                                        >
                                                                            <path d="M15.985 8.5H8.207l-5.5 5.5a8 8 0 0 0 13.277-5.5zM2 13.292A8 8 0 0 1 7.5.015v7.778zM8.5.015V7.5h7.485A8 8 0 0 0 8.5.015" />
                                                                        </svg>
                                                                    </Link>
                                                                </CTableDataCell>
                                                            </CTableRow>
                                                        ))}
                                                    </CTableBody>
                                                </CTable>
                                                : <></>}
                                        </>
                                        )}
                                        <div className='pagination'>
                                            <ReactPaginate
                                                breakLabel="..."
                                                nextLabel=">"
                                                onPageChange={handlePageClick}
                                                pageRangeDisplayed={3}
                                                marginPagesDisplayed={1}
                                                pageCount={Math.ceil(allPagination / 10)}
                                                previousLabel="<"
                                                renderOnZeroPageCount={null}
                                                containerClassName="pagination"  
                                                activeClassName="active-page"  
                                                pageClassName="pagination-item" 
                                                pageLinkClassName="pagination-link"  
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <CModal className="edit_company_popup"
                    alignment="center"
                    visible={editVisible}
                    onClose={() => setEditVisible(false)}
                >
                    <CModalHeader>
                        <CModalTitle>Edit Customer</CModalTitle>
                    </CModalHeader>
                    <CModalBody>
                        <CRow>
                            <CCol xs={12}>
                                <CCard className="mb-4 edit_company_popup">
                                    <CCardBody>
                                        <form noValidate onSubmit={formik.handleSubmit} className="row g-3">
                                            <CCol md={6}>
                                                <CFormLabel htmlFor="inputcname">
                                                    Hotel ID
                                                </CFormLabel>
                                                <CFormInput
                                                    aria-label="Hotel ID"
                                                    value={inputData.company_id}
                                                    {...formik.getFieldProps("company_id")}
                                                    className={clsx(
                                                        "form-control bg-transparent",
                                                        {
                                                            "is-invalid":
                                                                formik.touched.company_id &&
                                                                formik.errors.company_id,
                                                        },
                                                        {
                                                            "is-valid":
                                                                formik.touched.company_id &&
                                                                !formik.errors.company_id,
                                                        }
                                                    )}
                                                    name="company_id"
                                                    autoComplete="off"
                                                    readOnly
                                                />
                                                {formik.errors.company_id &&
                                                    formik.touched.company_id ? (
                                                    <div className="text-danger">
                                                        {formik.errors.company_id}
                                                    </div>
                                                ) : null}

                                            </CCol>
                                            <CCol md={6}>
                                                <CFormLabel htmlFor="inputcname">
                                                    Customer Name
                                                </CFormLabel>
                                                <CFormInput
                                                    aria-label="vehicle fare"
                                                    value={inputData.company_name}
                                                    {...formik.getFieldProps("company_name")}
                                                    className={clsx(
                                                        "form-control bg-transparent",
                                                        {
                                                            "is-invalid":
                                                                formik.touched.company_name &&
                                                                formik.errors.company_name,
                                                        },
                                                        {
                                                            "is-valid":
                                                                formik.touched.company_name &&
                                                                !formik.errors.company_name,
                                                        }
                                                    )}
                                                    name="company_name"
                                                    autoComplete="off"
                                                    readOnly
                                                />
                                                {formik.errors.company_name &&
                                                    formik.touched.company_name ? (
                                                    <div className="text-danger">
                                                        {formik.errors.company_name}
                                                    </div>
                                                ) : null}
                                            </CCol>
                                            <CCol xs={6}
                                                onBlur={() => {
                                                    setTouched(true)
                                                }}
                                            >
                                                <CFormLabel htmlFor="inputtripfrom">
                                                    Address
                                                </CFormLabel>
                                                <PlacesAutocomplete
                                                    value={address}

                                                    onChange={(data) => {

                                                        console.log(data, " from place holder")
                                                        setAddress(data.trim());
                                                        if (data.trim().length < 1) {
                                                            setAddressError(true)
                                                            setSelectAddress(false)
                                                        } else {
                                                            setAddressError(true)
                                                            setSelectAddress(false)

                                                        }
                                                    }}
                                                    onSelect={handleSelectAddress}
                                                >
                                                    {({
                                                        getInputProps,
                                                        suggestions,
                                                        getSuggestionItemProps,
                                                        loading,
                                                    }) => (
                                                        <div>
                                                            <CFormInput
                                                                onBlur={() => {
                                                                    console.log("Blur run")
                                                                }}
                                                                id="inputtripfrom"
                                                                {...getInputProps({
                                                                    // placeholder: "Enter a location",
                                                                })}
                                                                className={clsx(
                                                                    "form-control bg-transparent",
                                                                    {
                                                                        "is-invalid":
                                                                            touched &&
                                                                            addressError
                                                                    },
                                                                    {
                                                                        "is-valid":
                                                                            touched &&
                                                                            !addressError
                                                                    }
                                                                )}

                                                            />
                                                            {addressError && touched &&
                                                                <div className="text-danger">
                                                                    Address must be selected from list
                                                                </div>
                                                            }
                                                            <div className="suugestion-div">
                                                                <div className="suggestion-inner">
                                                                    {loading && <div>Loading...</div>}
                                                                    {suggestions
                                                                        .slice(0, 3)
                                                                        .map((suggestion) => (
                                                                            <div
                                                                                key={suggestion.id}
                                                                                {...getSuggestionItemProps(
                                                                                    suggestion
                                                                                )}
                                                                            >
                                                                                {suggestion.description}
                                                                            </div>
                                                                        ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </PlacesAutocomplete>


                                            </CCol>
                                            <CCol md={6}>
                                                <CFormLabel htmlFor="inputpcode">
                                                    Post Code
                                                </CFormLabel>
                                                <CFormInput
                                                    aria-label="postcode"
                                                    maxLength="50"
                                                    value={inputData.post_code}
                                                    {...formik.getFieldProps("post_code")}
                                                    className={clsx(
                                                        "form-control bg-transparent",
                                                        {
                                                            "is-invalid":
                                                                formik.touched.post_code &&
                                                                formik.errors.post_code,
                                                        },
                                                        {
                                                            "is-valid":
                                                                formik.touched.post_code &&
                                                                !formik.errors.post_code,
                                                        }
                                                    )}
                                                    name="post_code"
                                                    autoComplete="off"
                                                />
                                                {formik.errors.post_code &&
                                                    formik.touched.post_code ? (
                                                    <div className="text-danger">
                                                        {formik.errors.post_code}
                                                    </div>
                                                ) : null}
                                            </CCol>

                                            <CCol md={6}>
                                                <CFormLabel htmlFor="inputphnnum">
                                                    Phone Number
                                                </CFormLabel>
                                                <CFormInput
                                                    type="number"
                                                    aria-label="phone number"

                                                    value={inputData.phone}
                                                    {...formik.getFieldProps("phone")}
                                                    className={clsx(
                                                        "form-control bg-transparent",
                                                        {
                                                            "is-invalid":
                                                                formik.touched.phone &&
                                                                formik.errors.phone,
                                                        },
                                                        {
                                                            "is-valid":
                                                                formik.touched.phone &&
                                                                !formik.errors.phone,
                                                        }
                                                    )}
                                                    name="phone"
                                                    autoComplete="off"
                                                />
                                                {formik.errors.phone &&
                                                    formik.touched.phone ? (
                                                    <div className="text-danger">
                                                        {formik.errors.phone}
                                                    </div>
                                                ) : null}
                                            </CCol>

                                            <CCol md={6}>
                                                <CFormLabel htmlFor="inputmailaddress">
                                                    Email Address
                                                </CFormLabel>
                                                <CFormInput
                                                    id="email_address"
                                                    value={inputData.email}
                                                    {...formik.getFieldProps("email")}
                                                    className={clsx(
                                                        "form-control bg-transparent",
                                                        {
                                                            "is-invalid":
                                                                formik.touched.email &&
                                                                formik.errors.email,
                                                        },
                                                        {
                                                            "is-valid":
                                                                formik.touched.email &&
                                                                !formik.errors.email,
                                                        }
                                                    )}
                                                    name="email"
                                                    autoComplete="off"
                                                />
                                                {formik.errors.email && formik.touched.email ? (
                                                    <div className="text-danger">
                                                        {formik.errors.email}
                                                    </div>
                                                ) : null}
                                            </CCol>
                                            {/* <CCol xs={12} className="change_your_pwd_txt">
                                                <CButton className="change_pwd_btn_customer"
                                                    onClick={() => handlePwdChange()} >Reset customer’s password.</CButton>
                                            </CCol> */}

                                            <CCol md={12}>
                                                <div
                                                    className="d-flex justify-content-center"
                                                    style={{ marginTop: "40px" }}
                                                >
                                                    <CButton type="submit" className="submit-btn" disabled={formLoader}>
                                                        {formLoader ? <ClipLoader color="#000000" /> : "Submit"}
                                                    </CButton>
                                                    <CButton
                                                        type="button"
                                                        onClick={() => {
                                                            setEditVisible(!editVisible)
                                                            formik.resetForm()
                                                            setSelectAddress(true);
                                                            setAddressError(false);
                                                        }
                                                        }
                                                        className="cancel-btn"
                                                    >
                                                        Cancel
                                                    </CButton>
                                                </div>
                                            </CCol>
                                        </form>
                                    </CCardBody>

                                </CCard>
                            </CCol>
                        </CRow>
                    </CModalBody>
                </CModal>


            </div>
        </>
    )
}

export default AllHotels
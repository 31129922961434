import React, { useEffect, useState, useRef, useContext } from "react";
import SuperSideBar from '../Taxi/SiderNavBar/Sidebar'
import AppHeader from '../TopBar/AppHeader'
import { getProducts, submitProduct, creteSubscription, deleteSubscription , createIdealCheckoutSession } from "../../utils/api";
import { toast } from 'react-toastify';
import { CModal, CModalBody, CModalHeader, CModalContent, CModalTitle } from "@coreui/react";
import applogo from '../../assets/images/taxi-logo.png'
import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements, Elements, PaymentElement, AddressElement } from '@stripe/react-stripe-js';
import { loadStripe } from "@stripe/stripe-js";
import userContext from "../../utils/context";
import SidebarDriver from "../Driver/Sidebar";
import AppLoader from "../AppLoader";
import moment from "moment";
const stripePromise = loadStripe('pk_test_R9FCBxRL1OituIRiYkzbiBbn');

const Pricing = () => {
    const [month, setMonth] = useState("Month");
    const [plan, setPlan] = useState("Premium");
    const [product, setProduct] = useState([]);
    const [active, setActive] = useState({});
    const [planPeriod, setPlanPeriod] = useState([]);
    const [modal, setModal] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const { user, setUser } = useContext(userContext)
    const [cancelModal, setCancelModal] = useState(false);
    const [btnName, setBtnName] = useState("Buy Now")
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        name: "",
        email: ""
    });
    const [errors, setErrors] = useState({
        name: "",
        email: "",
        cardNumber: "",
        expiryDate: "",
        cvv: ""
    });

    useEffect(() => {
        getPlans();
    }, []);

    const getPlans = () => {
        setLoading(true);
        getProducts().then((res) => {
            if (res?.code === 200) {
                setProduct(res.planList)
                setActive(res?.activePlan)
                setPlanPeriod(res?.activePayedPlan)
                const activePlans = res.planList.filter((plan) => plan.userActivePlan);
                if (activePlans.length > 0) {
                    activePlans.forEach((plan) => {
                        if (user.role === "COMPANY" && plan.name === "Premium") {
                            setBtnName("Upgrade Plan")
                        } else if (user.role === "COMPANY" && plan.name === "Pro") {
                            setBtnName("Downgrade Plan")
                        }
                    });
                } else {
                    setBtnName("Buy Now")
                }
                setLoading(false);
            }
        }).catch((error) => {
            console.log(error);
            setLoading(false);
        })
    }

    // const openModal = (data) => {
    //     console.log(data, user);

    //     const { role, isDriver, isCompany } = user;
    //     const { name } = data;

    //     if (
    //       (role === "COMPANY" && name === "Basic" && isDriver) ||
    //       (role === "COMPANY" && ["Premium", "Pro"].includes(name)) ||
    //       (role === "DRIVER" && name === "Basic") ||
    //       (role === "DRIVER" && ["Premium", "Pro"].includes(name) && isCompany)
    //     ) {
    //       setModal(true);
    //       setSelectedPlan(data);
    //     }
    //     else {
    //         toast.error("You don't have permission to access this plan");
    //     }
    //   };

    const openModal = (data) => {
        console.log(data, user);
        const { role, isDriver, isCompany } = user;
        const { name } = data;
        if (
            (role === "COMPANY" && ["Premium", "Pro"].includes(name)) ||
            (role === "DRIVER" && name === "Basic")
        ) {
            setModal(true);
            setSelectedPlan(data);
        }
        else {
            toast.error("You don't have permission to access this plan");
        }
    };


    const closeModal = () => {
        setModal(false);
        setSelectedPlan(null);
        setData({
            name: "",
            email: "",
        });
        setErrors({
            name: "",
            email: "",
            cardNumber: "",
            expiryDate: "",
            cvv: ""
        })
        getPlans();
    };


    const deleteSubs = () => {
        deleteSubscription(active.subscriptionId).then((res) => {
            if (res?.code === 200) {
                getPlans();
                setCancelModal(false);
                toast.success(res.message, {
                    autoClose: 3000,
                    position: "top-right",
                    hideProgressBar: true,
                });
            }
        }).catch(error => console.log(error))
    }

    return (
        <>
            <div className="container-fluidd">
                <div className="col-md-12">
                    <div>
                        {
                            user?.role === "COMPANY" ? <SuperSideBar /> : <SidebarDriver />
                        }
                        <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                            <AppHeader />
                            <div
                                className="body flex-grow-1 px-3"
                                style={{ paddingBottom: "20px" }}
                            >
                                <div className='row'>
                                    <div className='col-md-8'>
                                        <h1 className='mt-1'>Plans Built For Everyone</h1>
                                        <h5 className="mt-3">Get Started Now</h5>
                                    </div>
                                    <div className='col-md-4'>
                                        <h3 className='mt-3'>Subscription Plan</h3>
                                        <div className='mt-3 packge'>
                                            <span className={`me-2 ${month === "Month" ? "chng_pack" : "form_pack"}`} onClick={() => { setMonth("Month") }}>Monthly</span>
                                            {/* <span className={`  ${month === "Year" ? "chng_pack" : "form_pack"}`} onClick={() => { setMonth("Year") }}>Yearly</span> */}
                                        </div>
                                    </div>
                                </div>
                                {
                                    planPeriod.length > 0 ? <>
                                        <div className="pln_prd">Your Last subscription Plan, priced at €{planPeriod[0]?.amount}, is valid until  {moment(planPeriod[0].endPeriod).format("MMMM Do YYYY")}.</div>
                                    </> : ''
                                }
                                {
                                    loading ?
                                        <AppLoader />
                                        :
                                        <div className='row mt-5'>
                                            {
                                                product.length > 0 ? product.map((item, index) => (
                                                    <div className='col-md-4'>
                                                        <div className={`plans_div ${plan === item.name ? "plan_Active" : "plan_simple"}`} onClick={() => { setPlan(item.name) }}>
                                                            {
                                                                item.name == 'Premium' ?
                                                                    <div className="popular">
                                                                        <h6 className="mb-0">Most Popular</h6>
                                                                    </div> : ''
                                                            }
                                                            <div className="" >
                                                                <h2>{item?.name}</h2>
                                                                <div className="d-flex">
                                                                    {/* <h5 className="me-1">${month === "Month" ? "60" : "240"}  </h5>/ <h5 className="ms-1">{month === "Month" ? "Month" : "Year"}</h5> */}
                                                                    <h5 className="me-1">€{item.price}  </h5>/ <h5 className="ms-1">{month === "Month" ? "Month" : "Year"}</h5>
                                                                </div>
                                                                <p className="mb-0" style={{ fontSize: '13px' }}>{item.description}</p>
                                                                {
                                                                    item?.userActivePlan ?
                                                                        <div className="text-center">
                                                                            <button className={`btn ${plan === item.name ? "buy_active" : "buy_simple"}`} onClick={() => setCancelModal(true)}>
                                                                                Cancel  Subscription
                                                                            </button>
                                                                        </div> :

                                                                        <div className="text-center">
                                                                            <button className={`btn ${plan === item.name ? "buy_active" : "buy_simple"}`} onClick={() => openModal(item)}>
                                                                                {
                                                                                    item.name === "Basic" ? 'Buy Now' : <>{btnName}</>
                                                                                }

                                                                            </button>
                                                                        </div>
                                                                }
                                                                <div className={`${plan === item.name ? "hr_active" : "hr_simple"}`} > </div>
                                                                <div className="offers">
                                                                    <ul>
                                                                        {
                                                                            item.features.map((desc, index) => (
                                                                                <li key={index}>{desc}</li>
                                                                            ))
                                                                        }
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                                    : ''
                                            }
                                        </div>
                                }
                                {modal && (
                                    <Elements stripe={stripePromise}>
                                        <PaymentModal
                                            modal={modal}
                                            closeModal={closeModal}
                                            selectedPlan={selectedPlan}
                                            data={data}
                                            setData={setData}
                                            errors={errors}
                                            setErrors={setErrors}
                                        />
                                    </Elements>
                                )}
                                <CModal
                                    alignment="center"
                                    backdrop="static"
                                    visible={cancelModal}
                                    onClose={() => setCancelModal(false)}
                                    aria-labelledby="payment-modal-title"
                                >
                                    <CModalHeader>
                                        <CModalTitle>Cancel Subscription</CModalTitle>
                                    </CModalHeader>
                                    <CModalBody>
                                        <h5>Are you sure you want to cancel your subscription?</h5>
                                        <div className="text-center">
                                            <button className="btn btn_cancel" onClick={deleteSubs}>Yes</button>
                                            <button className="btn btn_cancel_no" onClick={() => setCancelModal(false)}>No</button>
                                        </div>
                                    </CModalBody>
                                </CModal>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}


const PaymentModal = ({
    modal,
    closeModal,
    selectedPlan,
    data,
    setData,
    errors,
    setErrors,
}) => {
    const stripe = useStripe();
    const elements = useElements();

    const [loading, setLoading] = useState(false);
    const [switchType, setSwitchType] = useState("card")

    const handleChange = (event) => {
        const { name, value } = event.target;
        setData({ ...data, [name]: value });
    };

    const validateFields = () => {
        let isValid = true;
        let newErrors = {
            name: "",
            cardNumber: "",
            expiryDate: "",
            cvv: "",
        };
        if (!data.name.trim()) {
            newErrors.name = "Name is required";
            isValid = false;
        }
        if (!data.email.trim()) {
            newErrors.email = "Email is required";
            isValid = false;
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(data.email.trim())) {
            newErrors.email = "Please Enter a valid email address";
            isValid = false;
        }
        const cardNumberElement = elements.getElement(CardNumberElement);
        if (!cardNumberElement?._complete) {
            newErrors.cardNumber = "Card Number is required";
            isValid = false;
        }
        const cardExpiryElement = elements.getElement(CardExpiryElement);
        if (!cardExpiryElement?._complete) {
            newErrors.expiryDate = "Card Expiry Date is required";
            isValid = false;
        }
        const cardCvcElement = elements.getElement(CardCvcElement);
        if (!cardCvcElement?._complete) {
            newErrors.cvv = "CVC/CVV Code is required";
            isValid = false;
        }
        setErrors(newErrors);
        return isValid;
    };

    const handlePayment = async (event) => {
        event.preventDefault();
        if (!stripe || !elements) return;
        if (!validateFields()) return;
        console.log('Payment', event)
        setLoading(true);
        const cardNumberElement = elements.getElement(CardNumberElement);

        const { paymentMethod, error } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardNumberElement,
            billing_details: { email: data.email },
        });

        if (error) {
            setLoading(false);
            // Handle error (e.g., display error message to the customer)
            console.error('Error creating payment method:', error);
            return;
        }

        // Retrieve the card's funding type
        const { card } = paymentMethod;
        console.log('Funding type ---- :', card);
        if (card.funding !== 'credit') {
            setLoading(false);
            // Display an error message to the customer
            setErrors((prevErrors) => ({
                ...prevErrors,
                cardNumber: 'Only credit cards are accepted.',
            }));
            return;
        }

        let data1 = {
            priceId: selectedPlan.productPriceId,
            paymentMethodId: paymentMethod.id
        }

        creteSubscription(data1).then((res) => {
            if (res?.code === 200) {
                if (res) {
                    stripe.confirmCardPayment(res.clientSecret, {
                        payment_method: {
                            card: cardNumberElement,
                            billing_details: { email: data.email },
                        },
                    }).then((result) => {
                        setLoading(false);
                        if (result.error) {
                            closeModal()
                            toast.error(result.error.message, {
                                autoClose: 3000,
                                position: "top-right",
                                hideProgressBar: true,
                            });
                        } else if (result.paymentIntent.status === 'succeeded') {
                            closeModal()
                            toast.success("Your subscription plan has been successfully activated", {
                                autoClose: 3000,
                                position: "top-right",
                                hideProgressBar: true,
                            });
                        }
                    });
                } else {
                    toast.error("Failed to purchase subscription", {
                        autoClose: 3000,
                        position: "top-right",
                        hideProgressBar: true,
                    });
                    setLoading(false);
                }
            }
        }).catch((error) => {
            setLoading(false);
            console.log(error)
        }
        )
    };


    const proceed = () => {
        createIdealCheckoutSession(selectedPlan.productPriceId).then((res) => {
           
            console.log("res--" , res);
            window.location.href = res.url

        }).catch(error => console.log(error))
    }

    return (
        <CModal
            alignment="center"
            backdrop="static"
            visible={modal}
            onClose={closeModal}
            aria-labelledby="payment-modal-title"
        >
            <CModalHeader>
                <div className="text-center w-100">
                    <img src={applogo} height={70} width={150} alt="Logo" />
                    <h5 className="mt-2 payment_modal">
                        Selected Plan : {selectedPlan?.name} €{selectedPlan?.price}/Month
                    </h5>
                </div>
            </CModalHeader>
            <CModalBody className="mx-4 py-2 px-2 pay_modal" >

                <div className="switches">
                    <div onClick={() => { setSwitchType('card') }} className={`${switchType === 'card' ? "add_border" : "no_border"}`}>
                        Credit card
                    </div>
                    <div onClick={() => { setSwitchType('link') }} className={`${switchType === 'link' ? "add_border" : "no_border"}`}>
                        IDEAL / SEPA
                    </div>
                </div>

                {
                    switchType === "card" ?
                        <form onSubmit={handlePayment}>
                            <div className="form-group row">
                                <div className="col-md-7 p-0">
                                    <input
                                        type="text"
                                        name="name"
                                        className={`setfont w-100  mb-2 modal-input ${errors.name ? "is-invalid" : ""
                                            }`}
                                        placeholder="Name on the Card"
                                        value={data.name}
                                        onChange={handleChange}
                                        autoComplete="off"
                                    />
                                    {errors.name && <div className="invalid-feedback mb-1">{errors.name}</div>}
                                    <input
                                        type="text"
                                        name="email"
                                        className={`setfont w-100  mb-2 modal-input ${errors.email ? "is-invalid" : ""
                                            }`}
                                        placeholder="Enter Email Address"
                                        value={data.email}
                                        onChange={handleChange}
                                        autoComplete="off"
                                    />
                                    {errors.email && <div className="invalid-feedback mb-1">{errors.email}</div>}
                                </div>
                                <div className="col-md-5 ">
                                    <p className="mb-0 summary">
                                        Summary
                                    </p>
                                    <div className="d-flex justify-content-between cost_data">
                                        <div className="">
                                            <p className="mb-0 dark_fnt">Plan Cost</p>
                                            <p className="mb-0 dark_fnt">VAT (21%)</p>
                                        </div>
                                        <div className="">
                                            <p className="mb-0 dark_fnt">€{selectedPlan?.price}</p>
                                            <p className="mb-0 ms-3 dark_fnt">{(selectedPlan?.price * 21) / 100}</p>
                                        </div>
                                    </div>
                                    <hr className="my-1" />

                                    <div className="d-flex justify-content-between cost_data">
                                        <div className="">
                                            <p className="mb-0 dark_fnt" >Total</p>
                                        </div>
                                        <div className="">
                                            <p className="mb-0 dark_fnt" >€{(selectedPlan?.price) + (selectedPlan?.price * 21) / 100}</p>
                                        </div>
                                    </div>
                                    <hr className="my-1" />

                                </div>

                                <CardNumberElement
                                    options={{
                                        showIcon: true,
                                        placeholder: "Card Number (Credit Card Only)",
                                    }}
                                    className={` mb-2 modal-input ${errors.cardNumber ? "is-invalid" : ""
                                        }`}
                                />
                                {errors.cardNumber && (
                                    <div className="invalid-feedback mb-1">{errors.cardNumber}</div>
                                )}
                            </div>
                            <div className="row">
                                <div className="col-md-12 d-flex">
                                    <label className="label_exp">Expiry Date</label>
                                    <div className="w-75">
                                        <CardExpiryElement
                                            className={` modal-input mb-2  ${errors.expiryDate ? "is-invalid" : ""
                                                }`}
                                        />
                                        {errors.expiryDate && (
                                            <div className="invalid-feedback mb-1">{errors.expiryDate}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-12 d-flex">
                                    <label className="label_cvc">CVC/CVV </label>
                                    <div className="w-50">
                                        <CardCvcElement
                                            className={`  mb-2 modal-input ${errors.cvv ? "is-invalid" : ""
                                                }`}
                                        />
                                        {errors.cvv && <div className="invalid-feedback mb-1">{errors.cvv}</div>}
                                    </div>
                                    <p className="mb-0 cvc_number">
                                        This code is the 3 or 4 digit verification number found on your card.
                                    </p>
                                </div>
                            </div>
                            <div className="text-center mt-3">
                                <button
                                    type="submit"
                                    disabled={!stripe || !elements || !data.name}
                                    className="btn w-100 btn_pay"
                                >
                                    {loading ? (
                                        // <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        <div class="spinner-border text-primary" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>

                                    ) : (
                                        "PAY"
                                    )}
                                </button>
                            </div>
                        </form> : <>
                            <div className="mb-5 pb-5 mt-2">

                                <div>
                                    <div className="col-md-12 ">
                                        <p className="mb-0 summary">
                                            Summary
                                        </p>
                                        <div className="d-flex justify-content-between cost_data">
                                            <div className="">
                                                <p className="mb-0 dark_fnt">Plan Cost</p>
                                                <p className="mb-0 dark_fnt">VAT (21%)</p>
                                            </div>
                                            <div className="">
                                                <p className="mb-0 dark_fnt">€{selectedPlan?.price}</p>
                                                <p className="mb-0 ms-3 dark_fnt">{(selectedPlan?.price * 21) / 100}</p>
                                            </div>
                                        </div>
                                        <hr className="my-1" />

                                        <div className="d-flex justify-content-between mb-2 cost_data">
                                            <div className="">
                                                <p className="mb-0 dark_fnt" >Total</p>
                                            </div>
                                            <div className="">
                                                <p className="mb-0 dark_fnt" >€{(selectedPlan?.price) + (selectedPlan?.price * 21) / 100}</p>
                                            </div>
                                        </div>

                                        <hr className="my-1" />
                                    </div>

                                </div>


                                <button className="btn w-100 btn_pay mt-4" onClick={proceed}>Proceed</button>

                            </div>

                        </>
                }

            </CModalBody>
        </CModal>
    );
};

export default Pricing



import React, { useEffect } from "react";
import { CCard, CCardBody, CCol, CRow } from "@coreui/react";
import available from "../../assets/images/available.png";

import { CButton } from "@coreui/react";
import { useNavigate } from "react-router";

function Success() {

  const navigate = useNavigate();
  return (
    <>
      <div>
        <div className="main-page" style={{ backgroundColor: "#0682ca" }}>
          <div className="container-outer" id="expired_outer" style={{ background: "#0682ca" }}>
            <div className="banner-inner-text col-md-4">
              <CRow>
                <CCol xs={12}>
                  <CCard style={{ boxShadow: "5px 5px 15px rgba(2, 1, 1, 0.5)" }}>
                    <CCardBody className="py-5" style={{ textAlign: "center" }}>
                      <img src={available} width={60} />
                      <p className="mt-3 mb-0 success_title">
                        Payment has been successfully completed.
                      </p>
                      <CButton className="change_pwd_btn" style={{ marginTop: "10px", width: '30%', background: "#0682ca" }} onClick={() => navigate("/")}>
                        Go to Dashboard
                      </CButton>
                    </CCardBody>

                  </CCard>
                </CCol>
              </CRow>
            </div>
          </div>
        </div>
      </div></>
  )
}

export default Success;